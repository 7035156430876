export const codigosAndamento = {
     "Solicitado":"01"
    , "Concluído":"02"
    , "Concluído - Deferido":"03"
    , "Concluído - Indeferido":"04"
    , "Cancelado":"05"
    , "Encaminhado para Verificação da Documentação":"06"
    , "Em Verificação da Documentação":"07"
    , "Encaminhado para o Coordenador de Curso":"08"
    , "Em Análise pelo Coordenador de Curso":"09"
    , "Aguardando Entrega da Documentação":"10"
    , "Aguardando Entrega de Documentação Recusada Pelo Comercial":"11"
    , "Encaminhado para Registro de Disciplinas":"12"
    , "Em Registro de Disciplinas":"13"
    , "Não Deseja Processo de Isenção de Disciplinas":"14"
}
