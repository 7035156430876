import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import PreviewDadosOferta from './PreviewDadosOferta';
import PreviewDadosPessoais from './PreviewDadosPessoais';
import PreviewDadosAgendamento from './PreviewDadosAgendamento';
import PreviewDadosEnem from './PreviewDadosEnem';
import PreviewDadosTransferenciaOrReingresso from './PreviewDadosTransferenciaOrReingresso';
import PreviewTermoFIES from './PreviewTermoFIES';
 
export default class ModalPreviewDados extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'dados-oferta',
      isVestibular:this.props.idAlavanca === 6 ? true : false,
      isEnem:this.props.idAlavanca === 5 ? true : false,
      isTransferenciaOrReingresso:this.props.idAlavanca === 8 || this.props.idAlavanca === 4 ? true : false,
      checkTermofies: false
    }
    

    this.closePreviewDadosCandidatoModal = this.closePreviewDadosCandidatoModal.bind(this);
    this.trocarTab = this.trocarTab.bind(this);
    this.discoverIsActiveContent = this.discoverIsActiveContent.bind(this);
    this.discoverIsActiveTab = this.discoverIsActiveTab.bind(this);
    this.editarProgresso = this.editarProgresso.bind(this);
    this.confirmarInscricao = this.confirmarInscricao.bind(this);
    this.mudarCheckTermofies = this.mudarCheckTermofies.bind(this)
    
}

closePreviewDadosCandidatoModal(){
  this.props.definirEstadoComponentePai('showPreviewDadosCandidatoModal',false)
}

trocarTab(tab){
  this.setState({activeTab:tab})
}
discoverIsActiveContent(tab){
  if(tab === this.state.activeTab) return 'in active'
  return ''
}

discoverIsActiveTab(tab){
  if(tab === this.state.activeTab) return 'active'
  return ''
}
editarProgresso(e){
  e.preventDefault();
  if(this.state.isVestibular){
    this.fluxoEditarProgressoVestibular()
  }else if(this.state.isEnem){
    this.fluxoEditarProgressoEnem()
  }else if(this.state.isTransferenciaOrReingresso){
    this.fluxoEditarProgressoTransferenciaOrReingresso()
  }
}
closeModal(){
  this.trocarTab('dados-oferta')
  this.props.definirEstadoComponentePai('showPreviewDadosCandidatoModal',false)  
}
confirmarInscricao(e){
  if(this.props.opcaoTipoProva.aceitaFies && ( this.props.lead.possuiFies && JSON.parse(this.props.lead.possuiFies)) && !this.state.checkTermofies){
    this.trocarTab('dados-FIES')
    alert('Os termos para usuários de FIES não foram aceitos!\n Marque a opção e continue sua inscrição.')
    return
  }

  e.preventDefault();
  this.closeModal();
  this.props.callbackFunctionInsertCandidato()
}

fluxoEditarProgressoVestibular(){
  if(this.state.activeTab === 'dados-oferta'){
    this.props.editarProgresso(null, 1)
    this.closeModal()
  }else if(this.state.activeTab === 'dados-pessoais'){
    this.props.editarProgresso(null, 2)
    this.closeModal()
  }else{
    this.props.editarProgresso(null, 3)
    this.closeModal()
  }  
}

fluxoEditarProgressoEnem(){
  if(this.state.activeTab === 'dados-oferta'){
    this.props.editarProgresso(null, 1)
    this.closeModal()
  }else{
    this.props.editarProgresso(null, 2)
    this.closeModal()
  } 
}

fluxoEditarProgressoTransferenciaOrReingresso(){
  if(this.state.activeTab === 'dados-oferta'){
    this.props.editarProgresso(null, 1)
    this.closeModal()       
  }else if(this.state.activeTab === 'dados-pessoais'){
    this.props.definirEstadoComponentePai('passo', 1)    
    this.props.definirEstadoComponentePai('escolheuModalidade',true)    
    this.props.definirEstadoComponentePai('preencheuFormulario',false)    
    this.closeModal()   
  }else{
    this.props.editarProgresso(null, 2)
    this.closeModal()
  }   
}

mudarCheckTermofies(){
  this.setState({checkTermofies:!this.state.checkTermofies})
}

    render() {
        return (            
        <Modal show={this.props.showPreviewDadosCandidatoModal} >        
          <Modal.Body>          
            <div className="modal-body modal-alerta modal-preview">
              <h4 style={{textAlign:'center'}} className="h4 text-blue mb-4">Certifique-se que os campos foram preenchidos corretamente</h4>
              <ul className="nav nav-tabs nav-preview">

                <li onClick={() => this.trocarTab('dados-oferta')}   className={this.discoverIsActiveTab('dados-oferta')}><a>Oferta</a></li>
                <li onClick={() => this.trocarTab('dados-pessoais')}  className={this.discoverIsActiveTab('dados-pessoais')}><a>Dados Pessoais</a></li>

                {this.state.isVestibular ?
                <li onClick={() => this.trocarTab('dados-agendamento')}  className={this.discoverIsActiveTab('dados-agendamento')}><a>Agendamento</a></li> 
                :null}
                {this.state.isEnem ?
                <li onClick={() => this.trocarTab('dados-enem')}  className={this.discoverIsActiveTab('dados-enem')}><a>Enem</a></li> 
                :null}
                 {this.state.isTransferenciaOrReingresso ?
                <li onClick={() => this.trocarTab('dados-transferencia-reingresso')}  className={this.discoverIsActiveTab('dados-transferencia-reingresso')}><a>Instiuição de Origem</a></li> 
                :null}

                { this.props.opcaoTipoProva.aceitaFies && (this.props.lead.possuiFies && JSON.parse(this.props.lead.possuiFies)) ?
                <li onClick={() => this.trocarTab('dados-FIES')}   className={this.discoverIsActiveTab('dados-FIES')}><a>FIES</a></li>    
                :null}
              </ul>

              <div className="tab-content tab-preview">
                <PreviewTermoFIES mudarCheckTermofies={this.mudarCheckTermofies} show={true} {...this.props} discoverIsActiveContent={this.discoverIsActiveContent} />                
                <PreviewDadosOferta {...this.props} discoverIsActiveContent={this.discoverIsActiveContent} />                
                <PreviewDadosPessoais  {...this.props} discoverIsActiveContent={this.discoverIsActiveContent} />                              
                 <PreviewDadosAgendamento show={this.state.isVestibular} {...this.props} discoverIsActiveContent={this.discoverIsActiveContent} />                      
                 <PreviewDadosEnem show={this.state.isEnem} {...this.props} discoverIsActiveContent={this.discoverIsActiveContent} />                       
                 <PreviewDadosTransferenciaOrReingresso show={this.state.isTransferenciaOrReingresso} {...this.props} discoverIsActiveContent={this.discoverIsActiveContent} />                                        
                </div>                 
              <ul className="list-preview">
                <li>
                  <button className="btn-editar-modal" onClick={(e) => {this.editarProgresso(e)}}>
                    Editar
                  </button>
                </li>
                <li>
                  <button  onClick={(e) => {this.confirmarInscricao(e)}}>
                    Confirmar Inscrição
                  </button>
                </li>
              </ul>
            </div>
          </Modal.Body>
          <style jsx="true" global="true">{`
              .modal-alerta, .modal-body{
                background: #fff;
              }
              .modal-body{
                padding: 20px 20px !important;
              }
              .modal-body{
                border-radius: 5px;
              }
              .modal-content{
                border-radius: 7px;
              }
              .modal-alerta > button{
                position: absolute;
                right: 0;
                top: 0;
                padding: 10px 15px;
                
              }
              .modal-alerta > p{
                text-align: center;
                font-weight: 300;
                color: #266ca9;
              }
              .modal-alerta > p > span{                
                text-transform: uppercase;
                font-weight: 700;
                padding:2px;
              }
              .alert{
                color: #e4b60e;
              }
              .sucesso{
                color: #1E7E34;
              }
              .erro{
                color:#ff6c6c;
              }
              .close{
                position: absolute;
                z-index: 99999999;
                right: 0px;
                top: 0px;
                padding: 7px 15px !important;
                transition: 0.5s;
                color: #fff;
              }
              button.close{
                background: #08427e;
                opacity: 0.8;
                border-radius: 0px 0px 0px 5px;
              }
              .close:hover, .close:focus{
                opacity: 0.75;
                cursor: pointer;
                border-radius: 0px 0px 0px 5px;
                transition: 0.5s;
                outline: none;
                color: #fff;
              }
            `}
          </style>
        </Modal>
        )
    }

    styles = {
        width100: {
          width: "100%"
        }
      };      
}
