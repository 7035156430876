import axios from 'axios'
function getLocalAccessToken() {
 const accessToken = window.localStorage.getItem('unigranrio@accessToken');
 return accessToken;
}
export const api = axios.create({
  baseURL: process.env.REACT_APP_RM_API_URL,
});


