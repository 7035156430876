import React, { Component } from 'react'
export default class PreviewTermoFIES extends Component {
  
  constructor(props) {
    super(props);
  }

    render() {
        {
          if(this.props.show){
            return (            
                <div id="agendamento" className={`tab-pane fade ${this.props.discoverIsActiveContent('dados-FIES')}`}>                
                  <h4 style={{textAlign:'center'}} className="h4 text-blue mb-4">
                    Para prosseguir com a Transferência Externa do FIES da IES de origem para a Unigranrio deverão ser respeitados os procedimentos abaixo:
                  </h4>	
                  <p style={{textAlign:'left', textIndent: '5vh'}}>
                    O candidato deve prosseguir com os trâmites de transferência acadêmica incluindo o preenchimento e anexo da Declaração de Solicitação de Transferência FIES que se encontra disponível no link e, se de acordo com o que lhe foi proposto, solicitar a transferência de IES no SISFIES.
                    Solicitada a transferência do FIES, a IES de origem valida a transferência e o aluno comparece no setor Uni Atendimento da Unigranrio para apresentar a documentação, abaixo listada, que será analisada e se de acordo a CPSA Unigranrio validará a transferência do financiamento.
                  </p>
                  <p style={{textAlign:'left', textIndent: '5vh'}}>
                    O candidato deve apresentar a documentação na Unigranrio imediatamente após a validação pela IES origem.
                  </p><br/>	
                  <span style={{textAlign:'left', textIndent: '5vh'}}>Lista dos documentos necessários:</span>
                  <ul>
                    <li> Extrato ou histórico acadêmico da IES de origem demonstrando as disciplinas cursadas por semestre, indicando se a disciplina foi aprovada ou reprovada.</li>
                    <li> DRI ou contrato de abertura do financiamento.</li>
                    <li> DRM do semestre (s) posterior (es) ao semestre (s) de baixo.</li>
                    <li> Tela do SiSFIES com o histórico do financiamento.</li>
                    <li> Cópia do RG e CPF do aluno. </li>
                    <li> Declaração de Solicitação de Transferência FIES original.</li>
                    <li> 
                        <a href="https://cdn2.hubspot.net/hubfs/3960387/D%20E%20C%20L%20A%20R%20A%20%C3%87%20%C3%83%20O%20an%C3%A1lise%20transf%20ext%20FIES%2018-1%20-%20este.pdf" target="_blank">
                            link para o modelo de declaração
                        </a>
                    </li>
                  </ul>
                  <br/>
                  <p style={{textAlign:'left', textIndent: '5vh'}}>
                    A CPSA do campus para onde o candidato deseja a transferência, poderá solicitar quaisquer outros documentos para análise. A análise será em conformidade com as normas do FIES, sendo indeferida a permanência no financiamento o pagamento da semestralidade será de responsabilidade do aluno.
                  </p><br/>

              <div style={{textAlign: 'center'}}>
                <input type="checkbox" value={this.props.checkTermofies} onChange={() => this.props.mudarCheckTermofies()} id="termo" name="termo"/>
                &nbsp; &nbsp;  Atesto que li e estou de acordo com o processo de Transferência do Financiamento Estudantil (FIES) descrito acima.
              </div>                  
                </div>
            )
        }else{
          return null;
        }
      }
        
    }

    
}
