import React, { Component } from "react";

export default class SegundaGraduacao extends Component{
    constructor(props) {
        super(props);
      }
    
      render() {
        return(
          <section className="magictime spaceInRight">
            <a onClick={(e) => this.props.selecionaAlavanca(e, 9)} className="signup-btn clearfix" href=""  data-toggle="modal" data-target="#signup-modal">
              <div className="signup-btn-cta d-flex align-items-center">                
                <p className="agende">
                  <span className="title-card">Portador de Diploma</span>
                </p>
                <div className="btn-inscrevase">Inscreva-se</div>
              </div>
              <small className="pre-inscricao bg-blue">
                * Cursos com condições especiais.
              </small>
            </a>
          </section>
        )
      }
    }