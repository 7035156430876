
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config'

const curriculoService = {};

const BASE_URL = '/curriculos'

curriculoService.faseCurricularPassadaComCursoMaiorQueMaxPeriodoMaximo =  function(faseCurricular,codigoCurso){
    return api.getBaseURL(`${BASE_URL}/fase-passada-maior-que-max-curriculo?faseCurricular=${faseCurricular}&codigoCurso=${codigoCurso}`, null,apiBaseProcessoSeletivo)
    .then((res) => {
        return res
    })
}


export default curriculoService;