import React, { Component } from 'react'
export default class TabelaDisciplinas extends Component {
  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Código</th>            
            <th scope="col">Nome</th>
            <th scope="col">Carga Horária</th>
            {this.props.disciplinasProcessadas ? '':<th scope="col">Ações</th>}
          </tr>
        </thead>
        <tbody>

          {this.props.disciplinas.map((el, index) => {
            return (
                <tr key={index}>                
                <td>{el.codigo || 'Não informado'}</td>
                <td>{el.nome}</td>
                <td>{el.cargaHoraria || 'Não informado'}</td>
                {this.props.disciplinasProcessadas ? '' :
                <td>                
                  <a href="" onClick={(e) => {
                    e.preventDefault()
                    this.props.deleteDisciplina(el.idDisciplina)
                  }}
                className="deletar-disciplina">
                <small>[remover]</small></a>                
                {!el.novaDisciplina ? '' :
                  <a href="" onClick={(e) => {
                    e.preventDefault()
                    this.props.preencherInputsDisciplinasComDisciplinaEscolhida(el.idDisciplina)
                  }}
                    className="editar-disciplina">
                    <small>[editar]</small></a>
                }</td>}
              </tr>)
          })}
        </tbody>
      </table>
    )
  }
}

