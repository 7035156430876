import React, { Component } from 'react';
import qrCode from './../../images/banner_qrcode_emec-site_unigranrio2.png';
import logoUnigranrioAfya from './../../images/Logo_UNI-AFYA_branca2.png';
import './style.css';
export default class Footer extends Component {

    render() {
        return (
          <footer
            className="site-footer center"
            id="footer-fatenp"
            style={this.props.style}
          >
            <div className="mb-3 mt-3 row">
              <div class="align-self-center col-md-6 col-xs-12">
                <div className="d-flex justify-content-end pecas">
                  <a
                    href="https://emec.mec.gov.br/emec/consulta-cadastro/detalhamento/d96957f455f6405d14c6542552b0f6eb/Mjg0"
                    target={'_blank'}
                  >
                    <img className="img-fluid" width={350} src={qrCode}  />
                  </a>
                </div>
              </div>
     {/*         <div className="align-self-end col-md-6 col-xs-12">
                <div className="d-flex justify-content-start pecas">
                  <img
               //     src={logoUnigranrioAfya}
                    alt=""
                    width={300}
                    className="img-fluid"
                  />
                </div>
        </div> */}
            </div>
            <p className="site-header-social">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/Fatenp"
                title="Facebook"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              {/* <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/fatenp" title="Twitter">
                            <i className="fab fa-twitter"></i>
                        </a> */}
              {/* <a target="_blank" rel="noopener noreferrer" href="https://plus.google.com/116722107956995296543" title="Google+">
                            <i className="fab fa-google-plus-g"></i>
                        </a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/fatenpunigranrio/"
                title="Instagram"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/fatenp/"
                title="LinkedIn"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCjiSsI5RLgqCWLIwMJ6pDig/videos"
                title="YouTube"
              >
                <i className="fab fa-youtube"></i>
              </a>
            </p>
            <small>
              {new Date().getFullYear()} © Fatenp Unigranrio | Todos os direitos
              reservados | Departamento de Tecnologia da Informação
            </small>
            <p className="site-footer-links">
              <a
                target={'_blank'}
                href="https://portal.unigranrio.edu.br/politica-de-privacidade"
              >
                POLÍTICA DE PRIVACIDADE
              </a>
              {' | '}
              <a
                target={'_blank'}
                href="https://privacyportal-br.onetrust.com/webform/309bef31-1bad-4222-a8de-b66feda5e113/73b12698-ca60-4899-92f8-f02477112b54"
              >
                PEDIDO DO TITULAR DE DADOS
              </a>
            </p>
          </footer>
        );
    }
}