import React, { Component } from 'react'
export default class PreviewDadosAgendamento extends Component {
  
  constructor(props) {
    super(props);
  }
    render() {
        {
          if(this.props.show){
            return (            
              <div id="agendamento" className={`tab-pane fade ${this.props.discoverIsActiveContent('dados-agendamento')}`}>                
              <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label" htmlFor="tipoProva">Tipo da Prova</label>
                        <input className="form-control required"  id="tipoProva"
                         name="tipoProva" type="text" value={this.props.opcaoTipoProva.nome}  readOnly/>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label" htmlFor="localProva">Local de Prova</label>
                        <input className="form-control required"  id="localProva" 
                        name="localProva" type="text" value={this.props.localProva.nome} readOnly/>
                      </div>
                    </div>     
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label" htmlFor="dataProva">Data da Prova</label>
                        <input className="form-control required"  id="dataProva" 
                        name="dataProva" type="text" value={this.props.dataProva} readOnly/>
                      </div>
                    </div>                    
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="control-label" htmlFor="horarioProva">Horário da Prova</label>
                        <input className="form-control required"  id="horarioProva" 
                        name="horarioProva" type="text" value={this.props.horarioProva.agendamento} readOnly/>
                      </div>
                    </div>                    
                       
                  </div>
                </div>
            )
        }else{
          return null;
        }
      }
        
    }

    
}
