import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
export default class ModalContinuarInscricao extends Component {
    render() {
        return (
          <Modal show={this.props.show} >        
          <Modal.Body>
            <div className="div-text-btn">
              <h4>Seu Lead foi cadastrado com sucesso.</h4>
              <h6>Como você deseja prosseguir ?</h6>
              <button  className="continuar" onClick={() => this.props.continuarInscricao()}>Continuar Inscrição</button>
              <button  className="cadastrar" onClick={() => this.props.continuarCadastrando()}>Cadastrar Mais Leads</button>
            </div>
          </Modal.Body>
          <style jsx="true" global="true">{`
              .modal-dialog{margin: 30px auto;}
              .container{margin-bottom: 50px !important;}
              .modal-alerta, .modal-body{background: #266ca9;}
              .modal-body{padding: 20px 20px !important;}
              .modal-body{border-radius: 5px;}
              .modal-content{border-radius: 7px;}
              .modal-alerta > button{
                position: absolute;
                right: 0;
                top: 0;
                padding: 10px 15px;
              }
              .modal-alerta > h3{
                text-align: center;
                font-weight: 300;
                color: #e9ecef;
                font-size:20px;
              }
              .modal-alerta > p > span{                
                text-transform: uppercase;
                font-weight: 700;
                padding:2px;
              }
              .alert{color: #e4b60e;} 
              .erro{color:#ff6c6c;}
              .close{
                color: #ffffff;
                position: absolute;
                z-index: 99999999;
                right: 0px;
                top: 0px;
                padding: 7px 15px !important;
                transition: 0.5s;
              }
              .close:hover, .close:focus{
                color: #f1f1f1;
                cursor: pointer;
                background: #08427eba;
                border-radius: 0px 5px 0px 5px;
                transition: 0.5s;
                outline: none;
              }
              .div-text-btn{text-align: center;}
              .div-text-btn > h4{
                color: #ffffff;
                font-size: 22px;
              }
              .div-text-btn > h6{
                padding-bottom: 30px;
                margin-top: 10px;
                font-size: 18px;
                font-weight: 300;
                color: #f1f1f1;
              }
              .div-text-btn > button{
                border: none;
                padding: 10px 20px;
                border-radius: 8px;
                border-bottom-style: inset;
              }
              .div-text-btn > button:hover,
              .div-text-btn > button:focus{
                cursor: pointer;
              }
              .div-text-btn > .continuar{
                background: #8BC34A;
                color: #ffffff;
                border-bottom: 5px solid #6b9836;
                margin-right: 30px;
              }
              .div-text-btn > .cadastrar{
                background: #ffffff;
                color: #2a77b2;
                border-bottom: 5px solid #e4e4e4;
              }
              @media(max-width: 507px){
                .modal-dialog{margin: 10px !important;}
                .div-text-btn > .continuar{
                  margin-bottom: 30px;
                  margin-right: 0px;
                }
                .div-text-btn > button{
                  margin: 0 auto;
                  display: block;
                  margin-right: auto !important;
                }
              }
            `}
          </style>
        </Modal>
        )
    }

    styles = {
        width100: {
          width: "100%"
        }
      };      
}
