
import api from '../../utils/api';
import {api as webServiceRm} from './../rm';
// import {apiBaseComercial} from '../../config'

const unidadeService = {};

// unidadeService.findById =  function(idUnidadeEnsino){
//     return api.getBaseURL(`/comercial/unidade/${idUnidadeEnsino}`, null, apiBaseComercial)
//     .then((unidade) => {
//         return unidade
//     })
// }
unidadeService.findByRM = async function(dadosUnidade){
  // console.log(`dados unidade:`, dadosUnidade);
   
  // const url = `modalidades?idInstituicaoMantida=20&idAlavanca=${dadosUnidade.idAlavanca}&idUnidade=1`;
  const url = `polos?idAlavanca=${dadosUnidade.idAlavanca}&idCurso=${dadosUnidade.idCurso ? dadosUnidade.idCurso : 0}`;
  const { data } = await webServiceRm.get(
    url
  );

  return data;
}
// unidadeService.findByEntity = async function(dadosUnidade){
    
//   //  console.log(dadosUnidade);

//     return api.postBaseURL('/comercial/unidade/search/', dadosUnidade,apiBaseComercial)
//     .then((unidades) => {
//         return unidades
//     })
// }

// unidadeService.findByNomeSemEspaco =  function(dadosUnidade){
//     return api.postBaseURL('/comercial/unidade/nome-sem-espaco', dadosUnidade,apiBaseComercial)
//     .then((unidade) => {
//         return unidade
//     }).catch(err => {
//         if (err.message === "Sem token" || err.status === 401) {  
//           api.authenticar()
//             .then(() => {
//               return  unidadeService.findByNomeSemEspaco(dadosUnidade);
//             })
//         }
//     });
// }


export default unidadeService;