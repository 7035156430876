import React, { Component } from 'react';
import logoUnigranrio from '../../images/Logo_UNI-AFYA_branca2.png';
import wpp from '../../images/icons/whatsapp.png';

export default class Header extends Component {
    render() {
        return (
            <header className="site-header background-unigranrio magictime boingInUp">
                <div className="container-fluid-courses">
                    <div className="row">
                        <div className="col-md-4">
                            <a href="https://portal.unigranrio.edu.br/" >
                                <img className="site-header-logo img-fluid mb-3 mb-md-0" src={logoUnigranrio} alt="Universidade Unigranrio" />
                            </a>
                        </div>
                        <div className="col-md-8 text-md-right">
                            {/* <span className="site-header-social">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/unigranrio" title="Facebook">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="http://twitter.com/unigranrio" title="Twitter">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://plus.google.com/u/0/109177974170765826768/" title="Google+">
                                    <i className="fab fa-google-plus-g"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="http://instagram.com/unigranrio" title="Instagram">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="http://www.linkedin.com/company/universidade-unigranrio" title="LinkedIn">
                                    <i className="fab fa-linkedin-in"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="http://www.youtube.com/user/unigranrioonline" title="YouTube">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </span> */}

                            <div className="site-header-nav2">
                                 <a href="http://portal.unigranrio.edu.br " target="_blank" rel="noopener noreferrer" title="Home">Home</a>
                                <span className="mx-2">|</span>
                                <a href="https://vestibular.unigranrio.edu.br/curso/6" target="_blank" rel="noopener noreferrer">Cursos</a>
                                <span className="mx-2">|</span>
                                {/* <a href="/hotsite/informacoes/" onClick={(e) => { this.props.history.push('/informacoes'); e.preventDefault() }}>Editais e Regulamentos</a> */}
                                <a href="http://unigranrio.com.br/vestibular/edital-regulamento.php" target="_blank" rel="noopener noreferrer">Editais e Regulamentos</a>
                                <span className="mx-2">|</span>
                                <a href="http://unigranrio.com.br/vestibular/fale-conosco.php" target="_blank" rel="noopener noreferrer">Contato</a>
                            </div>

                            <div className="site-header-nav2">
                                <img src={wpp} alt="" className="icon-wpp"/>
                                <a href="https://hubs.ly/H0l5fNf0" target="_blank" rel='noopener noreferrer' className="wpp">  21 3219-4040</a> 
                                <span className="mx-2">|</span>
                                <a href="" target="_blank" rel='noopener noreferrer' className="wpp"> 0800 003 4040</a> 
                                {/* <a href="http://www.unigranrio.com.br/" target="_blank" rel="noopener noreferrer">Ir para o portal</a> */}
                                {/* <span className="mx-2">|</span> */}
                                {/* <a href="http://www2.unigranrio.br/possoajudar/" target="_blank" rel="noopener noreferrer">FAQ</a>
                                <span className="mx-2">|</span> */}
                                
                            </div>

                        </div>
                    </div>
                </div>
            </header>

        )
    }
}




