import React, { Component } from 'react'
export default class PreviewDadosOferta extends Component {
  
  constructor(props) {
    super(props);
  }
    render() {
        return (            
            <div id="dados-oferta" className={`tab-pane fade ${this.props.discoverIsActiveContent('dados-oferta')}`}>               
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="curso">Curso</label>
                  <input className="form-control required"  id="curso"
                   name="curso" type="text" value={this.props.curso.nome}  readOnly/>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="unidade">Unidade</label>
                  <input className="form-control required"  id="unidade" 
                  name="unidade" type="text" value={this.props.unidade.nome} readOnly/>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="formaIngresso">Forma de Ingresso</label>
                  <input className="form-control required"  id="formaIngresso" name="formaIngresso"
                   type="text" value={this.props.opcaoTipoProva.nomeFormaIngresso} readOnly/>
                </div>
              </div>        
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="modalidade">Modalidade</label>
                  <input className="form-control required"  id="modalidade" name="modalidade"
                   type="text" value={this.props.modalidade.modalidadeEnsino} readOnly/>
                </div>
              </div>                   
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="turno">Turno</label>
                  <input className="form-control required"  id="turno" name="turno"
                   type="text" value={this.props.turno.nome} readOnly/>
                </div>
              </div>             
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="valorPromocional">Valor Promocional</label>
                  <input className="form-control required"  id="valorPromocional" name="valorPromocional"
                   type="text" value={this.props.turno.valorPromocional} readOnly/>
                </div>
              </div>             
            </div>
          </div>
        )
    }

    
}
