import React, { Component } from 'react'
import PagamentoCartao from './PagamentoCartao';
import DadosCandidato from '../components/candidato/DadosCandidato';
import ReactToPrint from "react-to-print";
import { tipoErro } from '../components/utils/Warnings';
import utilsApiBoleto from '../utils/utils-api-boleto';
import registroTituloService from '../services/financeiro/registro-titulo-service'


import { idFormaPagamentoCartao, idFormaPagamentoBoleto, 
    // apiBoleto 
} from '../config';
import * as html2canvas from 'html2canvas'
import * as jsPDF from 'jspdf';
import { copyToClipBoard } from '../utils/string'
import { removerMascaras } from '../utils/string'
import { getBaseUrl } from '../utils/string'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { checkIsNotApiProd, redirecionarPageVisualizacaoComprovante } from '../utils/url';
import candidatoService from '../services/processo-seletivo/candidato-service';
import Carregando from '../components/utils/Carregando';

export default class SucessoInscricao extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mostrarInputCartao: false,
            finalizouInscricao: false,
            idRegistroTituloBoleto: 0,
            toastId: 0,
            finalizandoInscricao: false
        }

        this.pagarComBoleto = this.pagarComBoleto.bind(this);
        this.pagarComCartao = this.pagarComCartao.bind(this);
        this.registrarTitulo = this.registrarTitulo.bind(this);
        this.imprimirBoleto = this.imprimirBoleto.bind(this);
        this.trocarEstadoFinalizouInscricao = this.trocarEstadoFinalizouInscricao.bind(this);
        this.finalizarInscricao = this.finalizarInscricao.bind(this);
        this.mudarStateSucessoInscricao = this.mudarStateSucessoInscricao.bind(this);
        this.continuarTransferenciaReingresso = this.continuarTransferenciaReingresso.bind(this);
        this.copiarLink = this.copiarLink.bind(this);
        this.enviarEmailConfirmacao = this.enviarEmailConfirmacao.bind(this)
    }

    componentDidMount() {
        utilsApiBoleto.carregarDownloadAPI();
        if (this.props.txRetorno === 'Candidato já possuí uma inscrição!') {
            this.exibirMensagemEmCasoDeCandidatoJaMatriculado(this.props.idCandidato)
        }
    }

    pagarComBoleto() {
        if (this.props.opcaoTipoProva.vrInscricao !== '0,00') {
            this.registrarTitulo(idFormaPagamentoBoleto);
        }
    }

    pagarComCartao() {
        this.setState({ mostrarInputCartao: !this.state.mostrarInputCartao });
        if (this.props.opcaoTipoProva.vrInscricao !== '0,00') {
            this.registrarTitulo(idFormaPagamentoCartao);
        }
    }

    exibirMensagemEmCasoDeCandidatoJaMatriculado(idCandidato) {
        candidatoService.verificarSeCandidatoJaEstaMatriculado(idCandidato)
            .then(candidato => {
                if (candidato.id !== 0) {  
                    // Candidato Pré Matriculado                  
                    this.props.openWarningModal(`
                    Foi localizado em sistema que você possui um processo seletivo em andamento para este semestre, curso, unidade, modalidade e turno. PRÉ-MATRÍCULA N.º ${candidato.id}. Para maiores informações ligue para 021 3219-4040 (candidatos FATENP devem ver o número 048 3242-1001) ou 0800 003 4040 
                    `,tipoErro.alerta)
                }else{
                    this.props.openWarningModal(`
                    Foi localizado em sistema que você possui um processo seletivo em andamento para este semestre, curso, unidade, modalidade e turno. INSCRIÇÃO  N.º ${idCandidato}. Para maiores informações ligue para 021 3219-4040 (candidatos FATENP devem ver o número 048 3242-1001) ou 0800 003 4040 
                    `,tipoErro.alerta)
                }
            })
            .catch(() => {
                this.props.openWarningModal(null, null, true)
            })
    }

    registrarTitulo(idFormaPagamento) {
        if (this.state.idRegistroTituloBoleto) {
            if (idFormaPagamento === idFormaPagamentoBoleto) {
                utilsApiBoleto.openPageBoleto(this.state.idRegistroTituloBoleto)
            }
        }
        const dadosRegistroTitulo = {
            idFormaPagamento: idFormaPagamento,
            idTitulo: this.props.idTitulo,
        }
        registroTituloService.insert(dadosRegistroTitulo)
            .then(returnParamsProcedure => {
                const idRegistroTitulo = returnParamsProcedure.idRegistroTitulo;
                const idRegistroTituloCartao = returnParamsProcedure.idRegistroTituloCartao;

                if (idFormaPagamento === idFormaPagamentoBoleto && !this.state.idRegistroTituloBoleto) {

                    this.setState({
                        carregando: false,
                        idRegistroTituloBoleto: idRegistroTitulo,
                        idRegistroTitulo: idRegistroTitulo,
                    }, () => {
                        this.props.definirEstadoComponentePai('idRegistroTitulo', idRegistroTitulo);
                        this.props.definirEstadoComponentePai('idRegistroTituloBoleto', idRegistroTitulo);
                        utilsApiBoleto.openPageBoleto(this.state.idRegistroTituloBoleto);
                    });

                }

                this.setState({
                    carregando: false,
                    idRegistroTitulo: idRegistroTitulo,
                    idRegistroTituloCartao: idRegistroTituloCartao,
                });

                this.props.definirEstadoComponentePai('idRegistroTitulo', idRegistroTitulo);
                this.props.definirEstadoComponentePai('idRegistroTituloCartao', idRegistroTituloCartao);

            })
            .catch(() => {
                this.setState({ carregando: false });
            })
    }

    imprimirBoleto(idRegistroTituloBoleto) {
        utilsApiBoleto.downloadFile(idRegistroTituloBoleto)
    }

    trocarEstadoFinalizouInscricao() {
        this.setState({ finalizouInscricao: true });
    }

    continuarTransferenciaReingresso() {        
        const candidato = {
            id:this.props.idCandidato
            , dtNascimentoFormatoAmericano:this.props.lead.dataNascimento
            , documentoIdentificacao:this.props.lead.cpf
            , idInstituicaoMantida:this.props.idInstituicaoMantida
            ,
        }
        redirecionarPageVisualizacaoComprovante(candidato, true)
    }
    copiarLink() {
        if (toast.isActive(this.state.toastId)) {
            return;
        }
        if (this.props.idInstituicaoMantida === 1) {
            copyToClipBoard(`${getBaseUrl()}/candidato/${this.props.idCandidato}/${this.props.lead.dataNascimento}/${removerMascaras(this.props.lead.cpf)}`);
        } else {
            copyToClipBoard(`${getBaseUrl()}/candidato-fatenp/${this.props.idCandidato}/${this.props.lead.dataNascimento}/${removerMascaras(this.props.lead.cpf)}`);
        }
        this.setState({
            toastId: toast.success("Link Copiado!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000
            })
        });
    }

    mudarStateSucessoInscricao(campo, valor) {
        this.setState({ [campo]: valor });
    }

    printDocument() {
        const input = document.getElementById('dateToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', -42, 20);
                // pdf.output('dataurlnewwindow');
                pdf.save("comprovante-inscricao.pdf");
            })
            ;
    }

    finalizarInscricao() {
        window.location.href = `http://inscricao.${this.props.idInstituicaoMantida === 1 ? 'unigranrio' : 'fatenp'}.com.br/processoSeletivo/paginas/publicas/vestibular/paginaInformacoesEtapasMatricula.jsf`            
        this.setState({mostrarComprovanteInscricao: true});
    }

    enviarEmailConfirmacao() {
        if(this.props.lead && this.props.lead.cpf && this.props.lead.dataNascimento && this.props.idCandidato){
            const dadosCandidato = {
              id: this.props.idCandidato,
              dataNascimento: this.props.lead.dataNascimento,
              cpf: this.props.lead.cpf
            }
            candidatoService.findOneByIdAndDateNascimentoAndCpf(dadosCandidato)
            .then(candidato =>{     
              candidatoService.enviarEmailConfirmacao(candidato)
              .then(()=>{
                this.props.openWarningModal('A confirmação de inscrição foi enviada novamente para o seu e-mail.', '')
              })
            })
          }
    }

    render() {
        return (
            <div className="container">
                <ToastContainer />
                {
                    this.state.finalizouInscricao
                        ?
                        <div>
                            <h1 className="obrigado-inscricao">OBRIGADO POR SE INSCREVER!</h1>
                            <p className="text-pos-obrigado">Sua inscrição foi realizada com sucesso. <br />Imprima o comprovante e apresente no dia da prova.</p>
                        </div>
                        :
                        null
                }

                {
                    this.props.inscricaoConcluida
                        ?
                <React.Fragment>

                {/* Caso transferência e reingresso, para ter um destaque nos botões 
                de continuar inscrição, foi optado por esconder esses Componente dentro do Próximo React.fragment */}
                {this.props.idAlavanca !== 3 && this.props.idAlavanca !== 4 ?
                <React.Fragment>
                            <div id="dateToPrint" >
                                <DadosCandidato  {... this.props} {... this.state} ref={el => (this.componentRef = el)} />
                            </div>
                            <div className="imprimir-salvar-comprovante">
                                <ReactToPrint
                                    trigger={() =>
                                        <button className="imprimir-comprovante">Imprimir Comprovante de inscrição</button>}
                                    content={() => this.componentRef}
                                />
                                <button   onClick={() => {
                                                this.continuarTransferenciaReingresso()
                                            }}className="imprimir-comprovante">Salvar comprovante como PDF</button>
                                <button onClick={this.enviarEmailConfirmacao} className="imprimir-comprovante">Reenviar e-mail de confirmação</button>
                            </div>
                    </React.Fragment>:null}
                    </React.Fragment>

                        : null
                }

                {
                    this.state.finalizouInscricao || (this.props.idAlavanca === 2 || this.props.idAlavanca === 3 || this.props.idAlavanca === 4 || parseInt(this.props.opcaoTipoProva.vrInscricao, 10) === 0)
                        ?
                        null
                        :
                        <div className="form-group">
                            <div className="row">
                                <div className="col col-sm-12 center">
                                    <p className="text-inscricao">
                                        Sua inscrição só terá validade após o pagamento da taxa de inscrição.
                                        <br />Escolha uma das formas abaixo:
                                    </p>

                                    <button className="btn-segunda-graduacao" onClick={this.pagarComBoleto}>
                                        Imprimir Boleto Bancário
					                </button>
                                    <button className="transf-externa" onClick={this.pagarComCartao}>
                                        Pagar com Cartão de Crédito
						            </button>
                                    {
                                        this.state.idRegistroTituloBoleto
                                            ? <iframe title="Boleto" id="iframe-boleto" name="iframe-boleto" ref={el => (this.refBoleto = el)} src={"" + this.state.idRegistroTituloBoleto} frameBorder="0"></iframe>
                                            : null
                                    }

                                </div>
                            </div>
                        </div>
                }
                {/* Caso transferência e reingresso */}
                {this.props.idAlavanca === 3 || this.props.idAlavanca === 4 ?
                    <div>
                        {this.state.finalizandoInscricao ? <Carregando message="Processando sua inscrição" /> :
                            <div className="row">
                                <div className="col col-sm-12 center">
                                    <h4 className="span" style={{ color: 'orange' }}>
                                        Sua inscrição só terá validade após a conclusão da segunda etapa de inscrição
                                    </h4>
                                    <div className="imprimir-salvar-comprovante">
                                        {/*<button style={{ backgroundColor: '#F1702B', boxShadow: '0px 5px 0px 0px #a2552c' }} className="imprimir-comprovante"
                                            onClick={this.finalizarInscricao}>
                                            Continuar Segunda Etapa da Inscrição Presencialmente
                </button>*/}
                                        <button className="salvar-pdf"
                                            onClick={() => {
                                                this.continuarTransferenciaReingresso()
                                            }}>
                                            Continuar Segunda Etapa da Inscrição Online
                </button>

                                    </div>
                                </div>
                            </div>}

                    </div>
                    : null}

                {
                    this.state.mostrarInputCartao
                        ? <PagamentoCartao {... this.props} {... this.state} trocarEstadoFinalizouInscricao={this.trocarEstadoFinalizouInscricao} mudarStateSucessoInscricao={this.mudarStateSucessoInscricao} />
                        : null
                }
            </div>
        )
    }
}