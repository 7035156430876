import React, { Component } from "react";

import Container from "../../components/Container";

import Carregando from '../../components/utils/Carregando';
import CurrencyFormat from 'react-currency-format';
import unidadeService from '../../services/comercial/unidade-service';
import modalidadeEnsinoService from '../../services/comercial/modalidade-ensino-service';
import ofertaService from '../../services/comercial/oferta-service';

export default class CursoListByUnidade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldCursos: [],
      cursos: [],
      unidade:'',
      modalidades: [],
      arrayModalidades: [],
      carregando: true, 
      semOfertaOuUnidadeInativada:false,     
      pesquisa: {
        nomeCurso: "",
        idUnidadeEnsino: 0,
        idAlavanca: 0,
        idModalidadeEnsino: 0,   
        idInstituicaoMantida:0
      },     
    };

    this.mudarPagina = this.mudarPagina.bind(this);
    this.renderizarCursos = this.renderizarCursos.bind(this);    
    this.renderizarModalidades = this.renderizarModalidades.bind(this);
    this.buscarCursosPorInput = this.buscarCursosPorInput.bind(this);    
    this.buscarPorModalidade = this.buscarPorModalidade.bind(this)
    this.escolherCurso = this.escolherCurso.bind(this);
  }

  buscarCursosPorInput(e) {
    this.alterarStatePesquisa(e);
    const cursosFiltrados = this.state.oldCursos.filter(cursoModalidades => cursoModalidades.curso.nome.toLowerCase().includes(e.target.value.toLowerCase()));
    this.setState({ cursos: this.renderizarCursos(cursosFiltrados) });
  } 

  buscarPorModalidade(e) {
    this.alterarStatePesquisa(e)
    this.chamarApiPesquisaCursos(e)
  }

  componentDidMount() {    
    //Pesquisa Todas as Unidades
    this.setState({ listaCorpo: this.state.list });
    this.obterUnidade()
    
  }

  escolherCurso(e, curso) {
    e.preventDefault();
    let url = `${this.props.location.pathname.substring(0,this.props.location.pathname.lastIndexOf('/'))}/0/${curso.id}/${this.state.pesquisa.idUnidadeEnsino}`
    if(this.state.unidade.codigo === '30' || this.state.unidade.codigo === '31'){    
      url = url.replace('unidade/','fatenp/')  
    }else{
      url = url.replace('unidade/','curso/')  
    }
    this.props.history.push(url, { curso: curso, unidades: this.state.arrayUnidades, lead: this.props.location.state ? this.props.location.state.lead : null });
  }  

  obterUnidade() {            
    let parametroPesquisa;
    let method;    
    if(!isNaN(this.props.match.params.parametroPesquisa)){ // Busca pelo id
      parametroPesquisa = this.props.match.params.parametroPesquisa;
      method = 'findById';
    }else{ // Busca pelo nome
      parametroPesquisa = {nomeUnidadeEnsino:this.props.match.params.parametroPesquisa}
      method = 'findByNomeSemEspaco';
    } 
    unidadeService[method](parametroPesquisa)    
      .then(unidade => {
        if(!unidade ||  unidade.ativa === false){
          this.setState({semOfertaOuUnidadeInativada:true,carregando:false})
          setTimeout(() => {
            window.location.href = "https://portal.unigranrio.edu.br/"
          }, 3000);
        }else{
          let tempPesquisa = this.state.pesquisa
          tempPesquisa.idUnidadeEnsino = unidade.id
          this.setState({unidade:unidade,pesquisa:tempPesquisa}, ()=>{
            this.obterModalidades() 
            this.chamarApiPesquisaCursos() 
          })          
        }        
      })      
  }

  mudarPagina(e, url) {
    e.preventDefault();
    this.props.history.push(url);
  }

  renderizarUnidades(unidades) {
    let temp = this.state.pesquisa
   
    this.setState({pesquisa:temp})    
    return unidades.map((el, i) => (
      <option key={i} value={el.id}>
        {el.nome}
      </option>
    ));
  }

  renderizarModalidades(modalidades) {         
    return modalidades.map((el, i) => (
      <option key={i} value={el.id}>
        {el.nomeReduzido}
      </option>
    ));
  }

  alterarStatePesquisa(event) { 
    let temp = this.state.pesquisa
    if (event.target.value !== null) {
      temp.nomeCurso = event.target.name === 'nomeCurso' ? event.target.value : this.state.pesquisa.nomeCurso;
      temp.idUnidadeEnsino = event.target.name === 'idUnidadeEnsino' && this.state.idUnidadeEnsino !== 0 ? parseInt(event.target.value, 10) : this.state.pesquisa.idUnidadeEnsino;
      temp.idModalidadeEnsino = event.target.name === 'idModalidadeEnsino' && this.state.idModalidadeEnsino !== 0 ? parseInt(event.target.value, 10) : this.state.pesquisa.idModalidadeEnsino;
    }
    this.setState({ pesquisa: temp });
  }

  chamarApiPesquisaCursos() {    
    ofertaService.findCursoInOfertaByEntity(this.state.pesquisa)    
      .then(cursos => {
        const stateCursos = this.renderizarCursos(cursos);
        this.setState({ cursos: stateCursos, oldCursos: cursos, carregando: false });
      });      
  }

  obterModalidades(){                     
      let parametrosPesquisa;
      if(this.state.unidade.codigo === '30'){
         parametrosPesquisa = {
          idInstituicaoMantida:2
        }
      }else{
         parametrosPesquisa = {
          idInstituicaoMantida:1
        }
      }
      modalidadeEnsinoService.findByEntity(parametrosPesquisa)            
      .then(modalidades =>{
        this.setState({ modalidades: this.renderizarModalidades(modalidades), arrayModalidades: modalidades });
      });      
    }

  urlCurso(curso){    
    let url = `${this.props.location.pathname.substring(0,this.props.location.pathname.lastIndexOf('/'))}/0/${curso.id}/${this.state.pesquisa.idUnidadeEnsino}`
    if(this.state.unidade.codigo === '30' || this.state.unidade.codigo === '31'){      
      return url.replace('unidade/','fatenp/')  
    }    
    return url.replace('unidade/','curso/')    
  }

  renderizarCursos(cursos) {
    if (!cursos.length) {
      return (<p> Em breve, inscrições abertas para os nossos cursos.</p>)
    }
    
    return cursos.map((el, i) => (
      <tr key={i}>
        <td className="width30">
          <i className="fa fa-angle-right" />
          <a className="" href={this.urlCurso(el.curso)} onClick={(e) => this.escolherCurso(e, el.curso)}>
            {el.curso.nome}
          </a>
        </td>
        <td>

        { el.valorApartirDe !== '' ? 
          <CurrencyFormat value={el.valorApartirDe}
                          thousandSeparator="."
                          decimalScale={2}
                          fixedDecimalScale={true}
                          decimalSeparator=","
                          displayType={'text'} 
                          prefix={'R$ '} />
                          : 'Sob consulta'
        } 
             
       </td>
        <td>
          {
            el.modalidades.map((modalidade, i) =>
              <i key={i} className={
                modalidade.nomeReduzido.includes('SemiPresencial') ? 'indicador semi' :
                modalidade.nomeReduzido.includes('EaD') ? 'indicador ead' :
                modalidade.nomeReduzido.includes('Presencial') ? 'indicador presencial' : 'indicador'
              }>
                {modalidade.nomeReduzido.includes('SemiPresencial') ? 'S' :
                  modalidade.nomeReduzido.includes('EaD') ? 'E' :
                  modalidade.nomeReduzido.includes('Presencial') ? 'P' : ''}
              </i>)
          }
        </td>
        <td className="width30">
          <i className="fa fa-hourglass" />
          {el.curso.duracao}
        </td>
        <td>
          <button
            className="btn btn-primary"
            onClick={(e) => this.escolherCurso(e, el.curso)}
          >
            ESCOLHER CURSO
            </button>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <Container {...this.props} {...this.state}>
        <div>
          <section className="main">
            <div className="hero">
              <div className="container">
                <h2>{this.state.unidade.nome}</h2>
              </div>
            </div>

            <div className="filters">
              <div className="container">
                {/* <p className="text_unigranrio_crusolist">
                 Removido
                </p> */}

                <div className="row d-flex align-items-center">
                  <div className="col-sm-4 col-lg-4 mb-2 mb-sm-0">
                    <label className="font-700">Nome do Curso:</label>
                    <input
                      onChange={this.buscarCursosPorInput}
                      value={this.state.nomeCurso}
                      className="search form-control form-control-sm input-height"
                      type="text"
                      id="s"
                      name="nomeCurso"
                      placeholder="Exemplo: Enfermagem"
                    />
                  </div>

                   <div className="col-sm-4 col-lg-4 mb-2 mb-sm-0">
                      <label className="font-700">Oferta</label>
                      <select name="idModalidadeEnsino" id="idModalidadeEnsino" onChange={this.buscarPorModalidade} style={{ padding: "0" }} className="form-control form-control-sm select-curso input-height">
                        <option value="0">-- Todas --</option>
                        {this.state.modalidades}

                      </select>
                   </div>
                  <div className="legend-buttons col-lg-4 d-none d-lg-block mgtop">
                    <span className="legend-block">
                      <span className="indicador presencial">P</span> PRESENCIAL
                    </span>
                    <span className="legend-block">
                      <span className="indicador ead">E</span> EAD
                    </span>
                    <span className="legend-block">
                      <span className="indicador semi">S</span> SEMIPRESENCIAL
                    </span>
                  </div>
                  <div className="col-sm-4 col-lg-3" />
                </div>
              </div>
            </div>

              {this.state.carregando ? <Carregando />:
            <div className="container">            
              {this.state.semOfertaOuUnidadeInativada ? 
                  (<h2 className="text-center">PÁGINA INDISPONÍVEL NO MOMENTO</h2>):
                  <div className="table-responsive content-cursos" id="yep">        
                  <table className="courses-table table tabela-cursos table-sm">                  
                    <thead>
                      <tr className="col_list_cursos">
                        <th>Curso</th>
                        <th>A partir de</th>
                        <th>Oferta</th>
                        <th>Duração</th>
                        <th />
  
                      </tr>
                    </thead>
                    
                    <tbody className="list">{this.state.cursos}</tbody>
                  </table>
  
                  <div className="content-boxes" />
                </div>}             
             
              
            </div>}
          </section>
        </div>
      </Container>
    );
  }
}
