import React, { Component } from 'react'

export default class EscolhaFormaIngresso extends Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {  
        if (this.props.idCurso === 106 && +e.target.value === 1) {
            window.location.href = 'http://inscricao.unigranrio.edu.br/processoSeletivo/vestibular-inscricao?redir=false'
        } else if (this.props.idCurso === 106 && +e.target.value === 2) {
            window.location.href = 'http://inscricao.unigranrio.edu.br/processoSeletivo/enem-inscricao'
        } else {
            this.props.trocarFormaIngresso(parseInt(e.target.value, 10));   
                         
        }  
    }

    render() {
        return ( 
            <div className="col-sm-12 col-mod-12 no-pd mgb-40" id="radio-full">
                <h1 className="h3 text-blue mb-4 mt-4">Escolha a Forma de Ingresso</h1>
                <div className="row">
                    {/* <div className="bloco-desconto">50% OFF</div> */}
                    <div className="radio col-sm-2">
                        <input   onChange={(e) => this.onChange(e)} checked={this.props.idAlavanca === 6} defaultChecked='true' type="radio" name="optradio" className="radio-ingresso" id="vestibularOnline" value="6" />
                        <label htmlFor="vestibularOnline" className="label-ingresso">Vestibular On-line</label>
                    </div>

                    <div className="radio col-sm-2">
                        <input  onChange={(e) => this.onChange(e)} checked={this.props.idAlavanca === 5} type="radio" name="optradio" className="radio-ingresso" id="enem" value="5" />
                        <label htmlFor="enem" className="label-ingresso">Enem</label>
                    </div>
                    <div className="radio col-sm-2">
                        <input  onChange={(e) => this.onChange(e)} checked={this.props.idAlavanca === 8} type="radio" name="optradio" className="radio-ingresso" id="transferencia-externa" value="8" />
                        <label htmlFor="transferencia-externa" className="label-ingresso">Transferência Externa</label>
                    </div>
                    <div className="radio col-sm-2">
                        <input  onChange={(e) => this.onChange(e)} checked={this.props.idAlavanca === 9} type="radio" name="optradio" className="radio-ingresso" id="reingresso" value="9" />
                        <label htmlFor="reingresso" className="label-ingresso">Portador de Diploma</label>
                    </div>

                    <div className="radio col-sm-2">
                        <input   onChange={(e) => this.onChange(e)} checked={this.props.idAlavanca === 10} type="radio" name="optradio" className="radio-ingresso" id="historicoEscolar" value="10" />
                        <label htmlFor="historicoEscolar" className="label-ingresso">Histórico Escolar</label>
                    </div>

                    {/* <div className="radio div col-sm-2">
                        <input  onChange={(e) => this.onChange(e)} checked={this.props.idAlavanca === 99} type="radio" name="optradio" className="radio-ingresso" id="vestibular" value="99" />
                        <label htmlFor="vestibular" className="label-ingresso"> Vestibular</label>
                    </div> */}
                </div>
            </div>
        )
    }
}
