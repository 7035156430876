import React, { Component, useState } from 'react';
import Show from '../operator/Show';
import Dropdown from 'react-bootstrap/Dropdown';

export default class CardEnem extends Component {
  
 
  constructor(props) {
        super(props);
        this.state = {
                show:false,
        }
    }
    
    render() {

      
        return (
          <section className="signup-btn clearfix magictime spaceInRight" style={{zIndex: 2}} >
            <div className="signup-btn-cta align-items-center">
              <div className="row">
                <div className="col-sm-6">
                  <p>
                    <h6 className="mb-1"></h6>
                    <span className="title-card">ENEM</span>
                  </p>
                </div>
                <Show show={this.props.idMantida === 1}>
                  <div
                    className="col-sm-3 mb-1"
                    style={{ paddingRight: '7px', paddingLeft: '7px' }}
                  >
                    {/* <a onClick={(e) => this.props.selecionaAlavanca(e,998)} href="" data-toggle="modal" data-target="#signup-modal">
                                    <button className="btn btn-enem-vestibular btn-block" >
                                        <strong>Medicina</strong>
                                    </button>
                                </a> */}


                  <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className='btn btn-block btn-enem-vestibular'>
                          Medicina
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu p-0 show text-center w-100'>
                          <Dropdown.Item href="http://processoseletivo.unigranrio.edu.br/?idPs=2591&pidarea=2743" className='btn btn-block btn-enem-vestibular m-0' target={'_blank'}>Barra da Tijuca</Dropdown.Item>
                          <Dropdown.Item href="http://processoseletivo.unigranrio.edu.br/?idPs=2592&pidarea=2717" className='btn btn-block btn-enem-vestibular m-0' target={'_blank'}>Duque de Caxias</Dropdown.Item>
                          
                        </Dropdown.Menu>
                      </Dropdown>
                    {/* <div className='dropdown'>

                    <button onClick={()=> {this.setState({show: !this.state.show}) }} class="btn btn-enem-vestibular btn-block dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <strong>Medicina</strong>
                    </button>
                    <div class={`dropdown-menu p-o w-100 ${this.state.show && 'show'}`} aria-labelledby="dropdownMenu">
                    <a
                      href={`${process.env.REACT_APP_URL_ENEM_MED}`}
                      target="_blank"
                      data-toggle="modal"
                      data-target="#signup-modal"
                     
                    >
                      <button
                        className="dropdown-item btn btn-enem-vestibular btn-block text-center"
                        type="button"
                      >
                        <strong>Barra da Tijuca</strong>
                      </button>
                    </a>

                    <a
                      href={`${process.env.REACT_APP_URL_ENEM_MED}`}
                      target="_blank"
                      data-toggle="modal"
                      data-target="#signup-modal"
                     
                    >
                      <button
                        className="dropdown-item btn btn-enem-vestibular btn-block text-center"
                        type="button"
                      >
                        <strong>Duque de Caxias</strong>
                      </button>
                    </a>
                      
                    </div>
                    </div> */}
                    {/* <a
                      href={`${process.env.REACT_APP_URL_ENEM_MED}`}
                      target="_blank"
                      data-toggle="modal"
                      data-target="#signup-modal"
                    >
                      <button
                        className="btn btn-enem-vestibular btn-block"
                        type="button"
                      >
                        <strong>Medicina</strong>
                      </button>
                    </a> */}
                  </div>
                </Show>
                <div
                  className={`${
                    this.props.idMantida === 1 ? '' : 'offset-3'
                  } col-sm-3 mb-1`}
                  style={{ paddingRight: '7px', paddingLeft: '7px' }}
                >
                  <a
                    onClick={(e) => this.props.selecionaAlavanca(e, 5)}
                    href=""
                    data-toggle="modal"
                    data-target="#signup-modal"
                  >
                    <button
                      className="btn btn-enem-vestibular btn-block"
                      type="button"
                    >
                      <strong>
                        {this.props.idMantida === 1
                          ? 'Outros Cursos'
                          : 'Inscreva-se'}
                      </strong>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <small className="pre-inscricao bg-blue">
              * Cursos com condições especiais.
            </small>
            {/* <ModalFormLead history={this.props.history} handleCloseModal={this.handleCloseModal} idAlavanca={this.state.idAlavanca} show={this.state.show} /> */}
          </section>
        );
    }
}
