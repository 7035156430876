import React, { Component } from "react";
import Show from "../operator/Show";


export default class CardTransferencia extends Component {
    constructor(props) {
        super(props);
      }
    
      render() {
        return (
          <section className="signup-btn clearfix magictime spaceInRight">
            <div className="signup-btn-cta align-items-center">
              <div className="row">
                <div className="col-sm-6">
                  <p>
                    <h6 className="mb-1"></h6>
                    <span className="title-card">Transferência</span>
                  </p>
                </div>
                <Show show={this.props.idMantida === 1}>
                  <div
                    className="col-sm-3 mb-1"
                    style={{ paddingRight: '7px', paddingLeft: '7px' }}
                  >
                    {/* <a
                      href={`${process.env.REACT_APP_URL_TRANSF_MED}`}
                      target="_blank"
                      data-toggle="modal"
                      data-target="#signup-modal"
                    >
                      <button
                        className="btn btn-enem-vestibular btn-block"
                        type="button"
                      >
                        <strong>Medicina</strong>
                      </button>
                    </a> */}
                  </div>
                </Show>
                <div
                  className={`${
                    this.props.idMantida === 1 ? '' : 'offset-3'
                  } col-sm-3 mb-1`}
                  style={{ paddingRight: '7px', paddingLeft: '7px' }}
                >
                  <a
                    onClick={(e) => this.props.selecionaAlavanca(e, 8)}
                    href=""
                    data-toggle="modal"
                    data-target="#signup-modal"
                  >
                    <button
                      className="btn btn-enem-vestibular btn-block"
                      type="button"
                    >
                      <strong>
                        {this.props.idMantida === 1
                          ? 'Outros Cursos'
                          : 'Inscreva-se'}
                      </strong>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <small className="pre-inscricao bg-blue">
              {' '}
              * Cursos com condições especiais.
            </small>
          </section>
        );
      }
    }