import React, { Component } from 'react'
import logo from "../../images/hotsite-img/logo-unigranrio2.png";
import logofatenp from "../../images/hotsite-img/logo-fatenp.png";
import localforage from 'localforage';
export default class DadosCandidatoBuscarInscricao extends Component {
    constructor(props) {
        super(props);        
            this.state = {
                possuiFies:false,
            }
        
        this.verificaNulo = this.verificaNulo.bind(this);
    
    }
    componentWillMount(){
        localforage.getItem('idCandidatoPossuiFies').then((idCandidatoPossuiFies)=>{
            if(this.props.candidato.id === idCandidatoPossuiFies){
                this.setState({possuiFies:true})
            }
        })
    }
    verificaNulo(propery){
        return propery ? propery: 'Não definido'
    }
    render() {
        return (
            <div>
            <img src={this.props.candidato.idInstituicaoMantida === 1 ? logo : logofatenp} className="logo-center" alt="Logo Unigranrio" />
            <div className="col-sm-8 card-dados-candite">
               <div className="row" >
                  <div className="col-lg-12">
                     <div style={{color: '#f00'}}>
                        <b>Inscrição: </b>{this.props.candidato.id}
                        {this.props.candidato.idFormaIngresso === 7 ?
                            <span> - Guarde este número, pois ele será necessário para fazer a prova online</span>
                        : ''}
                    </div>
                     {/* If nessário, pois em alavancas 3 (Transferência) ou
                     (Reingresso), guardamos os dados em locais diferentes */}
                     <b>Nome: </b>
                     <span> 
                     {this.verificaNulo(this.props.candidato.nome)}
                     </span>                     
                     <br /> 
                     <b>Situação: </b>
                     <span> 
                     {this.verificaNulo(this.props.candidato.nomeSituacaoCandidato)}
                     </span>
                     
                     <br />                                     
                     <div>
                        <b>Documento de Identificação: </b> <span>
                        {this.verificaNulo(this.props.candidato.documentoIdentificacao)}
                        </span> <br />
                     </div>
                     
                     <div>
                        <b>Data de Inscrição: </b><span>{this.verificaNulo(this.props.candidato.dataInscricao)}</span>
                        {this.props.candidato.idFormaIngresso === 7 ?
                            <a href={this.props.candidato.idInstituicaoMantida === 1 ? 'https://portal.unigranrio.edu.br/vestibular-online-unigranrio' : 'https://portal.unigranrio.edu.br/vestibular-online-fatenp'} className="fazer-prova" target="_blank">Começar minha prova</a>                            
                        : null}
                     </div>

                     <b>Curso: </b>
                     <span>
                     {this.verificaNulo(this.props.candidato.nomeOfertaProcessoSeletivoCurso)}
                     </span><br />
                     <b>Unidade: </b>
                     <span>
                     {this.verificaNulo(this.props.candidato.nomeOfertaProcessoSeletivoUnidade)}
                     </span><br />
                     <b>Turno: </b><span>{this.props.candidato.nomeOfertaProcessoSeletivoTurno}</span><br />                     
                     {this.props.candidato.idFormaIngresso === 1 ?
                     <div>
                        <b>Local da Prova: </b>
                        <span>
                        {this.verificaNulo(this.props.candidato.nomeLocalProva)}
                        </span>
                        <br />
                    
                    <div>
                    <b>Endereço: </b>
                    <span>
                    <span>
                    {this.verificaNulo(this.props.candidato.localProvaLogradouroEndereco)},&nbsp; 
                    Número: {this.verificaNulo(this.props.candidato.localProvaNumeroEndereco)},&nbsp; 
                    {this.verificaNulo(this.props.candidato.localProvaNomeMunicipio)},&nbsp; 
                    {this.verificaNulo(this.props.candidato.localProvaNomeUf)}
                    </span>
                    </span>
                    </div>
                        <b>Data da Prova: </b>
                        <span>
                            {this.verificaNulo(this.props.candidato.dataProvaAgendada)}
                        </span>
                        <br />
                     </div>
                     :null}
                     
                     
                     <b>Forma de Ingresso: </b>
                     <span>
                     {this.verificaNulo(this.props.candidato.nomeFormaIngresso)}
                     </span>
                     <br />
                     {this.props.candidato.idFormaIngresso === 2 ?
                     <div>
                         <br/>
                        
                        <b>ATENÇÃO: </b>
                        <span>
                            Para dar continuidade ao processo, solicitamos que você responda ao e-mail de confirmação de inscrição, anexando as cópias digitais dos itens abaixo:
                        </span>
                        <ul>
                            <li>Resultado do ENEM/Extrato de notas</li>
                            <li>1 foto 3x4</li>
                            <li>RG</li>
                            <li>CPF</li>
                            <li>Título de eleitor ou Título Eleitoral</li>
                            <li>Certidão de nascimento ou casamento</li>
                            <li>Comprovante de residência</li>
                            <li>Certificado e histórico do Ensino médio</li>
                            <li>Atestado de reservista (sexo masculino)</li>
                        </ul>
                     </div> 
                     :null}
                     {this.props.candidato.idFormaIngresso === 3 || this.props.candidato.idFormaIngresso === 4 ?
                     <div>
                         <br/>
                        
                        <b>ATENÇÃO: </b>
                        <span>
                        Para dar continuidade ao processo, solicitamos que você envie seus documentos e disciplinas já cursadas. Segue abaixo a lista de itens:
                        </span>
                        <ul>
                            {this.props.candidato.idFormaIngresso === 3 ?
                                <li>Declaração de matrícula e histórico da instituição de ensino superior de origem</li> 
                            :
                                <li>Diploma e histórico da instituição de ensino superior de origem</li>
                            }
                            <li>Declaração de situação junto ao ENADE</li>
                            <li>Programa das disciplinas</li>
                            <li>1 foto 3x4</li>
                            <li>RG</li>
                            <li>CPF</li>
                            <li>Título de eleitor ou Título Eleitoral</li>
                            <li>Certidão de nascimento ou casamento</li>
                            <li>Comprovante de residência</li>
                            <li>Certificado e histórico do Ensino médio</li>
                            <li>Atestado de reservista (sexo masculino)</li>
                        </ul>
                     </div> 
                     :null}
                     {this.props.candidato.idFormaIngresso === 6 ?
                     <div>
                         <br/>
                        
                        <b>ATENÇÃO: </b>
                        <span>
                            Para dar continuidade ao processo, solicitamos que você responda ao e-mail de confirmação de inscrição, anexando as cópias digitais dos itens abaixo:
                        </span>
                        <ul>
                            <li>1 foto 3x4</li>
                            <li>RG</li>
                            <li>CPF</li>
                            <li>Título de eleitor ou Título Eleitoral</li>
                            <li>Certidão de nascimento ou casamento</li>
                            <li>Comprovante de residência</li>
                            <li>Certificado e histórico do Ensino médio</li>
                            <li>Atestado de reservista (sexo masculino)</li>
                        </ul>
                     </div> 
                     :null}
                     {this.state.possuiFies ?
                     <div>
                         <br/>
                        
                     <b>ATENÇÃO: </b>
                     <span>
                         Para concluir sua Transferência com Fies, você deve se apresentar na Unidade junto com os seguintes documentos. <a target="_blank" href="https://cdn2.hubspot.net/hubfs/3960387/PROCESSO%20SELETIVO/Formul%C3%A1rio%20de%20Transfer%C3%AAncia%20Externa%20FIES.pdf">  Clique aqui para visualizar os documentos</a>.
                     </span>
                     </div> 
                     :null}
                     {/*<div style={styles.singaturePlace}>
                        <div style={styles.signatureLine}>
                        </div>
                        <b>Assinatura do Candidato</b>
                     </div>*/}
                  </div>
               </div >
            </div>
           
            {
                        this.props.candidato.idInstituicaoMantida === 1
                        ?
                            <small className="end">Portaria MEC n.º 940/94 de 16 de junho de 1994<br />
                        Rua Professor José de Souza Herdy, 1160 - 25 de Agosto, Duque de Caxias - RJ - Brasil<br />
                        CEP: 25073200 Telefone: (21) 2672-7813 www.unigranrio.br</small>
                        :
                            <small className="end">Portaria MEC n. 996 de 19 de junho de 2011.<br />
                         Avenida Vidal Procópio Lohn, 1081, Loteamento Nova Palhoça, Palhoça - SC.<br />
                        CEP: 88.131-551 Telefone: (48) 3242-1001 www.fatenp.com.br</small>
            }
         </div>
        )
    }
}

const styles = {
    singaturePlace: {
        marginTop: '40px',
        marginBottom:'5px',        
        textAlign: 'center'
    },
    signatureLine:{
        border: '0',
        borderBottom: '1px solid #000',
        height: '10px',
        marginBottom: '10px'
    }
}
