
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const ofertaProcessoSeletivoService = {};

ofertaProcessoSeletivoService.findByEntity =  function(dadosOfertaProcessoSeletivo){
    return api.postBaseURL('/oferta-processo-seletivo/search', dadosOfertaProcessoSeletivo,apiBaseProcessoSeletivo)
            .then((opcoesTiposProva) => {                
            return opcoesTiposProva;               
    });       
}

export default ofertaProcessoSeletivoService;