import React, { Component } from 'react'
export default class PreviewDadosEnem extends Component {
  
  constructor(props) {
    super(props);
  }
    render() {
        {
          if(this.props.show){
            return (            
              <div id="dados-enem" className={`tab-pane fade ${this.props.discoverIsActiveContent('dados-enem')}`}>                
                  <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="control-label" htmlFor="nrInscricaoEnem">Código do ENEM</label>
                            <input className="form-control required"  id="nrInscricaoEnem"
                             name="nrInscricaoEnem" type="text" value={this.props.lead.nrInscricaoEnem}  readOnly/>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="control-label" htmlFor="nrAnoEnem">Ano ENEM</label>
                            <input className="form-control required"  id="nrAnoEnem" 
                            name="nrAnoEnem" type="text" value={this.props.lead.nrAnoEnem} readOnly/>
                          </div>
                        </div>                                  
                      </div>
                    </div>
            )
        }else{
          return null;
        }
      }
        
    }

    
}
