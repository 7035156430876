
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const documentoCandidatoService = {};

documentoCandidatoService.salvarDocumentos =  function(formData){
    return api.postBaseURL('/documentos-candidato/salvar-documentos', formData, apiBaseProcessoSeletivo)
          .then((paramsProcedure) => {            
            return paramsProcedure
          })
}

documentoCandidatoService.findByEntity =  function(dadosDocumentosCandidato){
  return api.postBaseURL('/documentos-candidato/find', dadosDocumentosCandidato, apiBaseProcessoSeletivo)
        .then((documentosCandiato) => {            
          return documentosCandiato
        })
}


export default documentoCandidatoService;