import React from 'react';
import ReactDOM from 'react-dom';
//import {Provider} from 'react-redux';
//import {BrowserRouter as Router, Route} from 'react-router-dom';
import { BrowserRouter, Switch, Route,Redirect } from 'react-router-dom';
import './styles/style.css';
import './styles/bootstrap-grid.css';
import './styles/hotsite.css';
//import store from './store';
import CursoList from './pages/curso/CursoList';
import Informacoes from './pages/informacoes/Informacoes';
import Home from './pages/public/Home';
import Graduacao from './pages/curso/Graduacao';
import 'magic.css/dist/magic.min.css'
import Recrutador from './pages/externo/Recrutador';
import FormBuscaInscricaoCandidato from './pages/curso/FormBuscaInscricaoCandidato';
import CursoListByUnidade from './pages/curso/CursoListByUnidade';
import api from '../src/utils/api'
import InternalError from './pages/pages-errors/InternalError';


const App = () => (
  <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/fatenp" component={Home} />
        <Route exact path="/fatenp/curso/:idAlavanca" component={CursoList} />
        <Route exact path="/curso/:idAlavanca" component={CursoList} />
        <Route
          exact
          path="/unidade/:parametroPesquisa"
          component={CursoListByUnidade}
        />
        <Route exact path="/fatenp/:idCurso" component={Graduacao} />
        <Route
          exact
          path="/fatenp/:idAlavanca/:idCurso"
          component={Graduacao}
        />
        
        <Route
          exact
          path="/fatenp/:idAlavanca/:idCurso/:idUnidade"
          component={Graduacao}
        />
        <Route
          exact
          path="/curso/:idAlavanca/:idCurso/:idUnidade"
          component={Graduacao}
        />
        <Route
          exact
          path="/curso/:idAlavanca/:idUnidade"
          component={CursoList}
        />
        <Route
          exact
          path="/recrutador/:idAcaoComercialCaptador"
          component={Recrutador}
        />
        <Route exact path="/informacoes" component={Informacoes} />
        <Route
          exact
          path="/candidato-fatenp"
          component={FormBuscaInscricaoCandidato}
        />
        <Route
          exact
          path="/candidato-fatenp/:idCandidato/:dataNascimento/:cpf"
          component={FormBuscaInscricaoCandidato}
        />
        <Route
          exact
          path="/candidato"
          component={FormBuscaInscricaoCandidato}
        />
        <Route
          exact
          path="/candidato/:idCandidato/:dataNascimento/:cpf"
          component={FormBuscaInscricaoCandidato}
        />
        <Redirect from="/curso/0" to="/curso/6" />
        <Redirect from="*" to="/" />
      </Switch>
  </BrowserRouter>
);
ReactDOM.render(<App />, document.getElementById('root')); 
// api.authenticar().then(()=>{ 
//     ReactDOM.render(<App />, document.getElementById('root'));      
// }).catch(()=>{    
//     ReactDOM.render(<InternalError />, document.getElementById('root'));      
// })
//registerServiceWorker();