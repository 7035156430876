import api from "../../utils/api";
import { api as rmAPI } from "./../rm/index";

import { apiBaseComercial } from "../../config";
import { removeZerosLeftsNumberAsString } from "../../utils/string";
import { checkIsNotApiProd } from "../../utils/url";

const contatoHubspotService = {};

const enumFormaIngressoMappedInHubspot = {
  1: "AGENDADO",
  2: "ENEM",
  3: "TRANSFERÊNCIA EXTERNA",
  4: "REINGRESSO",
};

contatoHubspotService.createOrUpdate = async function (properties, email) {
  if (checkIsNotApiProd()) {
    return {};
  }
  // return api
  //   .postBaseURL(`hubspot/contato/create-or-update/${email}`
  //   , {properties}
  //   , apiBaseComercial)
  //   .then(statusRequest => {
  //     return statusRequest
  // })
  const { data, status } = await rmAPI.post(`/hubspot/contact/createOrUpdate`, {
    ...properties,
  });
  return status
};

contatoHubspotService.addProperty = function (property, value, properties) {
  if (value) {
    properties.push({
      property,
      value,
    });
  }
};

contatoHubspotService.montarObjectInformacoesOferta = function (
  propsComponent
) {
  let properties = [];
  const { addProperty } = contatoHubspotService;
  addProperty("tipo_de_curso", "Graduação", properties);
  addProperty(
    "modalidade_da_gradua_o",
    propsComponent.modalidade &&
      propsComponent.modalidade.modalidadeEnsino &&
      propsComponent.modalidade.modalidadeEnsino.toUpperCase(),
    properties
  );
  addProperty("curso_recrutador", propsComponent.curso.id, properties);
  addProperty("campus", propsComponent.unidade.id, properties);
  addProperty(
    "forma_de_ingresso",
    enumFormaIngressoMappedInHubspot[propsComponent.idAlavanca],
    properties
  );
  return properties;
};

contatoHubspotService.montarObjectInformacoesPessoais = function (
  propsComponent
) {
  let properties = [];
  const { addProperty } = contatoHubspotService;
  addProperty("firstname", propsComponent.lead.primeiroNome, properties);
  addProperty("lastname", propsComponent.lead.sobrenome, properties);
  addProperty("email", propsComponent.lead.email, properties);
  addProperty("phone", propsComponent.lead.telefone, properties);
  addProperty("cpf", propsComponent.lead.cpf, properties);
  addProperty(
    "data_de_nascimento",
    new Date(
      removeZerosLeftsNumberAsString(propsComponent.lead.dataNascimento)
    ).setUTCHours("00"),
    properties
  );
  return properties;
};

contatoHubspotService.montarObjectInformacoesEndereco = function (
  propsComponent
) {
  let properties = [];
  const { addProperty } = contatoHubspotService;
  addProperty("address", propsComponent.endereco.logradouro, properties);
  addProperty(
    "numero_casa_endereco",
    propsComponent.endereco.numero,
    properties
  );
  addProperty("nm_bairro", propsComponent.endereco.bairro, properties);
  addProperty(
    "complemento_endereco",
    propsComponent.endereco.complemento,
    properties
  );
  addProperty("state", propsComponent.endereco.nomeEstado, properties);
  addProperty("city", propsComponent.endereco.nomeMunicipio, properties);
  return properties;
};

contatoHubspotService.montarObjectMsql = function () {
  let properties = [];
  const { addProperty } = contatoHubspotService;
  addProperty("lifecyclestage", "marketingqualifiedlead", properties);
  addProperty("funil_de_captacao", "MQL", properties);
  return properties;
};

contatoHubspotService.montarObjectSql = function () {
  let properties = [];
  const { addProperty } = contatoHubspotService;
  addProperty("lifecyclestage", "salesqualifiedlead", properties);
  addProperty("funil_de_captacao", "SQL", properties);
  return properties;
};

contatoHubspotService.enviarContatoHubspot = function (
  propsComponent,
  emailLead
) {
  let properties;
  if (propsComponent.enviouInformacoesOfertaToHubspot) {
    properties = [
      ...contatoHubspotService.montarObjectSql(),
      ...contatoHubspotService.montarObjectInformacoesPessoais(propsComponent),
      ...contatoHubspotService.montarObjectInformacoesEndereco(propsComponent),
    ];
  } else {
    properties = [
      ...contatoHubspotService.montarObjectSql(),
      ...contatoHubspotService.montarObjectInformacoesOferta(propsComponent),
      ...contatoHubspotService.montarObjectInformacoesPessoais(propsComponent),
      ...contatoHubspotService.montarObjectInformacoesEndereco(propsComponent),
    ];
  }
  return contatoHubspotService.createOrUpdate(properties, emailLead);
};

export default contatoHubspotService;
