
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const periodoLetivoService = {};

periodoLetivoService.findByEntity =  function(dadosPeriodoLetivo){
    return api.postBaseURL('/periodos-letivo/find', dadosPeriodoLetivo,apiBaseProcessoSeletivo)
            .then((periodosLetivo) => {                
            return periodosLetivo;               
    })        
}

export default periodoLetivoService;