import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Carregando from '../utils/Carregando';
import tipoDocumentoFormaIngressoService from '../../services/processo-seletivo/tipo-documento-forma-ingresso'
import documentoCandidatoService from '../../services/processo-seletivo/documento-candidato-service'
import {FaHandPointRight} from 'react-icons/fa'
import { confirmAlert } from 'react-confirm-alert';
import candidatoService from '../../services/processo-seletivo/candidato-service';
export default class FormArquivosPendentes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tiposDocumentosFormasIngressos: [],
      documentos: [],
      quantidadeDocumentosProcessados: 0,
      isCarregandoTipoDocumentos: true,
      candidato: '',
      isProcessandoDocumentos: false,
      isCarregandoValidacao: false,
      loadingMessage: 'Processando documentos',
      documentosEnviados: false,

      checkboxAceiteTermos: false,

      checkBoxCienteDisciplinas: false,
      checkBoxDocumentosVerdadeiros: false,
      instanceTimeoutAnimation:'',
      maxSizeDocument:3000000,
      timeAnimation:8000
    }
    this.obterTiposDocumentosFormasIngressos = this.obterTiposDocumentosFormasIngressos.bind(this);
    this.handlePresSendDocumentos = this.handlePresSendDocumentos.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onCloseToastCheckBoxDisciplina = this.onCloseToastCheckBoxDisciplina.bind(this)
    this.onCloseToastFileOutMaxSize = this.onCloseToastFileOutMaxSize.bind(this)
    this.openSiteInscricaoAluno = this.openSiteInscricaoAluno.bind(this);
  }

  componentDidMount() {    
    this.obterTiposDocumentosFormasIngressos()
  }

  openToast(message,onClose) {
    if (toast.isActive(this.state.toastId)) {
      return;
    }
    this.setState({
      toastId: toast.warn(message, {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => {if(onClose){ return onClose()}}        
      })
    });
  }

  obterTiposDocumentosFormasIngressos() {
    const criterioPesquisaDocumento = {
      idTipoDocumento: 0,
      idFormaIngresso: this.props.candidato.idFormaIngresso,
      obrigatorioInscricao: true
    }

    tipoDocumentoFormaIngressoService.search(criterioPesquisaDocumento)
      .then(tiposDocumentosFormasIngressos => {
        this.setState({ tiposDocumentosFormasIngressos });
        this.setState({ isCarregandoTipoDocumentos: false });
      }).catch(() => {
        this.setState({ isCarregandoTipoDocumentos: false });
      });
  }
 
  openSiteInscricaoAluno() {
    const callback = () => window.location.href = `http://inscricao.${this.props.candidato.idInstituicaoMantida === 1 ? 'unigranrio' : 'fatenp'}.com.br/processoSeletivo/paginas/publicas/vestibular/paginaInformacoesEtapasMatricula.jsf`
    return confirmAlert({
      title: 'Atenção!!!',
      message: 'Deseja realmente fazer isso ?',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            this.props.finalizarInscricao(callback)
          }
        },
        {
          label: 'Não',
        }
      ]
    })
  }

  onCloseToastCheckBoxDisciplina(){    
    if(!this.state.instanceTimeoutAnimation){
      this.setState({isAnmationActivated:true})
      this.setState({instanceTimeoutAnimation:setTimeout(() => {
        this.setState({isAnmationActivated:false,instanceTimeoutAnimation:null})
      }, this.state.timeAnimation)})
    }
  }
  onCloseToastFileOutMaxSize(){    
    if(!this.state.instanceTimeoutAnimation){
      this.state.documentos.forEach((documento,index) => {        
        if(documento.arquivo.size > this.state.maxSizeDocument){          
          this.state.documentos[index].showHandOutMaxSize = true
          this.scrollToInputDocumento(documento.idTipoDocumento)
        }
      });
      this.setState({instanceTimeoutAnimation:setTimeout(() => {
        this.state.documentos.forEach((documento,index) => {
        this.state.documentos[index].showHandOutMaxSize = false          
        });
        this.setState({instanceTimeoutAnimation:null})
      }, this.state.timeAnimation)})
    }
  }

  handlePresSendDocumentos(e) {
    e.preventDefault();
    if(this.state.documentos.length === 0){
      this.setState({passouEtapaEnvioDocumentos:true})
      return this.props.editarProgresso()
    }else if(this.isValidDocuments()){
      this.enviarDocumentos()
    }    
  }
  isValidDocuments(){    
    let isValidDocuments = false
    if (!this.state.checkboxAceiteTermos) {
      this.openToast('Para prosseguir você deve ler a aceitar os termos de serviço',this.onCloseToastCheckBoxDisciplina)
      return isValidDocuments
    }

    // if (!this.state.checkBoxCienteDisciplinas) {
    //   this.openToast('Para prosseguir você deve marcar que está ciente que as disciplinas podem não ser compatíveis com as disciplinas do curso escolhido',this.onCloseToastCheckBoxDisciplina)
    //   return isValidDocuments
    // }
    // if (!this.state.checkBoxDocumentosVerdadeiros) {
    //   this.openToast('Você deve marcar que assegura que os documentos são verdadeiros',this.onCloseToastCheckBoxDisciplina)
    //   return isValidDocuments
    // }
    
    for (const key in this.state.documentos) {
       if (this.state.documentos[key].arquivo.name.substr(this.state.documentos[key].arquivo.name.length - 4) !== '.pdf' &&
         this.state.documentos[key].arquivo.name.substr(this.state.documentos[key].arquivo.name.length - 4) !== '.png' &&
         this.state.documentos[key].arquivo.name.substr(this.state.documentos[key].arquivo.name.length - 5) !== '.jpeg' &&
         this.state.documentos[key].arquivo.name.substr(this.state.documentos[key].arquivo.name.length - 4) !== '.jpg' &&
         this.state.documentos[key].arquivo.name.substr(this.state.documentos[key].arquivo.name.length - 4) !== '.zip') {
        isValidDocuments = false
        break;
       }else{
        isValidDocuments = true
       }
  
       if (this.state.documentos[key].arquivo.size > this.state.maxSizeDocument) {
         this.openToast(`Tamanho maior que o limite para o arquivo ${this.state.documentos[key].arquivo.name}. Por favor, o arquivo deve ter menos que 3 MB`,this.onCloseToastFileOutMaxSize)
         isValidDocuments = false
         break;
       }else{
        isValidDocuments = true
       }
  }
  return isValidDocuments
}

  enviarDocumentos(){
    for (const key in this.state.documentos) {    
      this.processandoDocumentos(true)
      const formData = new FormData();      
      
      formData.append('documentos', this.state.documentos[key].arquivo);
      formData.append('idTipoDocumento', this.state.documentos[key].idTipoDocumento);
      formData.append('sequenciaArquivo', this.state.documentos[key].sequencia);       
      formData.append('idCandidato', this.props.candidato.id);
      formData.append('periodoLetivo', this.props.candidato.nomePeriodoLetivo);
      documentoCandidatoService.salvarDocumentos(formData)
        .then(async () => {
          this.setState({ documentosEnviados: true })
          
          if(parseInt(key) === 0){
            // Para ser executado apenas uma vez
            await candidatoService.proximoAndamentoCandidato({id:this.props.candidato.id})
            this.props.carregarAndamentoAtualCandidato(this.props.candidato.id)
          }
          /* Finaliza inscrição, caso candidato não tenha cadastrado a instituição de ensino superior
          na etapa passada */          
          
          if (this.props.candidato.idInstituicaoEnsinoSuperior === null) {
            this.setState({ loadingMessage: 'Finalizando Inscrição' })
            this.finalizarInscricao()
          } else {
            this.processandoDocumentos(false)
          }          
          this.setState({passouEtapaEnvioDocumentos:true})
        }).catch(() => this.setState({ isCarregandoValidacao: false }));
    }    
  }

   scrollToInputDocumento(idTipoDocumento) {    
    window.$([document.documentElement, document.body]).animate({
        scrollTop: window.$(`#${idTipoDocumento}`).offset().top
    }, 2000);
  }
  

  finalizarInscricao = () => {
    return this.props.finalizarInscricao()
  }

  excluirDocumento = (documento) => {            
    const indexToRemove = this.state.documentos.findIndex(el => el.sequencia === documento.sequencia && el.idTipoDocumento === documento.idTipoDocumento)
    this.state.documentos.splice(indexToRemove,1)
    if(!this.state.documentos.find(el => el.idTipoDocumento === documento.idTipoDocumento)){
      return document.getElementById(documento.idTipoDocumento).value = "";      
    }
    let sequencia = 1
    this.state.documentos.forEach((el,index) => {
      if(el.idTipoDocumento === documento.idTipoDocumento){
        this.state.documentos[index].sequencia = sequencia
        sequencia++
      }          
    })
       
  }

  onChange(e, tipoDocumento) {    
    if (e.target.files.length > 0) {
      const idsTipoDocumentosPermitidosMaisDeUmArquivo = [2,13,23]
      for (let i = 0; i < e.target.files.length; i++) {
        if(idsTipoDocumentosPermitidosMaisDeUmArquivo.indexOf(tipoDocumento.idTipoDocumento) === -1 && this.state.documentos.some(documento => documento.idTipoDocumento === tipoDocumento.idTipoDocumento)){
          return
        }
        let tempTipoDocumento = {};
        tempTipoDocumento["idTipoDocumento"] = tipoDocumento.idTipoDocumento;
        tempTipoDocumento["arquivo"] = e.target.files[i];
        tempTipoDocumento["sequencia"] = this.state.documentos.filter(el => el.idTipoDocumento === tipoDocumento.idTipoDocumento).length + 1

        const documentos = this.state.documentos
        documentos.push(tempTipoDocumento);
        this.setState({ documentos: documentos })
        this.forceUpdate()
      }
    }
  }

  onChangeCheckBox = (e,typeCheckbox) => {
    this.setState({ [typeCheckbox]: e.target.checked });
  } 

  processandoDocumentos(boolean) {
    this.setState({ isProcessandoDocumentos: boolean })
  }

  getTiposAceitos(nomeTipoDocumento) {
    if (nomeTipoDocumento.includes('3x4')) {
      return 'image/png, image/jpeg, image/jpg';
    } else if (nomeTipoDocumento.includes('disciplinas')) {
      return 'application/pdf, application/zip';
    }
    return 'application/pdf';
  }

  render() {
    return (
      <div>

        {this.state.isProcessandoDocumentos ? <Carregando message={this.state.loadingMessage} /> :
          <div>
            <ToastContainer  autoClose={false}/>            
            { !this.state.passouEtapaEnvioDocumentos ?
                <div>
                  <h2 className="h3 text-blue mb-2">Documentações Pendentes</h2>
                  {/*<p className="subtitle-doc mb-2" style={{fontSize: '16px', fontWeight: '500', color: '#08427e'}}>Caso você não consiga enviar os documentos agora, você pode levar os documentos da lista na Unidade/Polo escolhido.</p>*/}
                  <span>
                    {this.props.candidato.nomeSituacaoCandidato !== 'Inscrito' ? null : <button onClick={this.openSiteInscricaoAluno} className="pre-matricula mb-4">Desejo entregar meus documentos em mãos e fazer a Pré Matrícula</button>}
                  </span>
               </div>
            : null}
            {this.state.documentosEnviados ? null :
              <form onSubmit={this.handlePresSendDocumentos} encType="multipart/form-data">
                <div className="wrapper">
                  {this.state.isCarregandoTipoDocumentos || this.state.isCarregandoValidacao ? <Carregando /> :
                    <div className="row">
                      {this.state.tiposDocumentosFormasIngressos.map((tipoDocumento, i) => (
                        <div key={i} className="col-sm-6" id="pd-bottom-file">
                          <div className="form-group">
                            <label className="label_input_file">                            
                            {tipoDocumento.nomeTipoDocumento}                            
                            </label>
                            <input id="documento" name="documento"
                              className="input_file" type="file"                              
                              placeholder="Selecione um arquivo"
                              id={tipoDocumento.idTipoDocumento}
                              onChange={(e) => this.onChange(e, tipoDocumento, i)}
                              accept={this.getTiposAceitos(tipoDocumento.nomeTipoDocumento)}                              
                              multiple />
                            <div id="selectedFiles">
                              {this.state.documentos.map((documento, i) => documento.idTipoDocumento === tipoDocumento.idTipoDocumento ?
                                <span key={i}>                                
                                  <p>{documento.showHandOutMaxSize ? <FaHandPointRight className="icon-hand-point-right"/>:''} {documento.arquivo.name}
                                    <a style={{color:'red'}}  href="#" onClick={(e) => this.excluirDocumento(documento)}>   X</a>
                                  </p>
                                </span> : null)}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  }
                  <p className="subtitle-doc mb-4">Os arquivos devem ser enviados no formato PDF obrigatoriamente, com exceção da foto que deve ser enviada no formato PNG, JPEG, JPG e dos programas das disciplinas cursadas, que podem ser em ZIP.</p>
                </div>
                <br />
                {this.state.documentos.length > 0 &&  <div >
                  <label>
                  <ul style={{borderStyle:'inset', backgroundColor:'lightyellow'}}>
                      <li>
                        Estou ciente de que em caso de não anexar o Histórico Escolar de Graduação, o substituindo pelo Extrato Acadêmico, deve apresentar meu Histórico em até 30 dias corridos, contados a partir desta data.
                      </li>  
                      <li>
                        Estou ciente que as disciplinas cursadas podem não ser compatíveis com as disciplinas do curso escolhido e portanto não necessariamente todas serão dispensadas.                   
                      </li>
                      <li>
                        Estou ciente que devo apresentar os originais dos documentos aqui anexados no ato da matrícula e que qualquer irregularidade irá cancelar o processo de isenção (concluído ou não).
                      </li>
                      <li>
                        Asseguro que estes documentos são verdadeiros.
                      </li>
                    </ul>  

                  {!this.state.checkboxAceiteTermos && this.state.isAnmationActivated ? <FaHandPointRight className="icon-hand-point-right"/> : ''} <input onChange={(e) => this.onChangeCheckBox(e,'checkboxAceiteTermos')} type="checkbox" name="checkboxDisciplina" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                  Li e Aceito os termos de serviço.
                    

                   {/* {!this.state.checkBoxCienteDisciplinas && this.state.isAnmationActivated ? <FaHandPointRight className="icon-hand-point-right"/> : ''} <input onChange={(e) => this.onChangeCheckBox(e,'checkBoxCienteDisciplinas')} type="checkbox" name="checkboxDisciplina" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                    Estou ciente que as disciplinas cursadas podem não ser compatíveis com as disciplinas do curso escolhido e portanto não necessariamente todas serão dispensadas.                    */}
                  </label>
                  {/* <label>
                  {!this.state.checkBoxDocumentosVerdadeiros && this.state.isAnmationActivated ? <FaHandPointRight className="icon-hand-point-right"/>:''} <input onChange={(e) => this.onChangeCheckBox(e,'checkBoxDocumentosVerdadeiros')} type="checkbox" name="checkboxDisciplina" style={{ width: '15px', height: '15px', marginRight: '5px' }} />
                    Asseguro que estes documentos são verdadeiros.
                  </label> */}
                </div> }              
                <div className="container">
                  <button id="handlePresSendDocumentos" name="handlePresSendDocumentos" style={{ margin: '0 auto', display: 'block' }} className="btn btn-primary bt-confirmar" type="submit" >
                    {this.state.documentos.length > 0 ? 'Enviar Documentos':'Pular Etapa'}
                  </button>
                </div>
              </form>
            }

            <br />

            {!this.state.documentosEnviados ? null :
              <div>
                <div id="confirmar-cancelar" className="container">
                  <h6>Documentos enviados com sucesso! </h6> <p>Gostaria de adiantar o seu processo de análise da dispensa de disciplinas informando as disciplinas que você foi aprovado em outra(s) instituição(ões)?</p>
                  <button id="btFinalizaInscricao" name="finalizarInscricao" className="btn btn-primary bt-confirmar" onClick={this.finalizarInscricao} disabled={!this.state.checkboxAceiteTermos} type="button">Não, 
                   finalizar minha inscrição agora</button>
                  <button style={{backgroundColor:'#ff7122',border:'#ff7122'}} id="btConfirmaInscricao" name="avancarPasso" className="btn btn-primary bt-confirmar" onClick={this.props.editarProgresso} disabled={!this.state.checkboxAceiteTermos}>Sim, desejo adiantar meu processo de dispensas</button>
                </div>
              </div>
            }
          </div>}

      </div>
    )
  }
}
