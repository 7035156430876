import React, { Component } from 'react'
import logo from "../../images/hotsite-img/logo-unigranrio2.png";
import logofatenp from "../../images/hotsite-img/logo-fatenp.png";
import moment from 'moment';

export default class DadosCandidato extends Component {
    render() {
        return (
            <div>
            <img src={this.props.idInstituicaoMantida === 1 ? logo : logofatenp} className="logo-center" alt="Logo" />
            <div className="col-sm-8 card-dados-candite">
               <div className="row" >
                  <div className="col-lg-12">
                    <div style={{color: '#f00'}}>
                        <b>Inscrição: </b>{this.props.idCandidato}
                        {this.props.idAlavanca === 16 ?
                            <span> - Guarde este número, pois ele será necessário para fazer a prova online</span>
                        : ''}
                    </div>                  
                     <b>Nome: </b><span>
                     {this.props.lead.primeiroNome + ' ' + this.props.lead.sobrenome}
                     </span><br />
                     {
                        this.props.lead.cpf
                        ?
                        <div>
                            <b>CPF: </b> <span>
                            {this.props.lead.cpf.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4")}
                            </span> <br />
                        </div>
                        :
                        null
                     }
                     <div>
                        <b>Data de Inscrição: </b><span>{moment(new Date()).format("DD/MM/YYYY")}</span>
                        {this.props.idAlavanca === 16 ?
                            <a href={this.props.idInstituicaoMantida === 1 ? 'https://portal.unigranrio.edu.br/vestibular-online-unigranrio' : 'https://portal.unigranrio.edu.br/vestibular-online-fatenp'} className="fazer-prova" target="_blank">Começar minha prova</a>                            
                        : null}
                     </div>
                     <b>Origem: </b><span>Internet</span><br />
                     <b>Curso: </b><span>{this.props.curso.nome}</span><br />
                     <b>Campus: </b><span>{this.props.unidade.nome}</span><br />
                     <b>Turno: </b><span>{this.props.turno ? this.props.turno.nome : ''}</span><br />
                     {
                     this.props.localProva.nome
                     ?
                     <div>
                        <b>Local da Prova: </b><span>{this.props.localProva ? this.props.localProva.nome : ''}</span><br />
                    {this.props.localProva.logradouroEndereco ?
                    <div>
                    <b>Endereço: </b>
                    <span>
                    {this.props.localProva.logradouroEndereco},&nbsp; 
                    Número: {this.props.localProva.numeroComplementoEndereco},&nbsp;
                    {this.props.localProva.nomeMunicipio},&nbsp; 
                    {this.props.localProva.nomeUf}
                    </span>
                    </div>
                    :null
                    }
                        <b>Data da Prova: </b><span>{this.props.dataProva} - {this.props.horarioProva ? this.props.horarioProva.agendamento : ''}</span><br />
                     </div>
                     :
                     null
                     }
                     <b>Forma de Ingresso: </b><span>{this.props.opcaoTipoProva.nomeFormaIngresso}</span><br />
                     {this.props.idAlavanca === 2 ?
                        <div>
                            <br/>
                            <b>ATENÇÃO: </b>
                            <span>
                                Para dar continuidade ao processo, solicitamos que você responda ao e-mail de confirmação de inscrição, anexando as cópias digitais dos itens abaixo:
                            </span>
                            <ul>
                                <li>Resultado do ENEM/Extrato de notas</li>
                                <li>1 foto 3x4</li>
                                <li>RG</li>
                                <li>CPF</li>
                                <li>Título de eleitor ou Título Eleitoral</li>
                                <li>Certidão de nascimento ou casamento</li>
                                <li>Comprovante de residência</li>
                                <li>Certificado e histórico do Ensino médio</li>
                                <li>Atestado de reservista (sexo masculino)</li>
                            </ul>
                        </div> 
                     :null}
                     {this.props.idAlavanca === 3 || this.props.idAlavanca === 4 ? 
                            <div>
                            <br/>
                            
                        <b>ATENÇÃO: </b>

                        <span>
                            Para concluir sua inscrição, você precisa avançar para a segunda etapa da inscrição, onde fará o envio de documentos e poderá informar as disciplinas aprovadas em outras instituições. Clique no botão no final da página para continuar. 
                            </span>
                        </div> :null}
                     {/*<div style={styles.singaturePlace}>
                        <div style={styles.signatureLine}>
                        </div>
                        <b>Assinatura do Candidato</b>
                     </div>*/}
                  </div>
               </div >
            </div>
            {
                        this.props.idInstituicaoMantida === 1
                        ?
                            <small className="end">Portaria MEC n.º 940/94 de 16 de junho de 1994<br />
                        Rua Professor José de Souza Herdy, 1160 - 25 de Agosto, Duque de Caxias - RJ - Brasil<br />
                        CEP: 25073200 Telefone: (21) 2672-7813 www.unigranrio.br</small>
                        :
                            <small className="end">Portaria MEC n. 996 de 19 de junho de 2011.<br />
                         Avenida Vidal Procópio Lohn, 1081, Loteamento Nova Palhoça, Palhoça - SC.<br />
                        CEP: 88.131-551 Telefone: (48) 3242-1001 www.fatenp.com.br</small>
            }
         </div>
        )
    }
}

const styles = {
    singaturePlace: {
        marginTop: '40px',
        marginBottom:'5px',        
        textAlign: 'center'
    },
    signatureLine:{
        border: '0',
        borderBottom: '1px solid #000',
        height: '10px',
        marginBottom: '10px'
    }
}

