export function getParam(str, variable) {
  const query = str.split('?')[1];
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
      }
  }  
}
export function removerMascaras(string) {
    return string.replace(/[^\d]+/g,'');        
}      

export function copyToClipBoard(string) {
    const el = document.createElement('textarea');
    el.value = string;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);       
}    


export function getBaseUrl() {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port:'' }`
}      
export function removeEspecialCharacters (especialChar){
    especialChar = especialChar.replace('/[áàãâä]/ui', 'a');
    especialChar = especialChar.replace('/[éèêë]/ui', 'e');
    especialChar = especialChar.replace('/[íìîï]/ui', 'i');
    especialChar = especialChar.replace('/[óòõôö]/ui', 'o');
    especialChar = especialChar.replace('/[úùûü]/ui', 'u');
    especialChar = especialChar.replace('/[ç]/ui', 'c');
    especialChar = especialChar.replace('/[^a-z0-9]/i', '_');
    especialChar = especialChar.replace('/_+/', '_'); //
    return especialChar;
}

export function mascararCpf (cpf){
    if (!cpf) return '';
    return cpf.replace(/^([\d]{3})([\d]{3})([\d]{3})([\d]{2})$/, "$1.$2.$3-$4")    
}

export function removeZerosLeftsNumberAsString(string){    
    if(!string) return null
    let stringTratada = string
    const matchs = string.match(/(0+\d)/g)
    if(matchs){
        matchs.forEach(match => {
            const regex = new RegExp(match, "g")                
            stringTratada = stringTratada.replace(regex,match.substr(1,1))
        })    
    }
    return stringTratada
}

export function removeSpaceInEndOfString(string){    
    return string.replace(/\s+$/, '')
}

export function isRepeatingChars( str ) {
    return str.split('').every( ( elem ) => elem === str[ 0 ] )
}

export function isAllCharsUnique(str) {
    return new Set(str).size == str.length;
}

export function getNomeMantida(idMantida) {
    switch (idMantida) {
        case 1:
            return 'UNIGRANRIO'

        case 2:
            return 'FATENP'
        default:
            return 'UNIGRANRIO'
    }
}



