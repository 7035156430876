
// import api from '../../utils/api';

// import {apiBaseComercial} from '../../config'
import {  api as apiRM} from './../rm'
const modalidadeEnsinoService = {};

modalidadeEnsinoService.findByEntity = async function(dadosModalidadeEnsino){
    const { data } = await apiRM.get(`modalidades?idAlavanca=6`);
     return data;
   //   return api.postBaseURL('/comercial/modalidade-ensino/list', dadosModalidadeEnsino,apiBaseComercial)
   //   .then((modalidadesEnsino) => {
   //      return modalidadesEnsino;
   //   })
}


export default modalidadeEnsinoService;