// import {apiBoleto} from '../config'
const utilsApiBoleto = {};

utilsApiBoleto.carregarDownloadAPI =  function(){    
     // Source: http://pixelscommander.com/en/javascript/javascript-file-download-ignore-content-type/
     window.downloadFile = function (sUrl) {

        //iOS devices do not support downloading. We have to inform user about this.
        if (/(iP)/g.test(navigator.userAgent)) {
          //alert('Your device does not support files downloading. Please try again in desktop browser.');
          window.open(sUrl, '_blank');
          return false;
        }
  
        //If in Chrome or Safari - download via virtual link click
        if (window.downloadFile.isChrome || window.downloadFile.isSafari) {
          //Creating new link node.
          var link = document.createElement('a');
          link.href = sUrl;
          link.setAttribute('target', '_blank');
  
          if (link.download !== undefined) {
            //Set HTML5 download attribute. This will prevent file from opening if supported.
            var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
            link.download = fileName;
          }
  
          //Dispatching click event.
          if (document.createEvent) {
            var e = document.createEvent('MouseEvents');
            e.initEvent('click', true, true);
            link.dispatchEvent(e);
            return true;
          }
        }
  
        // Force file download (whether supported by server).
        if (sUrl.indexOf('?') === -1) {
          sUrl += '?download';
        }
  
        window.open(sUrl, '_blank');
        return true;
      }
  
      window.downloadFile.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
      window.downloadFile.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
}

    utilsApiBoleto.openPageBoleto =  function(idRegistroTituloBoleto){ 
        //window.frames["iframe-boleto"].focus();
        //window.frames["iframe-boleto"].print();     
    var myWindow = window.open('', '', 'fullscreen=yes');
        myWindow.document.write('<iframe id="iframe-boleto" allowfullscreen style="border: 0; width: 800; height: 1000; overflow: hidden" name="iframe-boleto" src="' + "" + idRegistroTituloBoleto + '" frameBorder="0"></iframe>');
        setTimeout(() => {
          myWindow.document.close();
          myWindow.focus();
          myWindow.print();
          myWindow.close();
          return;
        }, 4000);        
}

utilsApiBoleto.downloadFile = function(idRegistroTituloBoleto){
  window.downloadFile("" + idRegistroTituloBoleto);
}



export default utilsApiBoleto;