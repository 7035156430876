// import api from '../../utils/api';
import { api as apiRm } from './../rm';
// import { apiBaseComercial } from '../../config';

const ofertaService = {};
ofertaService.findCourses = async function (dadosOfertaCurso) {
  //  const url = `/courses?idInstituicaoMantida=20&idAlavanca=${
  //    dadosOfertaCurso.idAlavanca
  //  }&idUnidade=1&idModalidadeEnsino=1&idCurso=1&nomeCurso=${
  //    dadosOfertaCurso.nomeCurso === '' ? '%' : dadosOfertaCurso.nomeCurso
  //  };`;

  // console.log(dadosOfertaCurso);
  const { data } = await apiRm.get(
    `courses?idInstituicaoMantida=${
      dadosOfertaCurso.idInstituicaoMantida
        ? dadosOfertaCurso.idInstituicaoMantida
        : 1
    }
       &idAlavanca=${
         dadosOfertaCurso.idAlavanca ? dadosOfertaCurso.idAlavanca : 6
       }
       &idUnidade=${
         dadosOfertaCurso.idUnidade
           ? dadosOfertaCurso.idUnidade
           : dadosOfertaCurso.idUnidadeEnsino
           ? dadosOfertaCurso.idUnidadeEnsino
           : 0
       }
       &idModalidadeEnsino=${
         dadosOfertaCurso.idModalidadeEnsino
           ? dadosOfertaCurso.idModalidadeEnsino
           : 0
       }
       &idCurso=${dadosOfertaCurso.idCurso ? dadosOfertaCurso.idCurso : 0}&nomeCurso=%25`
  );
  // console.log(data);
  return data;
};

ofertaService.findTurnosPorCurso = async function (dadosOfertaCurso) {
  const {data} = await apiRm.get(
    `modalidades/curso/turnos?idAlavanca=${dadosOfertaCurso.idAlavanca}&idCurso=${dadosOfertaCurso.idCurso}&idUnidadeEnsino=${dadosOfertaCurso.idUnidadeEnsino}&idModalidade=${dadosOfertaCurso.idModalidadeEnsino}`
  );
  return data;
};
ofertaService.findModalidadesPorCurso = async function (dadosOfertaCurso) {
  const { data } = await apiRm.get(
    `modalidades/curso?idAlavanca=${dadosOfertaCurso.idAlavanca}&idCurso=${dadosOfertaCurso.idCurso}&idUnidadeEnsino=${dadosOfertaCurso.idUnidadeEnsino}`
  );

  return data;
};
// ofertaService.findCursoInOfertaByEntity = async function (dadosOfertaCurso) {
//   return api
//     .postBaseURL('/comercial/oferta/cursos', dadosOfertaCurso, apiBaseComercial)
//     .then((cursos) => {
//       return cursos;
//     })
//     .catch((err) => {
//       if (err.message === 'Sem token' || err.status === 401) {
//         api.authenticar().then(() => {
//           return ofertaService.findCursoInOferta(dadosOfertaCurso);
//         });
//       }
//     });
// };

// ofertaService.findModalidadeInOfertaByEntity = function (
//   dadosOfertaModalidade
// ) {
//   // console.log('dadosOfertaModalidade', dadosOfertaModalidade);
//   return api
//     .postBaseURL(
//       '/comercial/oferta/modalidade',
//       dadosOfertaModalidade,
//       apiBaseComercial
//     )
//     .then((modalidades) => {
//       return modalidades;
//     });
// };

export default ofertaService;
