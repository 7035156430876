import React, { Component } from 'react'
import AsyncSelect from 'react-select/lib/Async'
import { removerMascaras } from '../../utils/string';
import contatoService from '../../services/comercial/contato-service';
import candidatoService from '../../services/processo-seletivo/candidato-service';
import ufService from '../../services/estrutura-global/uf-service';
import instituicaoEnsinoService from '../../services/estrutura-global/instituicao-ensino-service'
import { noOptionsMessage } from '../../utils/select';
import localforage from 'localforage';
import curriculoService from '../../services/acdemico/curriculo-service';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { codigoCursos } from '../../config';
import { tipoErro } from '../utils/Warnings';



export default class FormInfoEscolaridade extends Component {
    constructor(props) {
        super(props)

        this.state = {
            anoAtual: (new Date()).getFullYear(), // Atributo para pegar o ano atual para fazer algumas validações
            ufs: [],
            isCursoMedicina: this.props.curso.codigo === codigoCursos.medicina,
            nomeBuscaInsituicao: '',
            isReingresso: this.props.idAlavanca === 4 ? true : false,
            instituicoesEnsinoSuperiorOculta: false,
            isEnviandoDados: false,
            maxFaseCurricular: 12,
            faseCurricularMaiorQueMaxCurriculo: false,
            candidatoJaPossuiProcessoIsencaoMesmoCurso:false
        }
        this.obterInsituicoesEnsino = this.obterInsituicoesEnsino.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeFaseCurricular = this.onChangeFaseCurricular.bind(this)
        this.abrirPreviewDadosCandidatoModal = this.abrirPreviewDadosCandidatoModal.bind(this)        
        this.enviandoDados = this.enviandoDados.bind(this)
        this.enviarCanditado = this.enviarCanditado.bind(this)
    }

    async componentDidMount() {
        this.obterUfs()
        const candidatoJaPossuiProcessoIsencaoMesmoCurso = await candidatoService.verificaCandidatoPossuiProcessoIsencaoMesmoCurso(this.props.lead.idLead,this.props.oferta.id, this.props.curso.codigo)
        if(candidatoJaPossuiProcessoIsencaoMesmoCurso){
            this.props.openWarningModal('Você Já possui uma inscrição nesse curso com um Processo de Isenção em Disciplinas em andamento, portanto, não poderá solicitar a isenção de disciplinas novamente.', tipoErro.alerta)
            this.setState({candidatoJaPossuiProcessoIsencaoMesmoCurso})
            this.props.definirEstadoComponentePai('lead', {...this.props.lead, desejaIsencaoDisciplinas:false})
        }
    }

    finalizarInscricaoParaAlgunsCasos(returnedParamsProcedure) {
        if (JSON.parse(this.props.lead.desejaIsencaoDisciplinas) === false) {
            candidatoService
                .apenasFinalizarInscricao(
                    {
                        id: returnedParamsProcedure.idCandidato,
                        idProcessoSeletivo: this.props.oferta.idProcessoSeletivo
                    })
                .then(() => {
                    localforage.setItem('idCandidatoDesejaIsentarDisciplinas', returnedParamsProcedure.idCandidato)
                        .then(() => {
                            this.props.history.push(`/candidato/${returnedParamsProcedure.idCandidato}/${this.props.lead.dataNascimento}/${removerMascaras(this.props.lead.cpf)}`);
                        })
                })
        } else {
            this.iniciarPassosFinais(returnedParamsProcedure)
            localforage.removeItem('idCandidatoDesejaIsentarDisciplinas')
        }

    }
    onChange(e, tipoIdInstituicao, tipoNomeInstituicao) {
        if (!e && tipoIdInstituicao && tipoNomeInstituicao) {
            let tempLead = this.props.lead;
            tempLead[tipoIdInstituicao] = ''
            tempLead[tipoNomeInstituicao] = ''
            this.props.definirEstadoComponentePai('lead', tempLead)
            return;
        }

        // No caso de onChange no selects de Instituição, ele retorna e (Event) diferente do padrão
        if (tipoIdInstituicao && tipoNomeInstituicao) {
            let tempLead = this.props.lead;
            // Clicou para criar Instituição
            if (e.value === -1) {
                const tempLead = this.props.lead
                tempLead.nomeInstituicaoEnsinoSuperior = this.state.nomeBuscaInsituicao
                this.setState({ semOpcaoInstituicaoEnsinoSuperior: true })
                this.props.definirEstadoComponentePai('lead', tempLead)
                return
            }
            tempLead[tipoIdInstituicao] = e.value
            tempLead[tipoNomeInstituicao] = e.label
            this.setState({ instituicoesEnsinoSuperiorOculta: false })
            this.props.definirEstadoComponentePai('lead', tempLead)
            return;
        }
        let tempLead = this.props.lead;
        tempLead[e.target.name] = e.target.value
        this.props.definirEstadoComponentePai('lead', tempLead)

    }

    onChangeFaseCurricular(e) {
        curriculoService.faseCurricularPassadaComCursoMaiorQueMaxPeriodoMaximo(e.target.value, this.props.curso.codigo)
            .then(async isFaseMaiorQueMaximoPeriodoCurriculo => {
                if (isFaseMaiorQueMaximoPeriodoCurriculo) {
                    await this.setState({ faseCurricularMaiorQueMaxCurriculo: true })
                    this.openToast(null, 'Isso é bem triste de dizer, mas infelizmente, não possuímos currículo ativo nesse período')
                } else {
                    this.setState({ faseCurricularMaiorQueMaxCurriculo: false })
                }
            })
        this.onChange(e)
    }

    obterUfs() {
        ufService.listAll()
            .then(ufs => {
                const tempLead = this.props.lead
                const idEstadoRio = ufs.find(el => el.sigla === 'RJ').id
                tempLead.idEstadoEnsinoSuperior = idEstadoRio
                this.props.definirEstadoComponentePai('lead', tempLead)
                this.setState({ ufs: this.renderizarUfs(ufs) });
            });
    }

    renderizarUfs(ufs) {
        return ufs.map((el, i) => (
            <option key={i} value={el.id}>
                {el.nome}
            </option>
        ));
    }   

    abrirPreviewDadosCandidatoModal(e) {
        e.preventDefault();
        this.props.definirEstadoComponentePai('callbackFunctionInsertCandidato', this.enviarCanditado)
        this.props.definirEstadoComponentePai('showPreviewDadosCandidatoModal', true)
    }

    enviandoDados(boolean) {
        this.props.definirEstadoComponentePai('isEnviandoDadosCandidato', boolean)
    }
    
     enviarCanditado() {
        this.enviandoDados(true)
        const candidato = {
            idLead: this.props.lead.idLead,
            idProcessoSeletivo: this.props.tipoProva,
            idOfertaProcessoSeletivo: this.props.oferta.id,
            idInstituicaoMantida: this.props.idInstituicaoMantida,
            idLocalProva: this.props.localProva.id,
            dataProva: Object.keys(this.props.dataProva).length === 0 ? null : this.props.dataProva,
            horaProva: this.props.horarioProva.agendamento,
            inPermiteEnvioSms: true,
            nrInscricaoEnem: this.props.lead.nrInscricaoEnem,
            nrAnoEnem: this.props.idAlavanca === 2 ? this.props.lead.nrAnoEnem : null,

            //Endereço
            cep: removerMascaras(this.props.endereco.cep),
            logradouro: this.props.endereco.logradouro,
            numero: this.props.endereco.numero,
            complemento: this.props.endereco.complemento,
            bairro: this.props.endereco.bairro,
            municipio: this.props.endereco.idMunicipio,
            uf: this.props.endereco.idUf,

            cpf: removerMascaras(this.props.lead.cpf),
            nome: this.props.lead.primeiroNome,
            telefone: this.props.lead.telefone,
            sobrenome: this.props.lead.sobrenome,
            email: this.props.lead.email,
            dataNascimento: this.props.lead.dataNascimento,
            // Dados de transferência e reingresso
            nomeMae: this.props.lead.nomeMae,
            nomePai: this.props.lead.nomePai,
            identidade: this.props.lead.identidade,
            orgaoExpedidor: this.props.lead.orgaoExpedidor,
            dataExpedicao: this.props.lead.dataExpedicao,
            anoConclusaoEnsinoMedio: this.props.lead.anoConclusaoEnsinoMedio,
            nomeCidadeEnsinoMedio: this.props.lead.nomeCidadeEnsinoMedio,
            idInstituicaoEnsinoMedio: this.props.lead.idInstituicaoEnsinoMedio,
            nomeInstituicaoEnsinoMedio: this.props.lead.nomeInstituicaoEnsinoMedio,
            idEstadoEnsinoMedio: this.props.lead.idEstadoEnsinoMedio,
            idEstadoEnsinoSuperior: this.props.lead.idEstadoEnsinoSuperior,
            nomeCursoOrigemEnsinoSuperior: this.state.isCursoMedicina ? 'Medicina' : this.props.lead.nomeCursoOrigemEnsinoSuperior,
            nomeInstituicaoEnsinoSuperior: this.props.lead.nomeInstituicaoEnsinoSuperior,
            anoConclusaoEnsinoSuperior: this.props.lead.anoConclusaoEnsinoSuperior,
            nomeCidadeEnsinoSuperior: this.props.lead.nomeCidadeEnsinoSuperior,
            idInstituicaoEnsinoSuperior: this.props.lead.idInstituicaoEnsinoSuperior,
            idNacionalidade: this.props.lead.idNacionalidade,
            idPaisNascimento: this.props.lead.idPaisNascimento,
            idEstadoCivil: this.props.lead.idEstadoCivil,
            idGenero: this.props.lead.idGenero,
            faseCurricular: this.props.lead.faseCurricular || 0, // Em casos que não for preenchido, é colocado zero
            possuiFies: this.props.opcaoTipoProva.aceitaFies ? JSON.parse(this.props.lead.possuiFies) : false,
            desejaIsencaoDisciplinas:!this.state.candidatoJaPossuiProcessoIsencaoMesmoCurso ? JSON.parse(this.props.lead.desejaIsencaoDisciplinas):false,
            cargaHorariaCumpridaEnsinoSuperior: this.props.lead.cargaHorariaCumpridaEnsinoSuperior,
            // Campo somente de medicina
            coeficienteRendimento: this.props.lead.coeficienteRendimento || null,

        }
        
        
        candidatoService.insert(candidato,this.props.openWarningModal)
            .then((returnedParamsProcedure) => {
                if (!returnedParamsProcedure) {
                    this.props.openWarningModal(null, null, true)
                    return;
                }
                
                if(this.props.opcaoTipoProva.aceitaFies && JSON.parse(this.props.lead.possuiFies)){
                     localforage.setItem('idCandidatoPossuiFies', returnedParamsProcedure.idCandidato)
                }
                
                this.finalizarInscricaoParaAlgunsCasos(returnedParamsProcedure)

            })
            .catch(erro => {
                this.props.openWarningModal(erro, 2, false)
                this.props.definirEstadoComponentePai('isEnviandoDadosCandidato', false)
                return;
            })
    }

    openToast(time, message, onClose) {
        if (toast.isActive(this.state.toastId)) {
            return;
        }
        this.setState({
            toastId: toast.warn(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: time,
                onClose: () => { if (onClose) { return onClose() } }
            })
        });
    }


    iniciarPassosFinais(returnedParamsProcedure) {
        const idTitulo = returnedParamsProcedure.idTitulo;
        const idCandidato = returnedParamsProcedure.idCandidato
        this.props.definirEstadoComponentePai('idTitulo', idTitulo)
        this.props.definirEstadoComponentePai('idCandidato', idCandidato)

        this.props.definirEstadoComponentePai("inscricaoConcluida", true);
        this.props.editarProgresso(null, 5, this.props.lead);
        this.enviandoDados(false);
        window.$('html,body').animate({ scrollTop: document.body.scrollHeight }, 2500);
    }

    obterInsituicoesEnsino(nomeBuscaInsituicao, propsUf) {
        const dadosInstituicaoEnsino = {
            nome: nomeBuscaInsituicao,
            idUf: propsUf
        }
        this.setState({ nomeBuscaInsituicao: nomeBuscaInsituicao })
        this.setState({ nomeCidadeEnsinoSuperior: nomeBuscaInsituicao })
        return instituicaoEnsinoService.findByEntityInEsinoSuperior(dadosInstituicaoEnsino)
            .then(res => {
                let opcoes = [];
                opcoes = res.map(o => {
                    return {
                        value: o['id'],
                        label: o['nome']
                    };
                });
                if (opcoes.length === 0) {
                    opcoes.push({
                        value: -1,
                        label: 'Não achou sua instituição ? clique aqui para salvá-la'
                    })
                }
                const limiteRegistroPorPagina = 60
                if (opcoes.length === limiteRegistroPorPagina) {
                    this.setState({ instituicoesEnsinoSuperiorOculta: true })
                } else {
                    this.setState({ instituicoesEnsinoSuperiorOculta: false })
                }
                return opcoes
            });

    }
    render() {
        return (
            <form id="dados" className="box_fade" onSubmit={(e) => this.abrirPreviewDadosCandidatoModal(e)}>
                <ToastContainer autoClose={false} />
                <h2 className="h3 text-blue mb-4">Informações Sobre o Ensino Superior de Origem</h2>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label" htmlFor="idEstadoEnsinoSuperior">Estado da Instituição de Origem</label>
                            <select required className="form-control" id="idEstadoEnsinoSuperior"
                                name="idEstadoEnsinoSuperior" value={this.props.lead.idEstadoEnsinoSuperior}
                                onChange={this.onChange}>
                                {this.state.ufs}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label" htmlFor="nomeCidadeEnsinoSuperior">Cidade da Instituição de Origem</label>
                            <input className="form-control required"
                                required id="nomeCidadeEnsinoSuperior" name="nomeCidadeEnsinoSuperior"
                                type="text" value={this.props.lead.nomeCidadeEnsinoSuperior}
                                onChange={this.onChange} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    {this.state.semOpcaoInstituicaoEnsinoSuperior ?
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="control-label" htmlFor="nomeInstituicaoEnsinoSuperior">Nome da Instituição do Ensino Superior</label>
                                <input required={!this.props.lead.idInstituicaoEnsinoSuperior}
                                    disabled={this.props.lead.idInstituicaoEnsinoSuperior} className="form-control required" id="nomeInstituicaoEnsinoSuperior" name="nomeInstituicaoEnsinoSuperior" type="text" value={this.props.lead.nomeInstituicaoEnsinoSuperior} onChange={this.onChange} />
                            </div>
                        </div> :
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="control-label" htmlFor="idInstituicaoEnsinoSuperior">
                                    Nome da Instituição
                            </label>
                                {this.state.instituicoesEnsinoSuperiorOculta ?
                                    <span className="text-intiuicoes-oculta">
                                        *Existem itens ocultos, digite mais dados sobre a sua instituição
                            </span>
                                    : null}
                                <AsyncSelect
                                    placeholder="Digite o nome da instituição"
                                    isClearable
                                    id="idInstituicaoEnsinoSuperior"
                                    name="idInstituicaoEnsinoSuperior"
                                    noOptionsMessage={(e) => noOptionsMessage(e, 'Instituição não cadastrada, entre em contato com sua Unidade/Polo')}
                                    value=
                                    {this.props.lead.idInstituicaoEnsinoSuperior ?
                                        { label: this.props.lead.nomeInstituicaoEnsinoSuperior, value: this.props.lead.idInstituicaoEnsinoSuperior } : false}
                                    onChange={(e) => this.onChange(e, 'idInstituicaoEnsinoSuperior', 'nomeInstituicaoEnsinoSuperior')}
                                    loadOptions={(e) => this.obterInsituicoesEnsino(e, this.props.lead.idEstadoEnsinoSuperior, true)}

                                />
                            </div>
                        </div>}


                    <div className={`col-md-${this.state.isReingresso ? 3 : 6}`}>
                        {this.state.isCursoMedicina ?
                            <React.Fragment>
                                <div className="form-group">
                                    <label className="control-label" htmlFor="coeficienteRendimento">Coeficiente de Rendimento (CR)</label>
                                    <input className="form-control required" min={1} max={10} step="0.01" required id="coeficienteRendimento" name="coeficienteRendimento"
                                        type="number" value={this.props.lead.coeficienteRendimento} onChange={this.onChange} />
                                </div>
                            </React.Fragment>
                            :
                            <div className="form-group">
                                <label className="control-label" htmlFor="nomeCursoOrigemEnsinoSuperior">Curso de Origem</label>
                                <input className="form-control required" required id="nomeCursoOrigemEnsinoSuperior" name="nomeCursoOrigemEnsinoSuperior"
                                    type="text" value={this.props.lead.nomeCursoOrigemEnsinoSuperior} onChange={this.onChange} />
                            </div>


                        }
                    </div>
                    {this.state.isReingresso ?
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="control-label" htmlFor="anoConclusaoEnsinoSuperior">Ano de Conclusão</label>
                                <input className="form-control required" required id="anoConclusaoEnsinoSuperior" name="anoConclusaoEnsinoSuperior"
                                    type="number" min="1950" max={this.state.anoAtual} value={this.props.lead.anoConclusaoEnsinoSuperior} onChange={this.onChange} />
                            </div>
                        </div> : null}


                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label className="control-label" htmlFor="desejaIsencaoDisciplinas">
                            Você Deseja Solicitar a isenção de disciplinas ?
                            </label>
                        <select disabled={this.state.candidatoJaPossuiProcessoIsencaoMesmoCurso} required className="form-control required" id="desejaIsencaoDisciplinas" name="desejaIsencaoDisciplinas"
                            value={this.props.lead.desejaIsencaoDisciplinas} onChange={(e) => {
                                if (e.target.value && JSON.parse(e.target.value) === false) {
                                 this.props.definirEstadoComponentePai('lead',
                                        {
                                            ...this.props.lead,
                                            faseCurricular: 0,
                                            desejaIsencaoDisciplinas: false,
                                            cargaHorariaCumpridaEnsinoSuperior:null
                                        })
                                    
                                    return
                                }

                                this.onChange(e)
                            }}>
                            <option value="">Selecione</option>
                            <option value={true}>Sim</option>
                            <option value={false}>Não</option>
                        </select>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="control-label" htmlFor="fase">Último Período Cursado</label>
                            <select disabled={!this.props.lead.desejaIsencaoDisciplinas || (this.props.lead.desejaIsencaoDisciplinas && JSON.parse(this.props.lead.desejaIsencaoDisciplinas) === false)} required className="form-control required" id="faseCurricular" name="faseCurricular"
                                value={this.props.lead.faseCurricular} onChange={this.onChangeFaseCurricular}>
                                <option value="">Selecione</option>
                                {Array.from(Array(this.state.maxFaseCurricular), (x, index) => index + 1).map(el =>
                                    <option value={el}>Período {el}</option>
                                )}

                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="control-label" htmlFor="cargaHorariaCumpridaEnsinoSuperior">Carga horária cumprida na Instituição de Origem</label>
                            <input className="form-control required"
                                required id="cargaHorariaCumpridaEnsinoSuperior" name="cargaHorariaCumpridaEnsinoSuperior"
                                type="number" max={32767} min={1} value={this.props.lead.cargaHorariaCumpridaEnsinoSuperior || "null"}
                                disabled={!this.props.lead.desejaIsencaoDisciplinas || (this.props.lead.desejaIsencaoDisciplinas && JSON.parse(this.props.lead.desejaIsencaoDisciplinas) === false)}
                                onChange={this.onChange} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    { this.props.opcaoTipoProva.aceitaFies ? 
                     <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label" htmlFor="possuiFies">
                                Você possui fies e quer transferi-lo para Unigranrio?
                            <a href="https://cdn2.hubspot.net/hubfs/3960387/PROCESSO%20SELETIVO/Formul%C3%A1rio%20de%20Transfer%C3%AAncia%20Externa%20FIES.pdf" target="_blank"><span className="text-informativo">Clique aqui para obter mais informações</span></a>
                            </label>
                            <select  required className="form-control required" id="possuiFies" name="possuiFies"
                                value={this.props.lead.possuiFies} onChange={this.onChange}>
                                <option value="">Selecione</option>
                                <option value={true}>Sim</option>
                                <option value={false}>Não</option>
                            </select>
                        </div>
                    </div>
                    : ''}

                    {this.state.isCursoMedicina ? <div className="col-md-6">
                        <div className="form-group">
                            <label className="control-label" htmlFor="confirmacaoVenhoCursoOrigemMedicina">
                                Seu curso de origem realmente de MEDICINA ?
                                <span className="text-informativo">
                                    Caso contrário, não será possível prosseguir com a sua inscrição.
                                </span>
                            </label>
                            <select required className="form-control required" id="confirmacaoVenhoCursoOrigemMedicina" name="confirmacaoVenhoCursoOrigemMedicina"
                                value={this.props.lead.confirmacaoVenhoCursoOrigemMedicina} onChange={this.onChange}>
                                <option value={false}>Não</option>
                                <option value={true}>Sim</option>
                            </select>
                        </div>
                    </div> : null}
                </div>

                <br />

                <div id="confirmar-cancelar" className="container">
                    <button id="btCancelarInscricao" className="btn btn-primary bt-cancelar" type="button" onClick={() => this.props.editarProgresso(null, 1)} disabled={this.props.carregando}>Voltar à Página Inicial</button>
                    <button id="btConfirmaInscricao" className="btn btn-primary bt-confirmar" type="submit" disabled={this.props.carregando || this.state.faseCurricularMaiorQueMaxCurriculo || (this.state.isCursoMedicina && !this.props.lead.confirmacaoVenhoCursoOrigemMedicina)}>Continuar</button>
                </div>
            </form>

        )
    }
}

