import React, { Component } from 'react';
import FooterFatenp from './footers/FooterFatenp';
import FooterUnigranrio from './footers/FooterUnigranrio';

export default class Footer extends Component {
  render() {
    return (
      <div>
        {this.props.location ? 
        <div>
          {this.props.location.pathname.includes('fatenp') || (this.props.unidade && (this.props.unidade.codigo === '30' ||  this.props.unidade.codigo === '31')) ? <FooterFatenp {... this.props} /> : <FooterUnigranrio {... this.props} />}
        </div>
        :''}        
      </div>
    )
  }
}