const idProjeto = 4;
const idFormaPagamentoBoleto = 1;
const idFormaPagamentoCartao = 4;
const codigoCursos = {
    medicina:'45'
}

const fraseGenericaErro = 'Você esbarrou em um problema temporário, ligue para 3219-4040 ou 0800 003 4040, você pode também mandar uma mensagem para o whatsapp 21 3219-4040 e finalizar sua inscrição. Desculpe pelo transtorno.'

// const apiBoleto = 'https://boleto.unigranrio.edu.br/VisualizarBoleto/'

const apiBase = process.env.REACT_APP_API_URL;

// const apiBaseComercial = apiBase + 'comercial-api';

const apiBaseProcessoSeletivo = apiBase + 'processo-seletivo-api';

// const apiBaseControleAcesso = apiBase + 'controle-acesso-api';

// const apiBaseFinanceiro = apiBase + 'financeiro-api';

// const apiBaseEstruturaGlobal = apiBase + 'estrutura-global-api';



// const apiBaseComercial = 'http://localhost:8204'

//  const apiBaseProcessoSeletivo = 'http://localhost:8205';

// const apiBaseControleAcesso = 'http://localhost:8206';

// const apiBaseFinanceiro = 'http://localhost:8210';

// const apiBaseEstruturaGlobal = 'http://localhost:8211'; 

export { 
    idProjeto, 
    idFormaPagamentoBoleto,
     idFormaPagamentoCartao, 
    //  apiBoleto, 
    //  apiBaseComercial, 
     apiBaseProcessoSeletivo, 
    //  apiBaseControleAcesso, 
    //  apiBaseFinanceiro, 
    //  apiBaseEstruturaGlobal, 
     fraseGenericaErro, 
     codigoCursos 
    };