
// import api from '../../utils/api';

// import {apiBaseEstruturaGlobal} from '../../config';


const enderecoService = {};

// enderecoService.findOneByCep =  function(dadosEndereco){
//     return api.postBaseURL('/enderecos/cep', dadosEndereco, apiBaseEstruturaGlobal)
//     .then(endereco => {
//         return endereco;
//     });    
// }


export default enderecoService;