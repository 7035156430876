
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const opcaoProvaService = {};

opcaoProvaService.findByEntity =  function(dadosOpcaoProva){
    return api.postBaseURL('/opcao-prova/search', dadosOpcaoProva,apiBaseProcessoSeletivo)
            .then((datasProva) => {                
            return datasProva;               
    })        
}

export default opcaoProvaService;