import React, { Component } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import TabelaDisciplinas from './TabelaDisciplinas';
export default class CardDadosDisciplinasCursadas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAccordionDisciplinas: this.props.showAccordionDisciplinas,
    }
    this.handleAccordion = this.handleAccordion.bind(this)

  }
  handleAccordion() {
    this.setState({ showAccordionDisciplinas: !this.state.showAccordionDisciplinas })
  }
  render() {
    return (
      <div style={{ margin: '5px 0 5px 0' }} className="card accordion-card-disciplinas">
        <div onClick={this.handleAccordion} className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button className="btn-curso-uni-aprovado" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <div className="row">
                <div className="col-md-10">
                  <p className="curso-aprovado">{this.props.nomePeriodoLetivo} - <span className="universidade-aprovado">{this.props.nomeInstituicao} - {this.props.siglaUf}</span></p>
                </div>
                <div className="col-md-2 disciplinas-salvas">
                  {this.state.showAccordionDisciplinas ? <FaCaretUp /> : <FaCaretDown />}
                </div>
              </div>
              <p className="small-text">Clique para mais detalhes</p>
            </button>
          </h5>
        </div>

        <div id="collapseOne" className={`collapse ${this.state.showAccordionDisciplinas ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">          
           <TabelaDisciplinas 
           disciplinas={this.props.disciplinas} 
           disciplinasProcessadas={this.props.disciplinasProcessadas}
           preencherInputsDisciplinasComDisciplinaEscolhida={this.props.preencherInputsDisciplinasComDisciplinaEscolhida}
           deleteDisciplina={this.props.deleteDisciplina}/>          
        </div>
      </div>
    )
  }
}

