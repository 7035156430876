
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';

const localProvaService = {};

localProvaService.findByEntity =  function(dadosLocalProva){
    return api.postBaseURL('/local-prova/search', dadosLocalProva,apiBaseProcessoSeletivo)
            .then((locaisProva) => {               
            return locaisProva;               
    });       
}

export default localProvaService;