import React, { Component } from "react";
import Container from "../../components/Container";
import Carregando from '../../components/utils/Carregando';
import CurrencyFormat from 'react-currency-format';
import unidadeService from '../../services/comercial/unidade-service';
import modalidadeEnsinoService from '../../services/comercial/modalidade-ensino-service';
import ofertaService from '../../services/comercial/oferta-service';
import enem2020 from '../../images/enem2020.png'
import md5 from 'md5';
import { getNomeMantida } from "../../utils/string";
import { Redirect } from "react-router-dom";
export default class CursoList extends Component {
  constructor(props) {
    super(props);
    const idMantida = this.props.location.pathname.includes('fatenp') ? 2 : 1;    

    this.state = {
      oldCursos: [],
      cursos: [],
      unidades: [],
      arrayUnidades: [],
      modalidades: [],
      turno: [],
      arrayModalidades: [],
      carregando: true,
      idMantida: idMantida,
      pesquisa: {
        nomeCurso: "",
        idUnidadeEnsino: 0,
        idAlavanca: !isNaN(this.props.match.params.idAlavanca) ? (parseInt(this.props.match.params.idAlavanca, 10) === 0 ? 6 : parseInt(this.props.match.params.idAlavanca, 10)) : 6,
        idModalidadeEnsino: 0,
        idInstituicaoMantida: idMantida
      },
      pesquisaUnidade: {
        idInstituicaoMantida: idMantida,
        idAlavanca: !isNaN(this.props.match.params.idAlavanca) ? (parseInt(this.props.match.params.idAlavanca, 10) === 0 ? 6 : parseInt(this.props.match.params.idAlavanca, 10)) : 6,
      },
      showLinkMedicina: false
    };
    this.mudarPagina = this.mudarPagina.bind(this);
    this.renderizarCursos = this.renderizarCursos.bind(this);
    this.renderizarUnidades = this.renderizarUnidades.bind(this);
    this.renderizarModalidades = this.renderizarModalidades.bind(this);
    this.buscarCursosPorInput = this.buscarCursosPorInput.bind(this);
    this.buscarCursosPorUnidade = this.buscarCursosPorUnidade.bind(this);
    this.buscarPorModalidade = this.buscarPorModalidade.bind(this)
    this.escolherCurso = this.escolherCurso.bind(this);
    

  }

  componentDidMount() {
    let temp = this.state.pesquisa
    let idAlavanca = !isNaN(this.props.match.params.idAlavanca) ? parseInt(this.props.match.params.idAlavanca, 10): 6;
    
    if (!idAlavanca) {
      idAlavanca = 6;
    }
    if(idAlavanca === 0){
      idAlavanca = 6
    }

    

    if(isNaN(this.props.match.params.idAlavanca)){
      // Caso venho pela url /curso/ead
      temp.idModalidadeEnsino = 2 // id do ead
    }
    
    this.setState({ pesquisa: idAlavanca  })

    //Pesquisa Todas as Unidades
    this.setState({ listaCorpo: this.state.list });
    this.obterUnidades()
    this.chamarApiPesquisaCursos(this.state);
    
  }

  buscarCursosPorInput(e) {
    this.alterarStatePesquisa(e);
    const cursosFiltrados = this.state.oldCursos.filter((oldCurso) =>
      oldCurso.nome.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ cursos: this.renderizarCursos(cursosFiltrados) });
  }

  buscarCursosPorUnidade(e) {
    if(e.target.value === 10 ){
      const temp = this.state.pesquisa
      temp.idInstituicaoMantida = 1
      this.setState({pesquisa:temp})
      this.obterModalidades(this.state.idMantida)      
    }
    this.alterarStatePesquisa(e)
    this.chamarApiPesquisaCursos()
  }

  buscarPorModalidade(e) {
    this.alterarStatePesquisa(e)
    this.chamarApiPesquisaCursos(e)
  }

  componentDidMount() {
    let temp = this.state.pesquisa
    let idAlavanca = !isNaN(this.props.match.params.idAlavanca) ? parseInt(this.props.match.params.idAlavanca, 10): 0;

    if (!idAlavanca) {
      idAlavanca = 0;
    } /* else {
      idAlavanca = idAlavanca === 1 ? 16 : idAlavanca; 
    } */
      if(idAlavanca === 0){
        idAlavanca = 6
      }

    if(isNaN(this.props.match.params.idAlavanca)){
      switch(this.props.match.params.idAlavanca){
        case 'presencial': temp.idModalidadeEnsino = 1;
        break;
        case 'ead': temp.idModalidadeEnsino = 2;
        break;
        case 'semi-presencial': temp.idModalidadeEnsino = 3;
        break;
        default:temp.idModalidadeEnsino = 0;
      }
    }
    

    temp.idAlavanca = idAlavanca
    this.setState({ pesquisa: temp })

    //Pesquisa Todas as Unidades
    this.setState({ listaCorpo: this.state.list });
    this.obterUnidades()
  }

  escolherCurso(e, curso) {
    e.preventDefault();
    
    if(curso.linkExterno != "" && curso.linkExterno != undefined ){
      window.location.assign(curso.linkExterno);
    }

    const url = this.props.location.pathname.includes('fatenp') 
    ? `/fatenp/${this.state.pesquisa.idAlavanca}/${curso.id}`
    : `/curso/${this.state.pesquisa.idAlavanca}/${curso.id}/${this.state.pesquisa.idUnidadeEnsino}`

    this.props.history.push(url, { curso: curso, unidades: this.state.arrayUnidades, lead: this.props.location.state ? this.props.location.state.lead : null });
  }

  mudarPagina(e, url) {
    e.preventDefault();
    this.props.history.push(url);
  }

  renderizarUnidades(unidades) {
    if(unidades.length > 0){
      let temp = this.state.pesquisa
      if(this.props.location.pathname.includes('fatenp')){      
        temp.idUnidadeEnsino = 0 //ID DO POLO FATENP UNIGRANRIO      
      }else{
        temp.idUnidadeEnsino = this.props.match.params.idUnidade ? this.props.match.params.idUnidade : 
                                  unidades.find(el => el.id.includes('1101150')) !== undefined ? unidades.find(el => el.id.includes('1101150')).id  : 0
      }
      this.setState({pesquisa:temp})    
      return unidades.map((el, i) => (
        <option key={i} value={el.id}>
          {el.nome}
        </option>
      ));
    }
  }

  renderizarModalidades(modalidades) {         
    return modalidades.map((el, i) => (
      <option key={i} value={el.id}>
        {el.nomeReduzido}
      </option>
    ));
  }

  alterarStatePesquisa(event) { 
    let temp = this.state.pesquisa
    if (event.target.value !== null) {
      temp.nomeCurso = event.target.name === 'nomeCurso' ? event.target.value : this.state.pesquisa.nomeCurso;
      temp.idUnidadeEnsino = event.target.name === 'idUnidadeEnsino' && this.state.idUnidadeEnsino !== 0 ? parseInt(event.target.value, 10) : this.state.pesquisa.idUnidadeEnsino;
      temp.idModalidadeEnsino = event.target.name === 'idModalidadeEnsino' && this.state.idModalidadeEnsino !== 0 ? parseInt(event.target.value, 10) : this.state.pesquisa.idModalidadeEnsino;
    }
    this.setState({ pesquisa: temp });
  }

   chamarApiPesquisaCursos() { 
  
    ofertaService.findCourses(this.state.pesquisa)           
      .then(cursos => {
        
        let tempCurso  = cursos
        
        // //Não renderizar Medicina para Polos/Unidades, fora Caxias e Barra
        // if(this.state.pesquisa.idUnidadeEnsino != 1 && this.state.pesquisa.idUnidadeEnsino != 6 ) {
        //   tempCurso = cursos.filter(x => {
        //     return x.id !== 106 ?  true : false
        //   })
        // } else {
        // //se não tiver oferta rederizar link informativo
        //   const exists =  cursos.some( x => x.id === 106)
        //   if(!exists) {
        //     this.setState({showLinkMedicina: true});
        //   }
        // }

        const stateCursos = this.renderizarCursos(cursos);
        this.setState({ cursos: stateCursos, oldCursos: cursos, carregando: false });
      })     
  }

  obterUnidades() {        
    unidadeService.findByRM(this.state.pesquisaUnidade)
      .then(unidades => {
        
        unidades.map(unidade => 
          {
            const nameUpperCase = unidade.nome.toUpperCase();
            
            unidade.nome = nameUpperCase;
          return unidade;
         })
        
        this.setState({ unidades: this.renderizarUnidades(unidades), arrayUnidades: unidades });
        this.setState({ listaCorpo: this.state.list });
        
        this.obterModalidades(this.state.idMantida) 
        //Pesquisa Todos os Cursos
        this.chamarApiPesquisaCursos()
      })      
    }
  obterModalidades(){          
    modalidadeEnsinoService.findByEntity({idInstituicaoMantida:1})      
      .then(modalidades =>{
        this.setState({ modalidades: this.renderizarModalidades(modalidades), arrayModalidades: modalidades });
      })      
    }

  urlCurso(curso){    
    if(this.props.location.pathname.includes('fatenp')){
      return `${this.props.location.pathname}/${curso.id}`.replace('curso/', '') 
    }
    return `${this.props.location.pathname}/${curso.id}/${this.state.pesquisa.idUnidadeEnsino}`    
    
  }

  renderizarCursos(cursos) {

    if (!cursos.length) {
      return (
        <>
          <p> Em breve, inscrições abertas para os nossos cursos.</p>
          <th style={{color:'#08427F', fontWeight: '800'}}>em breve</th>
        </>
        )
    }

    return cursos.map((el, i) => (
      <tr key={i}>
        <td className="width30">
          <i className="fa fa-angle-right" />
          <a
            className=""
            href={this.urlCurso(el)}
            onClick={(e) => this.escolherCurso(e, el)}
          >
            {el.nome}
          </a>
        </td>
        <td>
         
          {el.valorApartirDe !== null && el.valorApartirDe !== 0 ?  (
            <CurrencyFormat
              value={el.valorApartirDe ? el.valorApartirDe : 0}
              thousandSeparator=""
              decimalScale={2}
              fixedDecimalScale={true}
              decimalSeparator=","
              displayType={'text'}
              prefix={'R$ '}
            />
          ) : (
            'Sob consulta'
          )}
        </td>
        <td>
          {el.proximaTurma.includes('SEMI') && (
            <i className="indicador semi">S</i>
          )}
          {el.proximaTurma.includes('EAD') && <i className="indicador ead">E</i>}
          {el.proximaTurma.includes('PRESENCIAL') && <i className="indicador presencial">P</i>}
          {/* </td><i className={
            //   el.proximaTurma.includes('SEMI') ? 'indicador semi' :
            //   modalidade.nomeReduzido.includes('EAD') ? 'indicador ead' :
            //   modalidade.nomeReduzido.includes('Presencial') ? 'indicador presencial' : 'indicador'

            // }>
            //   {modalidade.nomeReduzido.includes('SEMI') ? 'S' :
            //     modalidade.nomeReduzido.includes('EaD') ? 'E' :
            //     modalidade.nomeReduzido.includes('Presencial') ? 'P' : ''}
            // </i>
          // } */}
        </td>
        <td className="width30">
          <i className="fa fa-hourglass" />
          {el.duracao}
        </td>
        <td>
          <button
            className="btn btn-primary"
            onClick={(e) => this.escolherCurso(e, el)}
          >
            ESCOLHER CURSO
          </button>
        </td>
      </tr>
    ));
  }
  getNomeFormaIngresso(){
    switch(this.state.pesquisa.idAlavanca){
      case 99 :return 'VESTIBULAR'      
      case 5:return 'ENEM'      
      case 8:return 'TRANSFERÊNCIA EXTERNA'
      case 9:return 'PORTADOR DE DIPLOMA'
      case 10:return 'HISTÓRICO ESCOLAR'
      case 6:return 'VESTIBULAR ON-LINE'
      default: return 'PROCESSO SELETIVO';
    }
  }

  render() {


    // if(this.state.pesquisa.idAlavanca === 6){
    //  return <Redirect to='/curso/34' />
    // }
    return (
      <Container {...this.props}>
        <div>
            <section className="main">
            {/* <div id="pelicula"></div>  */}
            <section className="bg-curso"
             style={
              (() => {
                switch(this.state.pesquisa.idAlavanca) {
                  case 1:
                    return { backgroundImage: `url(${enem2020})` };
                  case 2:
                    return { backgroundImage: `url(${enem2020})`,  };
                  case 3:
                    return { backgroundImage: `url(${enem2020})` };
                  case 4:
                    return { backgroundImage: `url(${enem2020})` };
                  case 15:
                    return { backgroundImage: `url(${enem2020})` };
                  case 16:
                    return { backgroundImage: `url(${enem2020})` };
                  default:
                    return { backgroundImage: `url(${enem2020})` };
                }
              })()
              }> 
            {this.props.location.state && this.props.location.state.lead ? <img src={`https://action.metaffiliation.com/trk.php?mclic=G5100571011&argann=${md5(this.props.location.state.lead.email)}&altid=${this.props.location.state.lead.idLead}" width="1" height="1" border="0"`} width="1" height="1" border="0"/>:null}      
              <div>
                <div className="col-xs-12">
                  <h2 className="name-course title_course-1" style={{color: '#fff', textAlign: 'center', width: '100%',}}>
                  {


                    (() => {
                      switch(this.state.pesquisa.idAlavanca) {
                        case 99:
                          return 'VESTIBULAR ';
                        case 5:
                          return 'ENEM ';
                        case 8:
                          return 'TRANSFERÊNCIA EXTERNA ';
                        case 9:
                          return 'PORTADOR DE DIPLOMA ';
                        case 10:
                          return 'HISTÓRICO ESCOLAR ' ;
                        case 6:
                          return 'VESTIBULAR ON-LINE';
                        default:
                          return 'VESTIBULAR ON-LINE';
                      }
                    })() //+  ' ' + getNomeMantida(this.state.idMantida)
                  }
                  </h2>
                </div>
              </div>
            </section>
            
            <div className="filters">
              <div className="container">
                <p className="text_unigranrio_crusolist">
                  {this.props.location.pathname.includes('fatenp') ? `A Unigranrio oferece inúmeros cursos de graduação, distribuídos em unidades presenciais e dezenas de polos de educação a distância. Inscreva-se`
                  :`A Unigranrio oferece inúmeros cursos de graduação, distribuídos em unidades presenciais e dezenas de polos de educação a distância. Inscreva-se!`}
                </p>

                <div className="row d-flex align-items-center">
                  <div className="col-sm-4 col-lg-3 mb-2 mb-sm-0">
                  <label className="font-500">CURSO</label>
                    <input
                      onChange={this.buscarCursosPorInput}
                      value={this.state.nomeCurso}
                      className="search form-control form-control-sm input-height"
                      type="text"
                      id="s"
                      name="nomeCurso"
                      placeholder="Exemplo: Enfermagem"
                    />
                  </div>
                  {
                    this.props.location.pathname.includes('fatenp') ? null :
                    <div className="col-sm-4 col-lg-3 mb-2 mb-sm-0">
                      <label className="font-500">CAMPUS</label>
                      <select name="idUnidadeEnsino" style={{ padding: "0" }} value={this.state.pesquisa.idUnidadeEnsino} onChange={this.buscarCursosPorUnidade} className="form-control form-control-sm select-curso input-height">
                        {/* <option value="0">-- Todos --</option> */}
                        {this.state.unidades}
                      </select>
                    </div>
                  }
                   <div className="col-sm-4 col-lg-3 mb-2 mb-sm-0" style={{}}>
                      <label className="font-500">OFERTA</label>
                      <select value={this.state.pesquisa.idModalidadeEnsino} name="idModalidadeEnsino" id="idModalidadeEnsino" onChange={this.buscarPorModalidade} style={{ padding: "0" }} className="form-control form-control-sm select-curso input-height">
                        <option value="0">-- Todas --</option>
                        {this.state.modalidades}

                      </select>
                   </div>
                  <div className="legend-buttons col-lg-3 d-none d-lg-block mgtop">
                    <span className="legend-block">
                      <span className="indicador presencial">P</span> PRESENCIAL
                    </span>
                    <span className="legend-block">
                      <span className="indicador ead">E</span> EAD
                    </span>
                    <span className="legend-block">
                      <span className="indicador semi">S</span> SEMIPRESENCIAL
                    </span>
                  </div>
                  <div className="col-sm-4 col-lg-3" />
                </div>
              </div>
            </div>
              {this.state.carregando ? <Carregando />:
            <div className="container">             
              {this.state.idMantida === 1 ? 
               
             <p>Para informações sobre o curso de Medicina. <a href="https://portal.unigranrio.edu.br/medicina" target="_blank">acesse aqui.</a> </p> : null} 

                  
              <div className="row" id="cards-pb">
              </div>
              <div className="table-responsive content-cursos" id="yep">

                <table className="courses-table table tabela-cursos table-sm">
                  <thead>
                    <tr className="col_list_cursos">
                      <th>Curso</th>
                      <th>A partir de</th>
                      <th>Oferta</th>
                      <th>Duração</th>
                      <th />

                    </tr>
                  </thead>
                  <tbody className="list">{this.state.cursos}</tbody>
                </table>

                <div className="content-boxes" />
              </div>
              
            </div>}
          </section>
        </div>
      </Container>
    );
  }
}
