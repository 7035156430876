import React, { Component } from 'react';
import Container from '../../components/Container';

import ofertaService from '../../services/comercial/oferta-service'
import unidadeService from '../../services/comercial/unidade-service';
import diaProvaService from '../../services/processo-seletivo/dia-prova-service'
import opcaoProvaService from '../../services/processo-seletivo/opcao-prova-service'
import localProvaService from '../../services/processo-seletivo/local-prova-service'
import ofertaProcessoSeletivoService from '../../services/processo-seletivo/oferta-processo-seletivo-service'
import horarioProvaService from '../../services/processo-seletivo/horario-prova-service'
import pathImages from '../../images/adm.png'
import Carregando from '../../components/utils/Carregando';
import CardModalidade from './CardModalidade';
import FormDados from './FormDados';
import Agendamento from './Agendamento';
import { fraseGenericaErro } from '../../config';
import SucessoInscricao from '../SucessoInscricao';
import moment from 'moment';
import CardLateralCurso from './CardLateralCurso';
import EscolhaFormaIngresso from './EscolhaFormaIngresso';
import TimelineVestibular from '../../components/cards/TimelineVestibular';
import TimelineEnem from '../../components/cards/TimelineEnem';
import TimelineTransferenciaReingresso from '../../components/cards/TimelineTransferenciaReingresso';


import Warnings from '../../components/utils/Warnings'
import { tipoErro } from '../../components/utils/Warnings'
import FormInfoEscolaridade from '../../components/fluxo-transferencia-reingresso/FormInfoEscolaridade';
import ModalPreviewDados from '../../components/candidato/previews/ModalPreviewDados';
import contatoHubspotService from '../../services/hubspot/contato-hubspot-service'
import alavancaService from '../../services/comercial/alavancaService';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export default class Graduacao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {
        show: false,
        message: '',
        tipoErro: '',
      },
      isEnviandoDadosCandidato: false,
      showPreviewDadosCandidatoModal: false,
      lead:
        this.props.location.state && this.props.location.state.lead
          ? this.props.location.state.lead
          : {},
      emailSalvoPrimeiraPage:
        this.props.location.state && this.props.location.state.lead
          ? this.props.location.state.lead.email
          : {},
      idsAlavancasDisponiveis: [],
      idsAlavancasSemUnidades: [],
      curso: {},
      unidade: {},
      turno: {},
      reajuste: true,
      modalidade: {},
      oferta: {},
      oldUnidades: [],
      unidades: [],
      localProva: {},
      horarioProva: {},
      arrayUnidades: [],
      modalidadeEscolhida: {},
      formDados: {},
      ofertas: [],
      arrayOfertas: [],
      idInstituicaoMantida: this.props.location.pathname.includes('fatenp')
        ? 2
        : 1,
      idUnidade: 0,
      idCurso: 0,
      idAlavanca: 0,
      idFormaIngressoProcessoSeletivo: 0,
      idTurno: 0,
      idModalidadeEnsino: 0,
      idCandidato: 0,
      idTitulo: 0,
      idRegistroTitulo: 0,
      idRegistroTituloBoleto: 0,
      idRegistroTituloCartao: 0,
      opcoesTiposProva: [],
      opcaoTipoProva: {},
      locaisProva: [],
      carregando: true,
      turnoSelecionado: {},
      ofertaTurno: [],
      modalidades: [],
      valores: [],
      escolheuModalidade: false,
      preencheuFormulario: false,
      escolheuOferta: false,
      inscricaoConcluida: false,
      passo: 1,
      tipoProva: 0,
      dataProva: '',
      dataProvaDatePicker: null,
      desabilitarDatePicker: true,
      datasProva: [],
      horariosProva: [],
      datasBloqueadas: [],
      diasPermitidos: [],
      endereco: {
        idEndereco: '',
        idUf: 0,
        sgUf: '',
        nomeMunicipio: '',
        cep: '',
        numero: '',
        complemento: '',
        logradouro: '',
        bairro: '',
        idMunicipio: 0,
      },
      callbackFunctionInsertCandidato: '',
      dataMax: '',
      dataMin: '',
      enviouInformacoesOfertaToHubspot: false,
    };

    this.renderizarUnidades = this.renderizarUnidades.bind(this);
    this.renderizarOfertas = this.renderizarOfertas.bind(this);
    this.renderizarDivEscolhaCursoUnidade =
      this.renderizarDivEscolhaCursoUnidade.bind(this);
    this.renderizarTelaFinal = this.renderizarTelaFinal.bind(this);
    this.renderizarTimeline = this.renderizarTimeline.bind(this);
    this.selecionarUnidade = this.selecionarUnidade.bind(this);
    this.selecionarUnidade = this.selecionarUnidade.bind(this);
    this.buscarUnidadesPorInput = this.buscarUnidadesPorInput.bind(this);
    this.editarProgresso = this.editarProgresso.bind(this);
    this.selecionarVestibular = this.selecionarVestibular.bind(this);
    this.obterOfertaProcessoSeletivo =
      this.obterOfertaProcessoSeletivo.bind(this);
    this.definirEstadoComponentePai =
      this.definirEstadoComponentePai.bind(this);
    this.trocarFormaIngresso = this.trocarFormaIngresso.bind(this);
    this.scrollParaUnidadeSelecionada =
      this.scrollParaUnidadeSelecionada.bind(this);
    this.escolherFluxoFormTransferenciaReingresso =
      this.escolherFluxoFormTransferenciaReingresso.bind(this);
    this.onChange = this.onChange.bind(this);
    this.trocarEstadoPai = this.trocarEstadoPai.bind(this);
    this.trocarUnidade = this.trocarUnidade.bind(this);
    this.closeWarningModal = this.closeWarningModal.bind(this);
    this.openWarningModal = this.openWarningModal.bind(this);
    this.getLinkDetalhesCurso = this.getLinkDetalhesCurso.bind(this);
  }

  componentWillMount() {
    const idAlavanca = parseInt(this.props.match.params.idAlavanca, 10) === 0 ? 6 : parseInt(this.props.match.params.idAlavanca, 10)|| 6;
    const idUnidade = parseInt(this.props.match.params.idUnidade, 10) || 0;
    const idCurso = String(this.props.match.params.idCurso) || 0;
    const idInstituicaoMantida = this.verificaMantida();

    this.setState(
      {
        idAlavanca: idAlavanca === 1 ? 6 : idAlavanca,
        idCurso: idCurso,
        idUnidade: idUnidade,
        idInstituicaoMantida: idInstituicaoMantida,
      },
      async () => {
        await this.getAllIdsAlavancasDisponiveis();
        this.obterUnidades(
          idAlavanca,
          idUnidade,
          idCurso,
          idInstituicaoMantida,
          true
        );
      }
    );
  }

  async getAllIdsAlavancasDisponiveis() {
    /* ID'S devem ter ordem de precedência sem nenhum critério, devido a isso, a estratégia
     escolhida foi colocar esses ids mockados, esses ID'S São da tabela TB_MCO_Alavanca
    const alavancas = await alavancaService.findAll() 
    this.setState({idsAlavancasDisponiveis:alavancas.map(alavanca => alavanca.id)})
    */
    // Ordem de precedência: Análise de Histórico; Enem; Vestibular Online; Vestibular Agendado; Transferência; Reingresso

    // case 99 :return 'VESTIBULAR'
    // case 5:return 'ENEM'
    // case 7:return 'TRANSFERÊNCIA EXTERNA'
    // case 8:return 'PORTADOR DE DIPLOMA'
    // case 9:return 'HISTÓRICO ESCOLAR'
    // case 6:return 'VESTIBULAR ONLINE'
    this.setState({ idsAlavancasDisponiveis: [5,6,8,9,10,99] });
  }

  definirEstadoComponentePai(campo, valor) {
    this.setState({ [campo]: valor });
  }

  /*   componentDidUpdate(){  
      if(this.state.inscricaoConcluida){
        window.onbeforeunload = undefined      
      }else{
        window.onbeforeunload = () => {        
          this.setState({})
          return true
        }
      } 
  }    
     */

  buscarUnidadesPorInput(e) {
    const unidadesFiltradas = this.state.oldUnidades.filter((unidade) =>
      unidade.nome.toLowerCase().includes(e.target.value.toLowerCase())
    );
    this.setState({ unidades: this.renderizarUnidades(unidadesFiltradas) });
  }

  onChange(e) {
    if (moment.isMoment(e)) {
      const dataProva = e.format('DD/MM/YYYY');
      this.setState({
        dataProva: dataProva,
        dataProvaDatePicker: e,
        horarioProva: '',
        horariosProva: [],
      });
      this.obterHorariosProva(dataProva);
      return;
    }

    if (e.target.name === 'tipoProva') {
      if (parseInt(e.target.value, 10) === 0) {
        return;
      }
      this.setState({
        opcaoTipoProva: this.state.opcoesTiposProva.find(
          (opcaoTipoProva) => opcaoTipoProva.id === parseInt(e.target.value, 10)
        ),
        localProva: '',
        locaisProva: [],
        diasPermitidos: [],
        datasBloqueadas: [],
        dataProva: '',
        horarioProva: '',
        horariosProva: [],
      });
      this.obterLocaisProva(e.target.value);
    } else if (e.target.name === 'idLocalProva') {
      this.setState({
        localProva: this.state.locaisProva.find(
          (localProva) => localProva.id === parseInt(e.target.value, 10)
        ),
        horariosProva: [],
        horarioProva: '',
        diasPermitidos: [],
        datasBloqueadas: [],
        dataProva: '',
      });
      this.obterDatasProva(e.target.value);
    } else if (e.target.name === 'idHorarioProva') {
      this.setState({
        horarioProva: this.state.horariosProva.find(
          (horario) => horario.id === parseInt(e.target.value, 10)
        ),
      });
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  trocarEstadoPai(estado, valor) {
    this.setState({ [estado]: valor });
  }

  obterCurso(idCurso, idAlavanca, idInstituicaoMantida, idUnidadeEnsino) {
    const criterioPesquisaCurso = {
      idInstituicaoMantida: idInstituicaoMantida,
      idCurso: idCurso,
      idAlavanca: idAlavanca,
      idUnidade: idUnidadeEnsino,
    };
    ofertaService.findCourses(criterioPesquisaCurso).then((cursos) => {
      if (cursos[0]) {
        this.setState({ curso: cursos[0] });
      } else {
        this.openWarningModal(
          'Forma de ingresso não disponível para essa inscrição',
          tipoErro.alerta
        );
      }
    });
  }

  obterUnidades(
    idAlavanca,
    idUnidade,
    idCurso,
    idInstituicaoMantida,
    trocaAutomatica
  ) {
    const criterioPesquisaUnidade = {
      idInstituicaoMantida: idInstituicaoMantida,
      idAlavanca: idAlavanca,
      idUnidade: idUnidade,
      idCurso: idCurso,
    };
    return unidadeService
      .findByRM(criterioPesquisaUnidade)
      .then(async (unidades) => {
        //unidades = unidades.map(element => { element.nome = element.nome.toUpperCase();});
        for (let i = 0; i < unidades.length; i++) {
          unidades[i].nome = unidades[i].nome.toUpperCase();
         }
        if (unidades.length === 0) {
          const idsAlavancasSemUnidades = this.state.idsAlavancasSemUnidades;
          idsAlavancasSemUnidades.push(idAlavanca);
          await this.setState({ idsAlavancasSemUnidades });
          const alavancaToRequest = this.state.idsAlavancasDisponiveis.find(
            (idAlancaDisponivel) =>
              this.state.idsAlavancasSemUnidades.indexOf(idAlancaDisponivel) ===
              -1
          );
          if (alavancaToRequest && trocaAutomatica) {
            return this.trocarFormaIngresso(alavancaToRequest, true);
          } else {
            this.openWarningModal(
              'Em breve, inscrições abertas! ',
              tipoErro.alerta
            );
            this.setState({ carregando: false });
            return;
          }
        }
        const unidadeAtual = unidades.find(
          (unidade) => String(unidade.id) === String(idUnidade)
        );
        this.setState({
          idUnidadeEnsino: unidades[0].id,
          unidades: this.renderizarUnidades(unidades, idUnidade),
          unidade: unidadeAtual || unidades[0],
          arrayUnidades: unidades,
          oldUnidades: unidades,
          idUnidadeEnsinoProcessoSeletivo: unidadeAtual
            ? unidadeAtual.idUnidadeEnsinoProcessoSeletivo
            : unidades[0].idUnidadeEnsinoProcessoSeletivo,
        });

        if (this.state.idUnidadeEnsinoProcessoSeletivo === 55) {
          this.setState({ idInstituicaoMantida: 1 });
        }

        this.obterOfertas(
          idAlavanca,
          this.state.idUnidade || unidades[0].id,
          idCurso,
          idInstituicaoMantida
        );
      })
      .then((idUnidade) => {
        this.scrollParaUnidadeSelecionada();
        return idUnidade;
      });
  }

  scrollParaUnidadeSelecionada() {
    if (!this.state.arrayUnidades.length) return;

    var $ = window.$;

    var $container = $('.lista-unidades');
    var $scrollTo = this.state.idUnidade
      ? $(`#${this.state.idUnidade}`)
      : $(`#${this.state.arrayUnidades[0].id}`);
    if (!$scrollTo.offset()) return;

    $container.scrollTop(
      $scrollTo.offset().top - $container.offset().top + $container.scrollTop()
    );

    $container.animate({
      scrollTop:
        $scrollTo.offset().top -
        $container.offset().top +
        $container.scrollTop(),
    });
  }

  obterOfertas(idAlavanca, idUnidadeEnsino, idCurso, idInstituicaoMantida) {
    ofertaService
      .findModalidadesPorCurso({
        idInstituicaoMantida: idInstituicaoMantida,
        idAlavanca: idAlavanca,
        idUnidadeEnsino: idUnidadeEnsino,
        idCurso: idCurso,
      })
      .then((ofertas) => {
    
        this.setState({
          ofertas: this.renderizarOfertas(
            ofertas,
            null
          ),
          arrayOfertas: ofertas,
          carregando: false,
        });
        if (ofertas.length) {
          this.setState({
            idAlavanca: ofertas[0].alavancaId,
            idFormaIngressoProcessoSeletivo:
              ofertas[0].formaIngressoId,
          });
        }
        this.obterCurso(
          idCurso,
          idAlavanca,
          idInstituicaoMantida,
          idUnidadeEnsino
        );
      });
  }

  obterOfertaProcessoSeletivo(idProcessoSeletivo) {
    ofertaProcessoSeletivoService
      .findByEntity({
        idProcessoSeletivo: idProcessoSeletivo,
        idUnidadeEnsinoProcessoSeletivo:
          this.state.idUnidadeEnsinoProcessoSeletivo,
        idCursoProcessoSeletivo: this.state.curso.idCursoProcessoSeletivo,
        idTurnoProcessoSeletivo: this.state.turno.idTurnoProcessoSeletivo,
        idFormaIngressoProcessoSeletivo:
          this.state.idFormaIngressoProcessoSeletivo,
        idModalidadeEnsinoProcessoSeletivo: this.state.idModalidadeEnsino,
        idInstituicaoMantida: this.state.idInstituicaoMantida,
      })
      .then((oferta) => this.setState({ oferta: oferta[0] }));
  }

  obterTipoProva(
    idModalidadeEnsino,
    idTurnoProcessoSeletivo,
    idFormaIngressoProcessoSeletivo
  ) {
    let idMantida;
    if (this.state.idUnidadeEnsinoProcessoSeletivo === 55) {
      this.setState({ idInstituicaoMantida: 1 });
      idMantida = 1;
    } else {
      idMantida = this.state.idInstituicaoMantida;
    }
    const pesquisaTipoProva = {
      idInstituicaoMantida: idMantida,
      idModalidadeEnsinoProcessoSeletivo: idModalidadeEnsino,
      idFormaIngressoProcessoSeletivo: idFormaIngressoProcessoSeletivo,
      idTurnoProcessoSeletivo: idTurnoProcessoSeletivo,
      idUnidadeEnsinoProcessoSeletivo:
        this.state.idUnidadeEnsinoProcessoSeletivo,
      idCursoProcessoSeletivo: this.state.curso.idCursoProcessoSeletivo,
    };
    opcaoProvaService
      .findByEntity(pesquisaTipoProva)
      .then((opcoesTiposProva) => {
        if (opcoesTiposProva.length === 0 || !opcoesTiposProva) {
          this.openWarningModal(
            'Não existe processo seletivo aberto',
            tipoErro.alerta
          );
          this.editarProgresso(null, 1);
          return;
        }
        if (idFormaIngressoProcessoSeletivo !== 1) {
          this.setState({
            opcaoTipoProva: opcoesTiposProva[0],
            tipoProva: opcoesTiposProva[0].id,
          });
          this.obterOfertaProcessoSeletivo(opcoesTiposProva[0].id);
        }
        this.setState({ opcoesTiposProva: opcoesTiposProva });
      })
      .catch(() => {
        this.openWarningModal(null, tipoErro.erro, true);
        this.editarProgresso(null, 1);
      });
  }

  obterLocaisProva(idProcessoSeletivo) {
    const pesquisaLocaisProva = {
      idInstituicaoMantida: this.state.idInstituicaoMantida,
      idProcessoSeletivo: idProcessoSeletivo,
    };
    localProvaService.findByEntity(pesquisaLocaisProva).then((locaisProva) => {
      if (locaisProva.length === 0) {
        this.openWarningModal(
          'Não existem locais de prova disponíveis para essa inscrição',
          tipoErro.alerta
        );
      }
      this.setState({ locaisProva: locaisProva });
      this.obterOfertaProcessoSeletivo(idProcessoSeletivo);
    });
  }

  obterDatasProva(idLocalProva) {
    const pesquisaDiaProva = {
      idLocalProva: idLocalProva,
      idProcessoSeletivo: this.state.tipoProva,
    };
    diaProvaService.findByEntity(pesquisaDiaProva).then((datasProva) => {
      this.setState({
        datasProva: datasProva,
        desabilitarDatePicker: false,
        datasBloqueadas: datasProva.datasBloqueadas.map((dataBloqueada) =>
          moment(dataBloqueada, 'DD/MM/YYYY')
        ),
        diasPermitidos: datasProva.diasPermitidos,
        dataMin: moment(datasProva.dataMin, 'DD/MM/YYYY'),
        dataMax: moment(datasProva.dataMax, 'DD/MM/YYYY'),
      });
    });
  }

  verificaMantida() {
    if (this.props.location.pathname.includes('fatenp')) {
      return 2;
    } else {
      return 1;
    }
  }

  obterHorariosProva(dataProva) {
    const pesquisaHorarioProva = {
      dataProva: dataProva,
      idLocalProva: this.state.localProva.id,
      idProcessoSeletivo: this.state.tipoProva,
    };
    horarioProvaService
      .findByEntity(pesquisaHorarioProva)
      .then((horariosProva) => {
        const dataAtual = new Date().getTime();
        let horariosProvaTratado = [];
        let horaAgendamento;
        horariosProvaTratado = horariosProva.filter((el) => {
          horariosProvaTratado = new Date();
          horaAgendamento = new Date();
          horaAgendamento.setHours(parseInt(el.agendamento.substr(0, 2), 10));
          return (
            dataAtual < horaAgendamento.getTime() ||
            new Date().getDate() !== parseInt(dataProva.substr(0, 2), 10)
          );
        });
        this.setState({ horariosProva: horariosProvaTratado });
      });
  }

  renderizarOfertas(ofertas, elAtual, idTurno) {
    return ofertas.map((el, i) => {
      return (
        <CardModalidade
          {...this.props}
          modalidadeAtual={el}
          key={i}
          elAtual={elAtual}
          idTurno={idTurno}
          {...this.state}
          selecionarVestibular={this.selecionarVestibular}
        />
      );
    });
  }

  trocarUnidade(modalidade) {
    this.setState({
      idUnidadeEnsinoProcessoSeletivo:
        modalidade.idUnidadeEnsinoProcessoSeletivo,
    });
  }

  renderizarUnidades(unidades, idUnidade) {
    return unidades.map((modalidade, i) => (
      <li
        onClick={() => this.trocarUnidade(modalidade)}
        className="units-item"
        key={i}
      >
        <a
          className={
            idUnidade
              ? parseInt(modalidade.id) === parseInt(idUnidade, 10)
                ? 'units-link active'
                : 'units-link'
              : i === 0
              ? 'units-link active'
              : 'units-link'
          }
          id={modalidade.id}
          onClick={(e) => this.selecionarUnidade(e, modalidade)}
        >
          <i className="fa fa-angle-right"></i>
          {modalidade.nome}
        </a>
      </li>
    ));
  }

  renderizarFormDados() {
    return (
      <FormDados
        {...this.props}
        {...this.state}
        {...(this.props.location.state ? this.props.location.state.lead : null)}
        onChange={this.onChange}
        editarProgresso={this.editarProgresso}
        obterTipoProva={this.obterTipoProva}
        definirEstadoComponentePai={this.definirEstadoComponentePai}
        openWarningModal={this.openWarningModal}
      />
    );
  }

  renderizarFormTransferenciaReingresso() {
    return <div>{this.escolherFluxoFormTransferenciaReingresso()} </div>;
  }

  renderizarAgendamento() {
    return (
      <Agendamento
        {...this.props}
        {...this.state}
        onChange={this.onChange}
        definirEstadoComponentePai={this.definirEstadoComponentePai}
        editarProgresso={this.editarProgresso}
        openWarningModal={this.openWarningModal}
      />
    );
  }
  renderizarTimeline() {
    switch (this.state.idAlavanca) {
      case 1:
        return (
          <TimelineVestibular
            {...this.state}
            {...this.props}
            editarProgresso={this.editarProgresso}
          />
        );
      case 2:
        return (
          <TimelineEnem
            {...this.state}
            {...this.props}
            editarProgresso={this.editarProgresso}
          />
        );
      case 3:
      case 4:
        return (
          <TimelineTransferenciaReingresso
            {...this.state}
            {...this.props}
            editarProgresso={this.editarProgresso}
          />
        );
      case 15:
      case 16:
        return (
          <TimelineEnem
            {...this.state}
            {...this.props}
            editarProgresso={this.editarProgresso}
          />
        );
      default:
        return null;
    }
  }

  renderizarDivEscolhaCursoUnidade() {
    return (
      <div id="unidades" className="box_fade">
        <div className="row">
          <div className="col-sm-3 col-mod-12">
            <div className="units mb-lg-0 ">
              <h1 className="forma-ingresso center mb-2">Unidade</h1>
              <input
                placeholder="Pesquisar"
                className="pesquisar"
                onChange={this.buscarUnidadesPorInput}
              />
              <ul className="units-list lista-unidades" id="listaUnidades">
                {this.state.unidades}
              </ul>
            </div>
          </div>

          <div className="col-lg-9 col-md-12 col-sm-12" id="col-mod-total">
            <div className="row" id="cards-pb">
              {this.state.carregando ? <Carregando /> : this.state.ofertas}
            </div>
          </div>
        </div>
        {/* <div style={{textAlign: 'center'}}>
                <input type="checkbox" id="termo" name="termo"/>
                <label for="termo" style={{paddingLeft: '10px'}}> Li e aceito os termos das ofertas</label>
        </div> */}
        {this.state.idInstituicaoMantida === 1 ? (
          <div
            className="mb-2"
            style={{ textAlign: 'center', position: 'relative', left: '8px;' }}
          >
            <a
              href="https://portal.unigranrio.edu.br/promocoes"
              target="_blank"
              className="texto-link"
            >
              * Valor sobre o qual é aplicado o desconto (exceto Medicina)
            </a>
            {/*  <p className="valorDesconto">**O valor da mensalidade 2021.2 pode ser reajustado em 2022.1 </p> */}
          </div>
        ) : (
          <div
            className="mb-2"
            style={{ textAlign: 'center', position: 'relative', left: '8px;' }}
          >
            <a
              href="https://portal.unigranrio.edu.br/promocoes-fatenp"
              target="_blank"
              className="texto-observacao"
            >
              {' '}
              ** Confira aqui todas as nossas promoções válidas
            </a>
          </div>
        )}

        {this.state.idAlavanca === 1 ||
        this.state.idAlavanca === 2 ||
        this.state.idAlavanca === 15 ||
        this.state.idAlavanca === 16 ? (
          <div
            className="mb-2"
            style={{ textAlign: 'center', position: 'relative', left: '8px;' }}
          >
            <a
              href="https://portal.unigranrio.edu.br/promocoes"
              target="_blank"
              className="texto-observacao"
            >
              {' '}
              ** Clique aqui para conferir as promoções e os regulamentos.
            </a>
          </div>
        ) : null}
      </div>
    );
  }

  escolherFluxoFormTransferenciaReingresso() {
    switch (this.state.passo) {
      case 1:
        return (
          <FormDados
            {...this.props}
            {...this.state}
            {...(this.props.location.state
              ? this.props.location.state.lead
              : null)}
            onChange={this.onChange}
            editarProgresso={this.editarProgresso}
            obterTipoProva={this.obterTipoProva}
            definirEstadoComponentePai={this.definirEstadoComponentePai}
            openWarningModal={this.openWarningModal}
          />
        );
      case 2:
        return (
          <FormInfoEscolaridade
            {...this.props}
            {...this.state}
            {...(this.props.location.state
              ? this.props.location.state.lead
              : null)}
            onChange={this.onChange}
            editarProgresso={this.editarProgresso}
            obterTipoProva={this.obterTipoProva}
            definirEstadoComponentePai={this.definirEstadoComponentePai}
            openWarningModal={this.openWarningModal}
          />
        );
      default:
        return null;
    }
  }

  selecionarUnidade(e, unidade) {
    this.setState({ carregando: true, unidade: unidade });
    e.preventDefault();
    const idUnidade = parseInt(e.target.id, 10);
    this.setState({
      idUnidade: idUnidade,
      unidades: this.renderizarUnidades(this.state.arrayUnidades, idUnidade),
    });
    this.obterOfertas(this.state.idAlavanca, idUnidade, this.state.idCurso);
  }

  selecionarVestibular(
    modadalidadeAtualAlavanca,
    modalidade,
    state,
    idFormaIngressoProcessoSeletivo
  ) {
    // Verifica qual a alavanca para gerar o fluxo
    let passo;
    switch (modadalidadeAtualAlavanca) {
      case 1:
        passo = 2; // Fluxo Vestibular
        break;
      case 2:
        passo = 1; //Fluxo enem
        break;
      case 3:
        passo = 1; // Fluxo Transferência
        break;
      case 4:
        passo = 1; // Fluxo Reingresso
        break;
      case 15:
        passo = 1; // Histórico Escolar
        break;
      case 16:
        passo = 1; // Vestibular Online
        break;
      default:
        return null;
    }
    const idModalidadeEnsino = parseInt(
      state.modalidade.idModalidadeEnsinoProcessoSeletivo,
      10
    );
    this.setState(
      {
        escolheuModalidade: true,
        escolheuOferta: true,
        passo: passo,
        modalidade: state.modalidade,
        oferta: state.oferta,
        turno: state.turno,
        idFormaIngressoProcessoSeletivo: idFormaIngressoProcessoSeletivo,
        idTurno: parseInt(state.turno.turnoId, 10),
        idModalidadeEnsino: idModalidadeEnsino,
      },
      () => {
        if (this.state.lead.idLead) {
          const properties = [
            ...contatoHubspotService.montarObjectMsql(),
            ...contatoHubspotService.montarObjectInformacoesOferta(this.state),
          ];
          contatoHubspotService.createOrUpdate(
            properties,
            this.state.lead.email
          );
          // Não será necessário após o merge com a branch de transferência e reingresso
          this.setState({ enviouInformacoesOfertaToHubspot: true });
        }
      }
    );
    this.obterTipoProva(
      idModalidadeEnsino,
      state.turno.idTurnoProcessoSeletivo,
      idFormaIngressoProcessoSeletivo
    );
  }

  editarProgresso(e, passo, state) {
    if (e) e.preventDefault();
    if (passo === 1) {
      this.setState({ escolheuModalidade: false, preencheuFormulario: false });
    } else if (passo === 2) {
      this.setState({ preencheuFormulario: false });
    } else if (
      passo === 7 &&
      (this.state.idAlavanca === 3 || this.state.idAlavanca === 4)
    ) {
      this.setState({ inscricaoConcluida: true });
    }

    if (state) {
      if (state.idCandidato) {
        this.setState({
          idCandidato: state.idCandidato,
          idTitulo: state.idTitulo,
          idRegistroTitulo: state.idRegistroTitulo,
          formDados: state,
        });
      }
    }
    if (passo === 1) {
      this.setState({ escolheuOferta: false });
    }
    this.setState({ passo: passo });
  }

  renderizarTelaFinal() {
    return <h1>OBRIGADO POR SE INSCREVER!</h1>;
  }

  trocarFormaIngresso(idAlavanca, trocaAutomatica) {
    this.setState(
      {
        idFormaIngressoProcessoSeletivo: idAlavanca,
        idAlavanca: idAlavanca,
        ofertas: [],
        arrayOfertas: [],
        unidades: [],
        arrayUnidades: [],
        passo: 1,
        escolheuModalidade: false,
        preencheuFormulario: false,
        escolheuOferta: false,
        carregando: true,
      },
      () => {
        this.obterUnidades(
          idAlavanca,
          this.state.idUnidade,
          this.state.idCurso,
          this.state.idInstituicaoMantida,
          trocaAutomatica
        );
        //.then((idUnidadeEnsino) => {
        //this.obterOfertas(idAlavanca, idUnidadeEnsino || this.state.idUnidade, this.state.idCurso, this.state.idInstituicaoMantida)
        //this.obterCurso(this.state.idCurso, idAlavanca, this.state.idInstituicaoMantida);
        //});
      }
    );
  }

  closeWarningModal() {
    const warning = this.state.warning;
    warning.show = false;
    this.setState({ warning: warning });
  }

  openWarningModal(message, tipoErro, defaultError) {
    const warning = {
      show: true,
      message: message,
      tipoErro: tipoErro,
    };
    if (defaultError) {
      warning.message = fraseGenericaErro;
      warning.tipoErro = 2;
    }
    this.setState({ warning: warning });
  }
  //Se isso ficar mudando, criar uma coluna na tabela e deixar dinâmico
  getLinkDetalhesCurso() {
    if (this.state.idInstituicaoMantida === 1) {
      let url = 'https://portal.unigranrio.edu.br/';

      switch (this.props.match.params.idCurso) {
        case '12':
          url += 'administracao';
          break;
        case '36':
          url += 'analise-e-desenvolvimento-de-sistemas';
          break;
        case '41':
          url += 'arquitetura-e-urbanismo';
          break;
        case '42':
          url += 'biomedicina';
          break;
        case '28':
          url += 'ciencias-biologicas';
          break;
        case '13':
          url += 'ciencias-contabeis';
          break;
        case '102':
          url += 'ciencias-economicas';
          break;
        case '17':
          url += 'tecnologia-em-moda';
          break;
        case '11':
          url += 'tecnologia-em-design-grafico';
          break;
        case '14':
          url += 'direito';
          break;
        case '39':
          url += 'educacao-fisica';
          break;
        case '26':
          url += 'enfermagem';
          break;
        case '40':
          url += 'engenharia-civil';
          break;
        case '37':
          url += 'engenharia-de-producao';
          break;
        case '38':
          url += 'engenharia-quimica';
          break;
        case '1':
          url += 'tecnologia-em-estetica-e-cosmetica';
          break;
        case '27':
          url += 'farmacia';
          break;
        case '31':
          url += 'fisioterapia';
          break;
        case '78':
          url += 'fisica';
          break;
        case '7':
          url += 'tecnologia-em-gestao-ambiental';
          break;
        case '76':
          url += 'gestão-comercial';
          break;
        case '5':
          url += 'gestao-de-recursos-humanos';
          break;
        case '10':
          url += 'tecnologia-em-gestao-financeira';
          break;
        case '16':
          url += 'historia';
          break;
        case '19':
          url += 'jornalismo';
          break;
        case '77':
          url += 'letras-portugues';
          break;
        case '21':
          url += 'letras-portugues-e-ingles';
          break;
        case '6':
          url += 'tecnologia-em-logistica';
          break;
        case '4':
          url += 'tecnolgia-em-marketing';
          break;
        case '33':
          url += 'matematica';
          break;
        case '106':
          url += 'medicina';
          break;
        case '29':
          url += 'medicina-veterinaria';
          break;
        case '32':
          url += 'nutricao';
          break;
        case '25':
          url += 'odontologia';
          break;
        case '20':
          url += 'pedagogia';
          break;
        case '9':
          url += 'tecnologia-em-processos-gerenciais';
          break;
        case '23':
          url += 'psicologia';
          break;
        case '18':
          url += 'publicidade-e-propaganda';
          break;
        case '34':
          url += 'quimica';
          break;
        case '8':
          url += 'tecnologia-em-radiologia';
          break;
        case '3':
          url += 'redes-de-computadores';
          break;
        case '15':
          url += 'servico-social';
          break;
        case '35':
          url += 'sistemas-de-informacao';
          break;
        case '94':
          url += 'sistemas-para-internet';
          break;
        case '22':
          url += 'teologia';
          break;
        default:
          url += '';
      }

      return url;
    } else {
      return 'https://portal.unigranrio.edu.br/fatenp';
    }
  }

  render() {
    return (
      <Container {...this.props}>
        {
          <section className="main">
            <Warnings
              {...this.state.warning}
              closeWarningModal={this.closeWarningModal}
            />
            {/* verificação abaixo necessária para caso o candidato mudar a forma de ingresso, remontar o
            componente com a alavanca correta */}
            {this.state.escolheuModalidade ? (
              <ModalPreviewDados
                {...this.props}
                {...this.state}
                editarProgresso={this.editarProgresso}
                definirEstadoComponentePai={this.definirEstadoComponentePai}
              />
            ) : null}
            {/* <div id="pelicula"></div> */}
            <section
              className="bg-curso"
              style={{
                // backgroundImage: `url('${pathImages}')`,
                background: `#08437f`,
	background: `-moz-linear-gradient(45deg,  #08437f 0%, #0b4380 32%, #0b4380 32%, #0b4380 	33%, #2ba8e0 100%)`,
	background: `-webkit-linear-gradient(45deg,  #08437f 0%,#0b4380 32%,#0b4380 32%,#0b4380 	33%,#2ba8e0 100%)`, 
	background: `linear-gradient(45deg,  #08437f 0%,#0b4380 32%,#0b4380 32%,#0b4380 	33%,#2ba8e0 100%)`,
	filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#08437f', 	endColorstr='#2ba8e0',GradientType=1 )`, /* IE6-9 fallback on horizontal gradient */
              }}
            >
              {' '}
              {/*hero hero-course*/}
              <div className="row">
                <div className="col-xs-12">
                  <h1 className="name-course title_course">
                    {this.state.curso.nome}
                  </h1>
                </div>
              </div>
            </section>
            {Object.keys(this.state.curso).length > 0 ? (
              <div className="about_course">
                <div className="container-fluid-courses">
                  <div className="row">
                    <div className="col-sm-9 col-mod-12">
                      <div className="about_course fonte-texto">
                        <div className="row">
                          <div className="col-sm-9">
                            {/* <h2 className="mb-2">Apresentação do Curso</h2> */}
                            <p className="descricao">
                              {this.state.curso.objetivo}
                              {/* {this.state.curso.nome === 'Medicina' ? (
                                <p>Quer se tornar um futuro médico?</p>
                              ) : (
                                <p>Quer saber mais?</p>
                              )}
                              <span>Inscreva-se </span>
                              <a
                                href={this.getLinkDetalhesCurso()}
                                target="_blank"
                                style={{
                                  color: '#085fa3',
                                  fontSize: '16px',
                                  textDecoration: 'underline',
                                }}
                              >
                                aqui
                              </a>
                              ! */}
                            </p>
                          </div>
                          <div className="col-sm-3 col-mod-12">
                            <CardLateralCurso {...this.props} {...this.state} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 col-mod-12">
                      {this.state.curso.urlVideo ? (
                        <div className="video">
                          <iframe
                            title="Video"
                            src={
                              this.state.curso.urlVideo.replace(
                                'watch?v=',
                                'embed/'
                              ) + '?autoplay=1'
                            }
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                      ) : null}

                      {this.state.curso.urlVideo2 ? (
                        <div className="video">
                          <iframe
                            title="Video"
                            src={
                              this.state.curso.urlVideo2.replace(
                                'watch?v=',
                                'embed/'
                              ) + '?autoplay=1'
                            }
                            frameBorder="0"
                            allowFullScreen
                          ></iframe>
                        </div>
                      ) : null}
                      {/* Chamado 82102 */}
                      {/* {this.state.idCurso != 106 ? (
                        <a
                          href={`https://unigranrio.edu.br/_hotsite/_pdf/${this.props.match.params.idCurso}.pdf`}
                          target="_blank"
                          className="matriz-curricular"
                          style={{ display: 'block', margin: '0 auto' }}
                        >
                          Visualizar Matriz Curricular
                        </a>
                      ) : null} */}
                    </div>
                  </div>

                  <div className="global">
                    <div className="col-sm-9 informacoes">
                      <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.informacaoMec ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">
                                  + Informações do MEC
                                </h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            <p className="descricao descricao-text">
                              {this.state.curso.informacaoMec}
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>

                    <div className="col-sm-9 informacoes">
                      {/* <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.objetivo ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">+ Objetivo</h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            <p className="descricao descricao-text">
                              {this.state.curso.objetivo}
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion> */}
                    </div>

                    <div className="col-sm-9 informacoes">
                      <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.infraestrutura ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">
                                  + Infraestrutura
                                </h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            <p className="descricao descricao-text">
                              {this.state.curso.infraestrutura}
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>

                    <div className="col-sm-9 informacoes">
                      <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.perfilEgresso ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">
                                  + Perfil de Egresso
                                </h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            <p className="descricao descricao-text">
                              {this.state.curso.perfilEgresso}
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>

                    <div className="col-sm-9 informacoes">
                      <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.mercadoTrabalho ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">
                                  + Mercado de Trabalho
                                </h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            <p className="descricao descricao-text">
                              {this.state.curso.mercadoTrabalho}
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>

                    <div className="col-sm-9 informacoes">
                      <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.corpoDocente ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">+ Corpo Docente</h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            <p className="descricao descricao-text">
                              {this.state.curso.corpoDocente}
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>

                    <div className="col-sm-9 informacoes">
                      <Accordion allowZeroExpanded={true}>
                        <AccordionItem>
                          {this.state.curso.preRequisitos ? (
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                <h3 className="title-curso">
                                  + Pré Requisitos
                                </h3>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                          ) : null}
                          <AccordionItemPanel>
                            {this.state.curso.preRequisitos ? (
                              <p className="descricao descricao-text">
                                {this.state.curso.preRequisitos}
                              </p>
                            ) : null}
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12 col-mod-12">
                      {/* TIMELINE */}
                      {this.renderizarTimeline()}
                      {this.state.escolheuOferta ? null : (
                        <EscolhaFormaIngresso
                          {...this.state}
                          {...this.props}
                          trocarFormaIngresso={this.trocarFormaIngresso}
                        />
                      )}

                      {this.state.passo === 1 &&
                      !this.state.escolheuModalidade ? (
                        this.renderizarDivEscolhaCursoUnidade()
                      ) : this.state.isEnviandoDadosCandidato ? (
                        <Carregando message="Enviando dados" />
                      ) : (this.state.idAlavanca === 1 ||
                          this.state.idAlavanca === 2 ||
                          this.state.idAlavanca === 15 ||
                          this.state.idAlavanca === 16) &&
                        (this.state.passo === 1 || this.state.passo === 2) ? (
                        this.renderizarFormDados()
                      ) : (this.state.idAlavanca === 3 ||
                          this.state.idAlavanca === 4) &&
                        (this.state.passo === 1 || this.state.passo === 2) ? (
                        this.renderizarFormTransferenciaReingresso()
                      ) : this.state.passo === 3 &&
                        this.state.idAlavanca !== 3 &&
                        this.state.idAlavanca !== 2 ? (
                        this.renderizarAgendamento()
                      ) : this.state.passo === 4 ? (
                        <SucessoInscricao
                          openWarningModal={this.openWarningModal}
                          {...this.props}
                          {...this.state}
                          definirEstadoComponentePai={
                            this.definirEstadoComponentePai
                          }
                          editarProgresso={this.editarProgresso}
                        />
                      ) : null}

                      {this.state.passo === 5 &&
                      (this.state.idAlavanca === 3 ||
                        this.state.idAlavanca === 4) ? (
                        <SucessoInscricao
                          openWarningModal={this.openWarningModal}
                          {...this.props}
                          {...this.state}
                          definirEstadoComponentePai={
                            this.definirEstadoComponentePai
                          }
                          editarProgresso={this.editarProgresso}
                        />
                      ) : null}
                      {/* FIM TIMELINE */}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </section>
        }
      </Container>
    );
  }
}

// const styles = {
//   displayNone: {
//     display: "none"
//   },
//   cemPorcento: {
//     width: "100%"
//   },
//   pdbd: {
//     padding: "8px 40px",
//     borderRadius: "5px"
//   },
//   invisible: {
//     visibility: "hidden"
//   },
//   bgs: {
//     width: "100%",
//     height: "auto",
//     paddingBottom: "0px",
//     paddingTop: "0px",
//     maxHeight: "210px"
//   }
// }