import React, { Component } from 'react';
import Container from '../../components/Container';
import MaskedInput from 'react-text-mask'
import validadores from '../../utils/validadores';
import Warnings from '../../components/utils/Warnings';
import { tipoErro } from '../../components/utils/Warnings';

import { 
  // apiBoleto,
   idFormaPagamentoBoleto, idFormaPagamentoCartao, fraseGenericaErro } from '../../config';
import candidatoTituloService from '../../services/processo-seletivo/candidato-titulo-service'
import registroTituloService from '../../services/financeiro/registro-titulo-service'
import utilsApiBoleto from '../../utils/utils-api-boleto'
import candidatoService from '../../services/processo-seletivo/candidato-service'
import DadosCandidatoBuscarInscricao from '../../components/candidato/DadosCandidatoBuscarInscricao';
import ReactToPrint from "react-to-print";
import * as html2canvas from 'html2canvas'
import * as jsPDF from 'jspdf'
import FluxoTransferenciaReingresso from '../../components/fluxo-transferencia-reingresso/FluxoTransferenciaReingresso';


import CardDadosDisciplinasCursadas from '../../components/fluxo-transferencia-reingresso/CardDadosDisciplinasCursadas';
import candidatoDisciplinaExternaPeriodoLetivoService from '../../services/processo-seletivo/candidato-disciplina-externa-periodo-letivo-service';
import { codigosAndamento } from '../../utils/constants';

export default class FormBuscaInscricaoCandidato extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warning: {
        show: false,
        message: '',
        tipoErro: '',        
      },
      dadosDisciplinasCursadas:[],
      //  Em caso de falha na conexão ou algo do tipo, 
      // para garantir que o botão de pré matricula só seja exibido se o candidato não está pré matriculado
      candidatoJaEstaPreMatriculado:true, 
      candidato: '',
      inscricao: '',
      cpf: '',
      dataNascimento: '',
      showComprovante: false,
      showFormTransRe: false,
      solicitacaoServico:{}
    };

    this.buscarInscricao = this.buscarInscricao.bind(this);
    this.buscarCandidatoTitulo = this.buscarCandidatoTitulo.bind(this);
    this.onChange = this.onChange.bind(this);
    this.pagarComBoleto = this.pagarComBoleto.bind(this);
    this.pagarComCartao = this.pagarComCartao.bind(this);
    this.closeWarningModal = this.closeWarningModal.bind(this);
    this.openWarningModal = this.openWarningModal.bind(this);
    this.openSiteInscricaoAluno = this.openSiteInscricaoAluno.bind(this);
    this.renderizarReactToPrint = this.renderizarReactToPrint.bind(this); 
    this.carregarDisciplinasCadastradas = this.carregarDisciplinasCadastradas.bind(this)   
    this.carregarAndamentoAtualCandidato = this.carregarAndamentoAtualCandidato.bind(this)
    this.enviarEmailConfirmacao = this.enviarEmailConfirmacao.bind(this)
  }

  async componentDidMount() {    
    utilsApiBoleto.carregarDownloadAPI();           
  }
  carregarDisciplinasCadastradas(idCandidato,chamouEmFinalizarProcesso){
    candidatoDisciplinaExternaPeriodoLetivoService
    .carregarDisciplinasCadastradas(idCandidato)
    .then(previewDadosSalvos => {      
      this.setState({dadosDisciplinasCursadas:previewDadosSalvos})
      if(chamouEmFinalizarProcesso){
        this.setState({showFormTransRe:false})
      }
      
    })
  }

  async verificarSeCandidatoJaEstaPreMatriculado(idCandidato){
     const candidato = await candidatoService.verificarSeCandidatoJaEstaPreMatriculado(idCandidato)
     if(candidato.id === 0){
       await this.setState({candidatoJaEstaPreMatriculado:false})       
     }else{
      await this.setState({candidatoJaEstaPreMatriculado:true})
      this.openWarningModal('Esse Candidato já está pré matriculado', tipoErro.alerta)
     }
  }



  componentWillMount(){
    this.setState({
      inscricao: parseInt(this.props.match.params.idCandidato, 10)
      , cpf: this.props.match.params.cpf
      , dataNascimento: this.props.match.params.dataNascimento
    }, () => {
      if(this.state.cpf && this.state.inscricao && this.state.cpf){
        const dadosCandidato = {
          id:this.state.inscricao,
          dataNascimento:this.state.dataNascimento,
          cpf:this.state.cpf
        }
        candidatoService.findOneByIdAndDateNascimentoAndCpf(dadosCandidato)
        .then(candidato =>{
          this.handleGetCandidato(candidato);                    
        })
      }
    });
  }

  buscarInscricao(e, finalizouInscricao) {
    if (e) {
      e.preventDefault();
    }
    if (!validadores.validarCPF(this.removerMascaras(this.state.cpf))) {
      this.openWarningModal('CPF Inválido', tipoErro.alerta)
      return;
    }
    const dadosCandidato = {
      id: this.state.inscricao,
      dataNascimento: this.state.dataNascimento,
      cpf: this.state.cpf
    }
    return candidatoService.findOneByIdAndDateNascimentoAndCpf(dadosCandidato)
      .then(candidato => {
        this.handleGetCandidato(candidato, finalizouInscricao);
      });
  }

  async carregarAndamentoAtualCandidato(idCandidato){    
    await this.setState({solicitacaoServico:await candidatoService.getAndamentoAtual(idCandidato)})
  }

  async handleGetCandidato(candidato, finalizouInscricao) {
    if (!candidato) {
      this.openWarningModal('Inscrição não encontrada', tipoErro.alerta)
      return
    }
    this.setState({ candidato: candidato })    
    this.carregarDisciplinasCadastradas(candidato.id)    
    await this.carregarAndamentoAtualCandidato(candidato.id)
    if ((candidato.idFormaIngresso === 3 || candidato.idFormaIngresso === 4) 
          && 
          [codigosAndamento["Encaminhado para Registro de Disciplinas"]
          , codigosAndamento["Em Registro de Disciplinas"]
          , codigosAndamento["Aguardando Entrega da Documentação"]
          , codigosAndamento["Encaminhado para Verificação da Documentação"]
          , codigosAndamento["Em Verificação da Documentação"]].indexOf(this.state.solicitacaoServico.codigoAndamento) > -1) {  
            
        if(candidato.nomeSituacaoCandidato !== 'Inscrito') {
          this.setState({ showComprovante: true });
        }
        
        return this.setState({ showFormTransRe: (finalizouInscricao ? false : true) });
    }

    return this.setState({ showComprovante: true });
  }

  onChange(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }
  removerMascaras(string) {
    return string.replace(/[^\d]+/g, '');
  }

  pagarComBoleto() {
    this.buscarCandidatoTitulo(idFormaPagamentoBoleto);
  }

  pagarComCartao() {
    this.buscarCandidatoTitulo(idFormaPagamentoCartao);
  }

  buscarCandidatoTitulo(idFormaPagamento) {
    const dadosCandidatoTitulo = {
      id: 0,
      idCandidato: this.state.candidato.id,
      idTitulo: 0,
    }
    candidatoTituloService.findByEntity(dadosCandidatoTitulo)
      .then(candidatoTitulo => {
        this.registrarTitulo(idFormaPagamento, candidatoTitulo.idTitulo);
      });
  }

  registrarTitulo(idFormaPagamento, idTitulo) {
    if (this.state.idRegistroTituloBoleto) {
      if (idFormaPagamento === idFormaPagamentoBoleto) {
        utilsApiBoleto.openPageBoleto(idFormaPagamentoBoleto);
      }
    }
    const titulo = {
      idFormaPagamento: idFormaPagamento,
      idTitulo: idTitulo,
    }
    registroTituloService.insert(titulo)
      .then(returnParamsProcedure => {
        if (idFormaPagamento === idFormaPagamentoBoleto && !this.state.idRegistroTituloBoleto) {

          this.setState({
            idRegistroTituloBoleto: returnParamsProcedure.idRegistroTitulo,
            idRegistroTitulo: returnParamsProcedure.idRegistroTitulo,
          }, () => {
            utilsApiBoleto.openPageBoleto(this.state.idRegistroTituloBoleto)
          });

        }

        this.setState({
          idRegistroTitulo: returnParamsProcedure.idRegistroTitulo,
          idRegistroTituloCartao: returnParamsProcedure.idRegistroTituloCartao,
        });
      });
  }

  closeWarningModal() {
    const warning = this.state.warning
    warning.show = false
    this.setState({ warning: warning })
  }

  openWarningModal(message, tipoErro, defaultError) {
    const warning = {
      show: true,
      message: message,
      tipoErro: tipoErro
    }
    if (defaultError) {
      warning.message = fraseGenericaErro
      warning.tipoErro = 2
    }
    this.setState({ warning: warning })
  }

  openSiteInscricaoAluno() {
    window.location.href = `http://inscricao.${this.state.candidato.idInstituicaoMantida === 1 ? 'unigranrio' : 'fatenp'}.com.br/processoSeletivo/paginas/publicas/vestibular/paginaInformacoesEtapasMatricula.jsf`
  }

  printDocument() {
    const input = document.getElementById('dateToPrint');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', -42, 20);
        // pdf.output('dataurlnewwindow');
        pdf.save("comprovante-inscricao.pdf");
      })
      ;
  }

  enviarEmailConfirmacao() {
    if(!this.state.candidato) {
      this.openWarningModal('Inscrição não encontrada', tipoErro.alerta)
      return
    }
    
    candidatoService.enviarEmailConfirmacao(this.state.candidato)
    .then(()=>{
      this.openWarningModal('A confirmação de inscrição foi enviada novamente para o seu e-mail.', '')
    })
  }

  renderizarReactToPrint() {
    return (
      <div className="imprimir-salvar-comprovante">
        <ReactToPrint
          trigger={() =>
            <button className="imprimir-comprovante">Imprimir comprovante de inscrição</button>}
          content={() => this.componentRef}
        />
        <button onClick={this.printDocument} className="salvar-pdf">Salvar comprovante como PDF</button>
        <button onClick={this.enviarEmailConfirmacao} className="imprimir-comprovante">Reenviar e-mail de confirmação</button>
      </div>
    )
  }

  setShowComprovante = (showComprovante) => {
    this.setState({ showComprovante });
  }

  render() {
    return (
      <Container  {...this.props}>
        <section className="container" onKeyUp={(e) =>
          e.keyCode === 27 ? this.closeWarningModal() : null} onClick={() => this.closeWarningModal()}>
          <Warnings  {... this.state.warning} closeWarningModal={this.closeWarningModal} />
          {this.state.showFormTransRe || this.state.showComprovante ? null :
            <form className="box_fade form-buscar-candidato" onSubmit={(e) =>
              this.buscarInscricao(e)}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="inscricao">Número de Inscrição</label>
                    <input className="form-control required"
                      required id="inscricao" name="inscricao"
                      type="number" min="1" value={this.state.inscricao} max="9999999999" onChange={this.onChange} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="cpf">CPF</label>
                    <MaskedInput
                      pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
                      mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                      className="form-control required" required
                      id="cpf" name="cpf"
                      value={this.state.cpf}
                      onChange={this.onChange} type="text"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label className="control-label" htmlFor="dataNascimento">Data de nascimento</label>
                    <input className="form-control required"
                      required id="dataNascimento"
                      name="dataNascimento" type="date"
                      min="1900-01-01"
                      max={`${new Date().getFullYear()}-01-01`}
                      value={this.state.dataNascimento}
                      onChange={this.onChange} />
                  </div>
                </div>
              </div>
              <br />
              <button id="btn-buscar-candidato" className="btn btn-primary bt-confirmar" >Buscar</button>
            </form>
          }

          {this.state.showComprovante ?
            <div>
              <div id="dateToPrint" >                
                <DadosCandidatoBuscarInscricao  {... this.props} {... this.state} ref={el => (this.componentRef = el)} />
                </div>
                {this.renderizarReactToPrint()}       
                {this.state.dadosDisciplinasCursadas.length > 0 ? 
                <div>                  
                <h2 className="h5 text-blue mb-4">
                Disciplinas que você marcou como cursado
                </h2>
                {this.state.dadosDisciplinasCursadas.map((el,index) =>{                  
                  return(
                    <CardDadosDisciplinasCursadas 
                    key={index}
                    disciplinasProcessadas={true}                                      
                    nomePeriodoLetivo={el.periodoLetivo}
                    nomeInstituicao={el.instituicao}
                    showAccordionDisciplinas={false}
                    siglaUf={el.siglaUf}
                    disciplinas={el.disciplinas}
                     />
                  )
              })}            
            </div>:null}              
              {this.state.candidato.idSituacaoCandidato === 3                 
                && !this.state.candidato.permiteProcessoIsencao
                 ?                
              <div className="col-md-4 offset-4">                
                <button                  
                  onClick={this.openSiteInscricaoAluno} className="imprimir-comprovante">Faça Sua Pré-Matrícula</button>
              </div>
                : ''}


          {this.state.candidato.permiteProcessoIsencao ?                
              <div className="col-md-4 offset-4">                
                <button style={{background:'orange', width: '335px'}}                  
                  onClick={this.openSiteInscricaoAluno} className="btn-desistir-envio-documento">
                    {this.state.solicitacaoServico.codigoAndamento === codigosAndamento["Aguardando Entrega da Documentação"] ? 
                    'Desistir de Enviar os Documentos e Fazer a Pré-Matrícula': 'Faça Sua Pré-Matrícula'}
                  </button>
              </div>
                : ''}
              


              {this.state.candidato.idSituacaoCandidato !== 3 && parseInt(this.state.candidato.valorInscricao, 10) !== 0
                ? <div className="form-group">
                  <div className="row">
                    <div className="col col-sm-12 center">
                      <p className="text-inscricao">
                        Sua inscrição só terá validade após o pagamento da taxa de inscrição.
                     <br />Escolha uma das formas abaixo:
                  </p>
                      <button className="btn-segunda-graduacao" onClick={this.pagarComBoleto}>
                        Imprimir Boleto Bancário
                  </button>
                      <button className="transf-externa" onClick={this.pagarComCartao}>
                        Pagar com Cartão de Crédito
                  </button>
                      <div>
                      </div>
                      {
                        this.state.idRegistroTituloBoleto
                          ? <iframe title="Boleto" id="iframe-boleto" name="iframe-boleto" ref={el => (this.refBoleto = el)} src={"" + this.state.idRegistroTituloBoleto} frameBorder="0"></iframe>
                          : null
                      }
                    </div>
                  </div>
                </div> : null}
            </div>
            : null
          }
          {this.state.showFormTransRe ?
            <div>
              <FluxoTransferenciaReingresso
                carregarAndamentoAtualCandidato={this.carregarAndamentoAtualCandidato}
                carregarDisciplinasCadastradas={this.carregarDisciplinasCadastradas}
                candidato={this.state.candidato}
                setShowComprovante={this.setShowComprovante}
                buscarInscricao={this.buscarInscricao}
                openWarningModal={this.openWarningModal}
              />
            </div>
            : null
          }
          <div>
            <style jsx="false">{`
               @media(max-width: 1200px){
               .abs-footer{
               position: relative !important;
               }
               }
               @media(max-height: 900px){
               .abs-footer{
               position: relative !important;
               }
               .offset-4 {
                margin-left: 39.333333%;
                }
               }
               `}
            </style>
          </div>
        </section>
      </Container>
    )
  }
}