import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
export default class Warnings extends Component {  

    getTipoModalText(){
      switch(this.props.tipoErro){
        case 1:return 'Atenção';
        case 2: return 'Erro';
        case 3: return 'Sucesso';
        default: return '';
      }
    }
    getTipoModalCss(){
      switch(this.props.tipoErro){
        case 1:return 'alert';       
        case 2: return 'erro';
        case 3: return 'sucesso';
        default: return '';
      }
    }
    render() {
        return (
            <Modal show={this.props.show} >        
          <Modal.Body>
          <button type="button" onClick={() => this.props.closeWarningModal()} 
          className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
            <div className="modal-body modal-alerta">
              <p>
                <span className={this.getTipoModalCss()}>{this.getTipoModalText() !== '' ? `${this.getTipoModalText()}:` : ''}</span>{this.props.message}<br/>
                Clique <a href="https://portal.unigranrio.edu.br/graduacao-unigranrio" style={{color: 'white', fontWeight: 'bold'}} target="_blank"> aqui  </a>para saber das novidades. 
              </p>           
            </div>
          </Modal.Body>
          <style jsx="true" global="true">{`
              .modal-alerta, .modal-body{
                background: #266ca9;
              }
              .modal-body{
                padding: 20px 20px !important;
              }
              .modal-body{
                border-radius: 5px;
              }
              .modal-content{
                border-radius: 7px;
              }
              .modal-alerta > button{
                position: absolute;
                right: 0;
                top: 0;
                padding: 10px 15px;
              }
              .modal-alerta > p{
                text-align: center;
                font-weight: 300;
                color: #e9ecef;
              }
              .modal-alerta > p > span{                
                text-transform: uppercase;
                font-weight: 700;
                padding:2px;
              }
              .alert{
                color: #e4b60e;
              }
              .sucesso{
                color: #FFF;
              }
              .erro{
                color:#ff6c6c;
              }
              .close{
                color: #ffffff;
                position: absolute;
                z-index: 99999999;
                right: 0px;
                top: 0px;
                padding: 7px 15px !important;
                transition: 0.5s;
              }
              .close:hover, .close:focus{
                color: #f1f1f1;
                cursor: pointer;
                background: #08427eba;
                border-radius: 0px 5px 0px 5px;
                transition: 0.5s;
                outline: none;
              }
            `}
          </style>
        </Modal>
        )
    }

    styles = {
        width100: {
          width: "100%"
        }
      };      
}

export var tipoErro = {"alerta":1, "erro":2,"sucesso":3}
Object.freeze(tipoErro)