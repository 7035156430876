import React, { Component } from "react";

export default class Timeline extends Component {
    render() {
        return (
            <div className={`progress-container mgtop-progress step-enem-${this.props.passo -1 }`}>
                <div className="progress mb-2">
                    <div className="progress-bar progress-bar-striped"></div>
                </div>
                <div className="row">
                    <div className="progress-desc progress-desc-1 passos col-md-6" id="row-passo-1">
                    {/* <span>1º</span> Escolha do curso e unidade */}

                  {this.props.escolheuModalidade ? 
                  <div className="inner-passos">
                    {this.props.curso.nome}&nbsp;
                    {this.props.unidade.nome}&nbsp;
                    {this.props.modalidade.modalidadeEnsino}

                        </div> :
                            <div className="inner-passos">
                            </div>
                        }

                        {this.props.escolheuModalidade ?
                            <button disabled={this.props.inscricaoConcluida} id="linkEditarPasso1" className="btn bt-editar-progress" onClick={(e) => this.props.editarProgresso(e, 1)} rel="unidades" >
                                <svg className="svg-inline--fa fa-edit fa-w-18" aria-hidden="true" data-prefix="fa" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg>
                                Editar</button>
                            : null}

                    </div>

                    <div className="progress-desc progress-desc-4 passos col-md-6" id="row-passo-4">
                        {/* <span>2º</span> Dados */}

              {this.props.inscricaoConcluida ?
                            <div className="inner-passos">
                                Inscrição concluída com sucesso.
              </div>
                            :
                            <div className="inner-passos">
                            </div>
                        }
                    </div>

                </div>
            </div>
        )
    }
}
