import React, { Component } from 'react'
import { Modal } from "react-bootstrap";

 
export default class ModalPreviewDisciplinasCursadas extends Component {
  
  constructor(props) {
    super(props);    
}

    render() {
        return (            
        <Modal show={this.props.showModalPreviewDisciplinasCursadas} >        
          <Modal.Body>
          <button type="button" onClick={() => this.props.closeModalPreviewDisciplinasCursadas()} 
          className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
            <div className="modal-body modal-alerta modal-preview">              
            <h4 className="h4 text-blue mb-4 text-center">
             Valide as informações cadastradas
            </h4>                              
              <p className="subtitle-doc mb-4 text-tittle-disciplinas text-center">
              Certifique-se que todas as disciplinas para esse semestre nessa instituição foram marcados
              {this.props.ultimoCadastro ? '.':' para iniciar outro cadastro de disciplinas.'}</p>                              

              <div className="tab-content tab-preview">
                <div className="div-curso-universidade">
                <p className="preview-curso-aprovado">{this.props.nomePeriodoLetivo}</p>
                <p className="preview-universidade-aprovado">{this.props.nomeInstituicao} - {this.props.siglaUf}</p>
                </div>
                <ul>                  
                {this.props.nomesDisciplinasSelecionadas.map((nomeDisciplinaSelecionada, index) => {
                    return (
                      <li key={index}>{nomeDisciplinaSelecionada}</li>
                    )
                  })}
                </ul>               
                </div>                 
              <ul className="list-preview">
                <li>
                  <button className="btn btn-danger" onClick={(e) => {
                    e.preventDefault()
                    this.props.closeModalPreviewDisciplinasCursadas()}}>
                    Cancelar
                  </button>
                </li>
                <li>
                  <button  onClick={(e) => 
                    {                      
                    e.preventDefault()
                    this.props.confirmarDisciplinas(e)}}>
                    {this.props.ultimoCadastro ? 'Finalizar Inscrição':'Confirmar Disciplinas'}
                  </button>
                </li>
              </ul>
            </div>
          </Modal.Body>
          <style jsx="true" global="true">{`
              .modal-alerta, .modal-body{
                background: #fff;
              }
              .modal-body{
                padding: 20px 20px !important;
              }
              .modal-body{
                border-radius: 5px;
              }
              .modal-content{
                border-radius: 7px;
              }
              .modal-alerta > button{
                position: absolute;
                right: 0;
                top: 0;
                padding: 10px 15px;
              }
              .modal-alerta > p{
                text-align: center;
                font-weight: 300;
                color: #266ca9;
              }
              .modal-alerta > p > span{                
                text-transform: uppercase;
                font-weight: 700;
                padding:2px;
              }
              .alert{
                color: #e4b60e;
              }
              .sucesso{
                color: #1E7E34;
              }
              .erro{
                color:#ff6c6c;
              }
              .close{
                position: absolute;
                z-index: 99999999;
                right: 0px;
                top: 0px;
                padding: 7px 15px !important;
                transition: 0.5s;
                color: #fff;
              }
              button.close{
                background: #08427e;
                opacity: 0.8;
                border-radius: 0px 0px 0px 5px;
              }
              .close:hover, .close:focus{
                opacity: 0.75;
                cursor: pointer;
                border-radius: 0px 0px 0px 5px;
                transition: 0.5s;
                outline: none;
                color: #fff;
              }              
            `}
          </style>
        </Modal>
        )
    }

    styles = {
        width100: {
          width: "100%"
        }
      };      
}
