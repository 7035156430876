const utilsFormHubSpot = {};

utilsFormHubSpot.encontrarAtributo =  function(form,campo){    
    return form.find(atributo => atributo.name === campo) !== undefined ?
    form.find(atributo => atributo.name === campo).value:''
}

utilsFormHubSpot.encontrarTextCurso = function(form){    
    window.selects = form.find('select')
    const arraySelectsTratado = []
    const arraySelects = form.find('select')
    const lengthSelects = form.find('select').length
    for(let i = 0;i<lengthSelects;i++){
        arraySelectsTratado.push(arraySelects[i])
    }
    const selectCursoRecrutadorEncontrado = arraySelectsTratado.find(el => el.name === "curso_recrutador")    
    let valueTextCursoSelecionado
    if  (    
            selectCursoRecrutadorEncontrado 
                && selectCursoRecrutadorEncontrado.selectedOptions[0] 
                && selectCursoRecrutadorEncontrado.selectedOptions[0].text
         ) {
            valueTextCursoSelecionado = selectCursoRecrutadorEncontrado.selectedOptions[0].text    
        }
    
    return valueTextCursoSelecionado;
}



export default utilsFormHubSpot;