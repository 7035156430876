import React, { Component } from 'react';

export default class CardLateralCurso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            curso:{
                urlVideo:this.props.curso.urlVideo,
                informacoesCurso : [
                    // this.props.curso.objetivo ? {label:'Objetivo',value:this.props.curso.objetivo,icon:'fas fa-bullseye'}:'',
                    // this.props.curso.perfilEgresso ?  {label:'Perfil Egresso',value:this.props.curso.perfilEgresso,icon:'fas fa-user'}:'',
                    // this.props.curso.mercadoTrabalho ? {label:'Mercado de Trabalho',value:this.props.curso.mercadoTrabalho,icon:'fas fa-briefcase'}:'',
                    this.props.curso.nomeTipoFormacao ? {label:'Tipo de Formação',value:this.props.curso.nomeTipoFormacao,icon:'fas fa-book'}:'',
                    this.props.curso.duracao ? {label:'Duração',value:this.props.curso.duracao,icon:'fas fa-calendar-alt'}:'',
                    this.props.curso.cargaHoraria ? {label:'Carga horária',value:this.props.curso.cargaHoraria,icon:'fas fa-clock'}:'',
                    this.props.curso.proximaTurma ? {label:'Próxima turma',value:this.props.curso.proximaTurma,icon:'fas fa-users'}:'',
                    // this.props.curso.informacaoCurso ? {label:'Informações do MEC',value:this.props.curso.informacaoMec,icon:'fas fa-bullhorn'}:'',
                    // this.props.curso.infraestrutura ? {label:'Infraestrutura',value:this.props.curso.infraestrutura,icon:'fas fa-university'}:'',
                ]
            }
        }
        
        this.renderizarInformacoesCurso = this.renderizarInformacoesCurso.bind(this); 
    }    

    renderizarInformacoesCurso(){
        return this.state.curso.informacoesCurso.map((informacaoCurso, i) => (          
            <li  key={i}>
              <i className={informacaoCurso.icon}></i>
              <strong>{informacaoCurso.label} </strong>
               <p style={{color:'#085fa3', whiteSpace: 'normal'}}>{informacaoCurso.value}</p>
            </li>
          ));       
    }

    render() {
        return (
            <div className="description-course">
                <ul>                           
                    {this.renderizarInformacoesCurso()}
                    
                </ul>
            </div>
        )
    }
}
