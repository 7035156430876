import React, { Component } from 'react';
import FormArquivosPendentes from './FormArquivosPendentes';
import FormDisciplinasCursadas from './FormDisciplinasCursadas';
import TimelineTransferenciaReingresso2 from '../cards/TimelineTransferenciaReingresso2';
import { tipoErro } from '../../components/utils/Warnings';


import Carregando from '../utils/Carregando';
import candidatoService from '../../services/processo-seletivo/candidato-service';
import { codigosAndamento } from '../../utils/constants';


export default class FluxoTransferenciaReingresso extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passo: 1,
      candidato: this.props.candidato,
      inscricaoFinalizada: false,
      finalizandoInscricao:false,
    }
  }

  async componentDidMount(){
    const solicitacaoServico = await candidatoService.getAndamentoAtual(this.props.candidato.id)                  
    if(solicitacaoServico.codigoAndamento !== codigosAndamento["Aguardando Entrega da Documentação"]){
      this.editarDisciplinas()
    }
  }

  editarDocumentos = () => {
    this.setState({ passo: 1 });
  }

  editarDisciplinas = () => {
    this.setState({ passo: 2 });
  }

  finalizarInscricao = (callback) => {  
    this.setState({finalizandoInscricao:true})
    candidatoService.finalizarInscricao(this.props.candidato)
        .then(() => {
          if(callback) return callback() 
          this.props.buscarInscricao(null, true).then(()=>{
          this.props.carregarDisciplinasCadastradas(this.props.candidato.id,true)
          this.setState({inscricaoFinalizada:true})
          this.props.openWarningModal('Inscrição finalizada com sucesso!', '')
        })      
      }).catch(err => console.log(err));
  }

  render() {
    return (
      <div>
        {this.state.finalizandoInscricao ? <Carregando message="Finalizando Inscrição"/> :
       this.state.inscricaoFinalizada ? null :
          <div>
            <TimelineTransferenciaReingresso2
              passo={this.state.passo}
              editarProgresso={this.editarDocumentos}
              finalizarInscricao={this.finalizarInscricao}
            />

            <div style={{ marginTop: '50px', marginBottom: '50px' }}>
              {this.state.passo === 1 ?
                <FormArquivosPendentes
                 carregarAndamentoAtualCandidato={this.props.carregarAndamentoAtualCandidato}
                  candidato={this.props.candidato}
                  passo={this.state.passo}
                  editarProgresso={this.editarDisciplinas}
                  finalizarInscricao={this.finalizarInscricao}
                /> :
                <FormDisciplinasCursadas                  
                  candidato={this.props.candidato}
                  editarProgresso={this.editarDocumentos}
                  finalizarInscricao={this.finalizarInscricao}
                />
              }
            </div>
          </div>
        }
      </div>
    );
  }
}