import React, { Component } from "react";

export default class HistoricoEscolar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return(
      <section className="magictime spaceInRight">
        <a onClick={(e) => this.props.selecionaAlavanca(e, 10)} className="signup-btn clearfix" href=""  data-toggle="modal" data-target="#signup-modal">
          <div className="signup-btn-cta d-flex align-items-center">            
            <p><h6 className="mb-1"></h6>
            <span className="title-card">Histórico Escolar</span>
            </p>
            <div className=" btn-inscrevase ">Inscreva-se</div>
          </div>
          <small className="pre-inscricao bg-blue">
            * Cursos com condições especiais.
          </small>
        </a>

        {/* <ModalFormLead  {... this.props} {... this.state} handleCloseModal={this.handleCloseModal} /> */}
      </section>
    )
  }
}

