import React, { Component, useEffect } from 'react';
import moment from 'moment';
import Container from '../../components/Container';

import backgroundHome from '../../images/background-image_novo.png';
import modelo from '../../images/modelo.png'
import CardVestibular from '../../components/cards/CardVestibular';
import CardEnem from '../../components/cards/CardEnem';
import CardTransferencia from '../../components/cards/CardTransferencia';
import SegundaGraduacao from '../../components/cards/SegundaGraduacao';


import HistoricoEscolar from '../../components/cards/HistoricoEscolar';

import Cookies from 'js-cookie'
import { useRecoilValueLoadable } from 'recoil';
// import { fetchCursos } from '../../store/courses';
import qrCode from './../../images/banner_qrcode_site_vestibular2.png';




export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHorarioComercial: true,
      show: false,
      idAlavanca: 6, //VESTIBULAR ONLINE
      idMantida: this.props.location.pathname.includes('fatenp') ? 2 : 1,
      justCall:
        +new URL(window.location.href).searchParams.get('justcall') === 1
          ? true
          : false,
      lgpdUnigranrio: Cookies.get('lgpd'), //lgpdUnigranrio:Cookies.get('lgpdUnigranrio')
    };

    this.verificarHorarioComercial = this.verificarHorarioComercial.bind(this);
    this.setHorarioComercial = this.setHorarioComercial.bind(this);
    this.selecionaAlavanca = this.selecionaAlavanca.bind(this);
    this.closeModal = this.closeModal.bind(this);
  
  }
 
  componentDidMount() {
    // seg a sex  8 as 19
    // sab 9 as 15
    this.verificarHorarioComercial();
    this.listaCursos();

   

  }

  listaCursos() {
//   const {state, contents} = useRecoilValueLoadable(fetchCursos);
//  if(state === 'hasValue'){
//   console.log(contents)
//  }
}

  verificarHorarioComercial() {
    if (moment().weekday() > 0 && moment().weekday() < 6) {
      // Dia de semana
      if (moment().hour() >= 8 && moment().hour() < 19) {
        return this.setHorarioComercial(true);
      }
    } else if (moment().weekday() === 6) {
      // Sabado
      if (moment().hour() >= 9 && moment().hour() < 15) {
        return this.setHorarioComercial(true);
      }
    }

    return this.setHorarioComercial(false);
  }

  setHorarioComercial(isHorarioComercial) {
    this.setState({ isHorarioComercial });
  }

  selecionaAlavanca(e, alavanca) {
    e.preventDefault();

    /* this.setState({ 
      show : true,
    }); */

    /* this.forceUpdate() */
    this.handleLeadSent(alavanca);
  }

  handleLeadSent(alavanca) {
    //const stringFatenpCasoMantidaSejaFatenp = this.state.idMantida === 2 ? 'fatenp/': ''

    let lgpd = 'https://unigranrio.edu.br/termo_de_consentimento/?urlOrigem=';
    //let lgpd = 'http://localhost:5005/termo_de_consentimento/?urlOrigem=';
    if (this.state.lgpdUnigranrio) {
      lgpd = '';
    }

    if (alavanca === 999) {
      // ID Artificial de Medicina para vestibular
      window.open(
        lgpd +
          'http://inscricao.unigranrio.edu.br/processoSeletivo/vestibular-inscricao?redir=false',
        '_blank'
      );
    } else if (alavanca === 998) {
      // ID Artificial de Medicina para Enem
      window.open(
        lgpd +
          'http://inscricao.unigranrio.edu.br/processoSeletivo/enem-inscricao',
        '_blank'
      );
    } else if (alavanca === 1000) {
      // ID Artificial de Medicina para transferência externa
      //this.props.history.push(`${this.state.idMantida === 2 ? lgpd + 'fatenp/curso': lgpd + '/curso'}/3/106/0`); //106 é o id do Curso de Medicina na tabela tb_mco_curso
      window.open(
        lgpd +
          window.location +
          `${this.state.idMantida === 2 ? 'fatenp/curso' : 'curso'}/3/106/0`,
        '_blank'
      );
    } else {
      //this.props.history.push(lgpd +  `${stringFatenpCasoMantidaSejaFatenp}` + 'curso/' + `${this.state.idAlavanca}`);
      // window.open(lgpd + window.location + 'curso/' + alavanca, '_blank');
      this.props.history.push('curso/'+alavanca);
    }
  }

  closeModal() {
    this.setState({
      show: false,
    });
  }

  render() {

const { user, setUser } = this.context;
// console.log(user)
    return (
      <Container {...this.props}>
        <section className="main main-landing" id="teste">
          <div className="container-fluid">
            {/* <div className="row">
              <div className="col-md-6">
                <img className="img-fluid mb-4 " width={250} src={qrCode} />
              </div>
              <div className="col-md-6">
                <img className="img-fluid mb-4 " width={250} src={qrCode} />
              </div>
            </div> */}
            <div className="row h-100">
              <div className="col-lg-7 col-xs-12 modelo">
                <div className='pb-4'>
                  {/* <img className="img-fluid" width={250} src={qrCode} /> */}
                
                </div>
              </div>
              <div
                className={`col-lg-5 col-xs-12 ${
                  this.state.justCall && 'zero-opacity'
                }`}
              >
                {/* <div className="d-flex justify-content-end">
                  <img className="img-fluid mb-4 " width={250} src={qrCode} />
                </div> */}

                <CardVestibular
                  idMantida={this.state.idMantida}
                  selecionaAlavanca={this.selecionaAlavanca}
                />
                <CardEnem
                  idMantida={this.state.idMantida}
                  selecionaAlavanca={this.selecionaAlavanca}
                />
                <CardTransferencia
                  idMantida={this.state.idMantida}
                  selecionaAlavanca={this.selecionaAlavanca}
                />
                <HistoricoEscolar selecionaAlavanca={this.selecionaAlavanca} />
                {/* <AproveitamentoEnem  {... this.props} /> */}
                <SegundaGraduacao selecionaAlavanca={this.selecionaAlavanca} />
                {/* {<ModalFormLead idMantida={this.state.idMantida} history={this.props.history} idAlavanca={this.state.idAlavanca} show={this.state.show} closeModal={this.closeModal}/>} */}
              </div>
            </div>
            <style jsx="true">
              {`
                 .main-landing{
                  padding: 2rem 0 0;
                  background: url(${backgroundHome});
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: 100% 100%;
                  min-height: 80vh;
                  display: flex;

                 }

                @media (max-width: 1200px) {
                  .abs-footer {
                    position: relative !important;
                  }
                }
                @media (max-height: 900px) {
                  .main-landing {
                    display: inline-table;
                    width: 100%;
                  }
                  .abs-footer {
                    position: relative !important;
                  }
                  .header {
                    position: absolute;
                    width: 100%;
                    z-index: 9;
                  }
                }
              `}
            </style>
            <style jsx="true" global="true">
              {`
                body {
                  //  background: url(${backgroundHome}) center top/cover no-repeat;
                  //  background-size: cover;
                  //  background-position: 50%;

                  //  background: url(${backgroundHome});
                  //  background-position: bottom;
                  //  background-repeat: no-repeat;
                  //  background-size: 100% 10%;
                }

                .modelo {
                  background: url(${modelo});
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;

                }

                @media(max-width: 720px){
                  .modelo{
                    background-size: 80%!important;
                    min-height: 50vh;
                  }
                }
              `}
            </style>
          </div>
        </section>
      </Container>
    );
  }
}