
// import api from '../../utils/api';

// import {apiBaseComercial} from '../../config'

const acaoComercialCaptadorService = {};

// acaoComercialCaptadorService.findOneById =  function(idAcaoComercialCaptador){
//     return api.getBaseURL(`/acao-comercial-captador/${idAcaoComercialCaptador}`, null,apiBaseComercial)
//     .then((res) => {
//         return res
//     })
// }


export default acaoComercialCaptadorService;