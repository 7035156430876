import React, { Component } from 'react'
import {mascararCpf} from '../../../utils/string'
import {removerMascaras} from '../../../utils/string'
import MaskedInput from 'react-text-mask'
 
export default class PreviewDadosPessoais extends Component {
  
  constructor(props) {
    super(props);
  }
    render() {
        return (            
          <div id="dados-pessoais" className={`tab-pane fade ${this.props.discoverIsActiveContent('dados-pessoais')}`}>
          <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label className="control-label" htmlFor="nome">Nome</label>
            <input className="form-control required"  id="nome"
             name="nome" type="text" value={`${this.props.lead.primeiroNome} ${this.props.lead.sobrenome}`}  readOnly/>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="control-label" htmlFor="cpf">CPF</label>
            <input className="form-control required"  id="cpf" name="cpf"
             type="text" value={mascararCpf(this.props.lead.cpf)} readOnly/>
          </div>
        </div>        
        <div className="col-md-12">
          <div className="form-group">
            <label className="control-label" htmlFor="telefone">Telefone</label>
            <MaskedInput
              pattern="\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}$"
              mask={(e) => {
                  const semMascara = removerMascaras(e);
                  if (semMascara.length <= 10)
                      return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
                  else
                      return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
              }}
              className="form-control required" required id="telefone" 
              name="telefone" type="text" 
              value={this.props.lead.telefone || this.props.lead.telefoneComercial} readOnly
              />
          </div>
        </div>        
        <div className="col-md-12">
          <div className="form-group">
            <label className="control-label" htmlFor="dataNascimento">Data de Nascimento</label>
            <input className="form-control required"  id="dataNascimento" name="dataNascimento"
             type="date" value={this.props.lead.dataNascimento} readOnly/>
          </div>
        </div>   
      </div>
    </div>
        )
    }

    
}
