
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const candidatoTituloService = {};

candidatoTituloService.findByEntity =  function(dadosCandidatoTitulo){
    return api.postBaseURL('/candidato-titulo/find', dadosCandidatoTitulo, apiBaseProcessoSeletivo)
    .then(candidatoTitulo => {
      return candidatoTitulo
    });    
}


export default candidatoTituloService;