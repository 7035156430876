
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const tipoDocumentoFormaIngressoService = {};

tipoDocumentoFormaIngressoService.search =  function(dadosTipoDocumentoFormaIngresso){
    return api.postBaseURL('/tipo-documento-forma-ingresso/search', dadosTipoDocumentoFormaIngresso, apiBaseProcessoSeletivo)
    .then(tiposDocumentosFormasIngressos => {
        return tiposDocumentosFormasIngressos      
    })
}

export default tipoDocumentoFormaIngressoService;