
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const diaProvaService = {};

diaProvaService.findByEntity =  function(dadosDiaProva){
    return api.postBaseURL('/dia-prova/search', dadosDiaProva,apiBaseProcessoSeletivo)
            .then((oferta) => {                
            return oferta;               
    });       
}

export default diaProvaService;