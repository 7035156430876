import React, { Component } from 'react'
import MaskedInput from 'react-text-mask'
import { tipoErro } from '../../components/utils/Warnings';
import validadores from '../../utils/validadores';
import contatoService from '../../services/comercial/contato-service';
import candidatoService from '../../services/processo-seletivo/candidato-service';
import {removerMascaras} from '../../utils/string';
import Endereco from '../../components/candidato/Endereco';
import contatoHubspotService from '../../services/hubspot/contato-hubspot-service';
import { verificaSeEParaInserirOuAtualizarContato } from '../../utils/utils';

export default class FormDados extends Component {
    constructor(props) {
        super(props)
        this.state = {          
            carregando: false,
            anosEnem: '',
            anoAtual: new Date()
            //this.props.location.state ? this.props.location.state.lead : null,
        }

        this.onChange = this.onChange.bind(this);
        this.continuar = this.continuar.bind(this);
        this.definirIdadeMinima = this.definirIdadeMinima.bind(this);
        this.abrirPreviewDadosCandidatoModal = this.abrirPreviewDadosCandidatoModal.bind(this)
        //this.setEndereco = this.setEndereco.bind(this);
        this.enviarLead = this.enviarLead.bind(this)
        this.avancarPasso = this.avancarPasso.bind(this)
        this.enviandoDados = this.enviandoDados.bind(this)
    }

    componentDidMount() {     
        const lead = this.props.lead
        lead.nrAnoEnem = new Date().getFullYear() - 1
        this.props.definirEstadoComponentePai('lead',lead)        
        this.montarArrayDatasAnoEnemDinamicamente()
    }

    onChange(e) {                
        const lead = this.props.lead
        lead[e.target.name] = e.target.value
        this.setState({ lead: lead });
    }

    abrirPreviewDadosCandidatoModal(e){        
        e.preventDefault();           
        if(this.props.idAlavanca === 1) {
            this.enviarLead(e);
            return
        }else{
            this.props.definirEstadoComponentePai('callbackFunctionInsertCandidato',this.enviarLead)
            this.props.definirEstadoComponentePai('showPreviewDadosCandidatoModal',true)
        }
        
        
    }
  
    async enviarLead() {                
        if (!validadores.validarCPF(removerMascaras(this.props.lead.cpf))) {
            this.props.openWarningModal('CPF Inválido',tipoErro.alerta)
            return;
        }
        if(this.props.idAlavanca === 2){
            this.enviandoDados(true)
        }
        await contatoHubspotService.enviarContatoHubspot(this.props,this.props.lead.email)
        
        if (verificaSeEParaInserirOuAtualizarContato(this.props.lead.idLead, this.props.emailSalvoPrimeiraPage, this.props.lead.email)) {
            const lead = {
                id: 0,
                primeiroNome: this.props.lead.primeiroNome,
                sobrenome: this.props.lead.sobrenome,
                email: this.props.lead.email,
                telefone: this.props.lead.telefone,
                idOfertaProcessoSeletivo: this.props.oferta.id,
                idIstituicaoMantida: this.props.idIstituicaoMantida,
                cep: removerMascaras(this.props.endereco.cep),
                logradouro: this.props.endereco.logradouro + ' ' + this.props.endereco.numero,  
                complemento: this.props.endereco.complemento,
                bairro: this.props.endereco.bairro,
                cidade: this.props.endereco.nomeMunicipio,
                estado: this.props.endereco.sgUf,
                nrAnoEnem: this.props.idAlavanca === 2 ? this.props.lead.nrAnoEnem : null,
                nrInscricaoEnem: this.props.lead.nrInscricaoEnem ? this.props.lead.nrInscricaoEnem : null,
                cpf: removerMascaras(this.props.lead.cpf),
                dataNascimento: this.props.lead.dataNascimento
            }
        
            return contatoService.insert(lead)            
                .then((insertedLead) => {        
                    const lead = this.props.lead;
                    lead.idLead = insertedLead.idLead;
                    this.props.definirEstadoComponentePai('lead', lead);
                    this.props.definirEstadoComponentePai('preencheuFormulario', true);
                    
                    if(this.props.idAlavanca === 2 || this.props.idAlavanca === 15 || this.props.idAlavanca === 16) {
                        this.enviarCanditado()
                    } else if(this.props.idAlavanca === 1) {
                        this.continuar()
                    }
                })
                .catch(() => {                
                    this.enviandoDados(false)                
                    this.props.openWarningModal(null,null,true)                
                });
        } else {
            return this.atualizarLead()
        }
    }

    atualizarLead() {
        const lead = {
            id: this.props.lead.idLead,
            primeiroNome: this.props.lead.primeiroNome,
            sobrenome: this.props.lead.sobrenome,
            email: this.props.lead.email,
            telefone: this.props.lead.telefone,
            idOfertaProcessoSeletivo: this.props.oferta.id,
            idIstituicaoMantida: this.props.idIstituicaoMantida,
            cep: removerMascaras(this.props.endereco.cep),
            logradouro: this.props.endereco.logradouro + ' ' + this.props.endereco.numero,
            //enderecoNumero: this.props.endereco.numero,
            complemento: this.props.endereco.complemento,
            bairro: this.props.endereco.bairro,
            cidade: this.props.endereco.nomeMunicipio,
            estado: this.props.endereco.sgUf,
            nrAnoEnem: this.props.idAlavanca === 2 ? this.props.lead.nrAnoEnem : null,
            nrInscricaoEnem: this.props.lead.nrInscricaoEnem ? this.props.lead.nrInscricaoEnem : null,
            cpf: removerMascaras(this.props.lead.cpf),
            dataNascimento: this.props.lead.dataNascimento
        }
        return contatoService.update(lead,this.props.lead.idLead)
        .then(() => {
            if(this.props.idAlavanca === 2 || this.props.idAlavanca === 15 || this.props.idAlavanca === 16){
                this.enviarCanditado()
            }else{
                this.continuar()
            }
        })
    }    

    enviarCanditado() {        
        const candidato = {
            idLead: this.props.lead.idLead,
            idProcessoSeletivo: this.props.tipoProva,
            idOfertaProcessoSeletivo: this.props.oferta.id,
            idInstituicaoMantida: this.props.idInstituicaoMantida,
            idLocalProva: this.props.localProva.id,
            dataProva: Object.keys(this.props.dataProva).length === 0 ? null : this.props.dataProva,
            horaProva: this.props.horarioProva.agendamento,
            inPermiteEnvioSms: true,
            nrInscricaoEnem: this.props.lead.nrInscricaoEnem,
            nrAnoEnem: this.props.idAlavanca === 2 ? this.props.lead.nrAnoEnem:null,

            //Endereço
            cep: removerMascaras(this.props.endereco.cep),
            logradouro: this.props.endereco.logradouro,
            numero: this.props.endereco.numero,
            complemento: this.props.endereco.complemento,
            bairro: this.props.endereco.bairro,
            municipio: this.props.endereco.idMunicipio,
            uf: this.props.endereco.idUf,

            cpf: this.props.lead.cpf,
            nome: this.props.lead.primeiroNome,
            telefone: this.props.lead.telefone,
            sobrenome: this.props.lead.sobrenome,
            email: this.props.lead.email,
            dataNascimento: this.props.lead.dataNascimento,
        }
        candidatoService.insert(candidato, this.props.openWarningModal)
            .then((returnedParamsProcedure) => {
                if(!returnedParamsProcedure){
                    this.props.openWarningModal(null,null,true)
                    this.enviandoDados(false);
                    return;
                }
                const idTitulo = returnedParamsProcedure.idTitulo;
                const idCandidato  = returnedParamsProcedure.idCandidato
                this.props.definirEstadoComponentePai('idTitulo', idTitulo)
                this.props.definirEstadoComponentePai('idCandidato', idCandidato)
                
                this.props.definirEstadoComponentePai("inscricaoConcluida", true);  
                this.props.editarProgresso(null, 4, this.props.lead);
                this.enviandoDados(false);
            })   
            .catch(erro => {
                this.props.openWarningModal(erro, 2, false)
                this.props.definirEstadoComponentePai('isEnviandoDadosCandidato', false)
                return;
            })
                     
    }

    continuar() {        
        if (!validadores.validarCPF(removerMascaras(this.props.lead.cpf))) {
            this.props.openWarningModal('CPF Inválido',tipoErro.alerta)
            return;
        }
        const lead = this.props.lead;
        const endereco = this.props.endereco;
        lead.telefone = removerMascaras(lead.telefone)
        endereco.cep = removerMascaras(endereco.cep)
        lead.cpf = removerMascaras(lead.cpf)
        this.props.definirEstadoComponentePai('lead', this.props.lead)
        this.props.definirEstadoComponentePai('endereco', endereco)
        this.props.definirEstadoComponentePai('preencheuFormulario', true)
        this.props.editarProgresso(null, 3, this.state);
    }

    montarArrayDatasAnoEnemDinamicamente() {
        const primeiroAnoEnem = new Date(2009, 1)
        const datasAnosEnem = [];
        const diferencaAno = this.state.anoAtual.getYear() - primeiroAnoEnem.getYear()
        for (let i = 0; i <= diferencaAno; i++) {
            datasAnosEnem.push(new Date(primeiroAnoEnem.getTime()))
            primeiroAnoEnem.setFullYear(primeiroAnoEnem.getFullYear() + 1)
        }

        this.setState({ anosEnem: this.renderizarCombos(datasAnosEnem) })
    }

    renderizarCombos(anosEnem) {
        return anosEnem.map((el, i) => (
            <option key={i} value={el.getFullYear()}>
                {el.getFullYear()}
            </option>
        ));

    }

    definirIdadeMinima() {
        // Define idade mínima para se inscrever
        var today = new Date();
        var dd = '01'
        var mm = '01'
        var yyyy = today.getFullYear() - 14;
        return String(yyyy) + '-' + mm + '-' + dd;
    }
    async avancarPasso(e) {
        e.preventDefault();
        if (!validadores.validarCPF(removerMascaras(this.props.lead.cpf))) {
            this.props.openWarningModal('CPF Inválido',tipoErro.alerta)
            return;
        }        
        await this.enviarLead()
        this.props.editarProgresso(null, 2)
    }

    enviandoDados(boolean){
        this.props.definirEstadoComponentePai('isEnviandoDadosCandidato',boolean)
    }

    render() {
        return (
            <div>
                <form id="dados" method="post" className="box_fade" onSubmit={(e) => {
                    if(this.props.idFormaIngressoProcessoSeletivo === 3 || this.props.idFormaIngressoProcessoSeletivo === 4){
                        this.avancarPasso(e)
                    }else{
                        this.abrirPreviewDadosCandidatoModal(e)
                    }
                    }} >
                    <h2 className="h3 text-blue mb-4">Dados</h2>
                    {/* <form className="" method="post" action=""> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="primeiroNome">Nome</label>
                                <input className="form-control required" required id="primeiroNome" name="primeiroNome" type="text" value={this.props.lead.primeiroNome} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="sobrenome">Sobrenome</label>
                                <input className="form-control required" required id="sobrenome" name="sobrenome" type="text" value={this.props.lead.sobrenome} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="dataNascimento">Data de nascimento</label>
                                <input className="form-control required" required id="dataNascimento"
                                    name="dataNascimento" type="date" min="1900-01-01"
                                    max={this.definirIdadeMinima()} value={this.props.lead.dataNascimento} onChange={this.onChange} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="email">Email</label>
                                <input className="form-control required" required id="email" name="email" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={this.props.lead.email} onChange={this.onChange} />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="tel">Telefone de contato</label>
                                <MaskedInput
                                    pattern="\([0-9]{2}\) [0-9]{4,6}-[0-9]{3,4}$"
                                    mask={(e) => {
                                        const semMascara = removerMascaras(e);
                                        if (semMascara.length <= 10)
                                            return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
                                        else
                                            return ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
                                    }}
                                    className="form-control required" required id="telefone" name="telefone" type="text" value={this.props.lead.telefone} onChange={this.onChange}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="control-label" htmlFor="cpf">CPF</label>
                                <MaskedInput
                                    pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
                                    mask={[/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                                    className="form-control required" required id="cpf" name="cpf" type="text" value={this.props.lead.cpf} onChange={this.onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <Endereco   endereco={this.props.endereco}
                                openWarningModal={this.props.openWarningModal} 
                                definirEstadoComponentePai={this.props.definirEstadoComponentePai}/>
                    {
                        this.props.idAlavanca === 2
                            ?
                            <div className="row" >
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="nrInscricaoEnem">Código do ENEM</label>
                                        <input required className="form-control"
                                            id="nrInscricaoEnem" name="nrInscricaoEnem"
                                            type="text" value={this.props.lead.nrInscricaoEnem}
                                            onChange={this.onChange} />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="nrAnoEnem">Ano ENEM</label>
                                        <select required className="form-control" id="nrAnoEnem" name="nrAnoEnem" value={this.props.lead.nrAnoEnem} onChange={this.onChange}>
                                            {this.state.anosEnem}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    <br />
                    <div id="confirmar-cancelar" className="container">
                        {this.props.idFormaIngressoProcessoSeletivo === 3 || this.props.idFormaIngressoProcessoSeletivo === 4 ? 
                        <button id="btConfirmaInscricao" className="btn btn-primary bt-confirmar" type="submit" disabled={this.props.carregando}>Avançar para Próximo Passo</button>                 
                        :
                        <button id="btConfirmaInscricao" className="btn btn-primary bt-confirmar" disabled={this.state.carregando}>Continuar</button>                               
                        }
                        
                    </div>
                </form>
                {/* // </form> */}
            </div>
        )
    }
}
