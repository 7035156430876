import React, { Component } from 'react'

export default class PreviewDadosTransferenciaOrReingresso extends Component {

  constructor(props) {
    super(props);
    this.state = {
      possuiFies: false,
    }
  }
  componentWillMount() {
    if (this.props.lead.possuiFies && JSON.parse(this.props.lead.possuiFies)) {
      this.setState({ possuiFies: true })
    }
  }
  render() {
    {
      if (this.props.show) {
        return (
          <div id="dados-transferencia-reingresso" className={`tab-pane fade ${this.props.discoverIsActiveContent('dados-transferencia-reingresso')}`}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label" htmlFor="tipoProva">Instituição</label>
                  <input className="form-control required" id="nomeInstituicaoEnsinoSuperior"
                    name="nomeInstituicaoEnsinoSuperior" type="text" value={this.props.lead.nomeInstituicaoEnsinoSuperior} readOnly />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="nomeCursoOrigemEnsinoSuperior">Curso</label>
                  <input className="form-control required" id="nomeCursoOrigemEnsinoSuperior"
                    name="nomeCursoOrigemEnsinoSuperior" type="text" value={this.props.lead.nomeCursoOrigemEnsinoSuperior} readOnly />
                </div>
                <div className="form-group">
                  <label className="control-label" htmlFor="nomeCidadeEnsinoSuperior">Cidade</label>
                  <input className="form-control required" id="nomeCidadeEnsinoSuperior"
                    name="nomeCidadeEnsinoSuperior" type="text" value={this.props.lead.nomeCidadeEnsinoSuperior} readOnly />
                </div>
                {/* If necessário em caso Candidato optou por não solicitar a isenção de disciplinas */}
                {this.props.lead.faseCurricular !== 0 ?
                  <React.Fragment>
                    <div className="form-group">
                      <label className="control-label" htmlFor="faseCurricular">Último Período Cursado</label>
                      <input className="form-control required" id="faseCurricular"
                        name="faseCurricular" type="text" value={'Período ' + this.props.lead.faseCurricular} readOnly />
                    </div>
                    <div className="form-group">
                      <label className="control-label" htmlFor="cargaHorariaCumpridaEnsinoSuperior">Carga horária Cumprida</label>
                      <input className="form-control required" id="cargaHorariaCumpridaEnsinoSuperior"
                        name="cargaHorariaCumpridaEnsinoSuperior" type="text" value={this.props.lead.cargaHorariaCumpridaEnsinoSuperior} readOnly />
                    </div>
                  </React.Fragment>

                  : null}

                {this.props.opcaoTipoProva.aceitaFies ? 
                  <div className="form-group">
                    <label className="control-label" htmlFor="possuiFies">Deseja transferir o Fies da instituição de origem ?</label>
                    <input className="form-control required" id="possuiFies"
                      name="possuiFies" type="text" value={this.state.possuiFies ? 'Sim' : 'Não'} readOnly />
                  </div> 
                : ''}
                {this.props.idAlavanca === 4 ?
                  <div className="form-group">
                    <label className="control-label" htmlFor="anoConclusaoEnsinoSuperior">Ano de Conclusão</label>
                    <input className="form-control required" id="anoConclusaoEnsinoSuperior"
                      name="anoConclusaoEnsinoSuperior" type="text" value={this.props.lead.anoConclusaoEnsinoSuperior} readOnly />
                  </div>

                  : null}


              </div>
            </div>
          </div >
        )
      } else {
        return null;
      }
    }

  }


}
