import React, { Component } from 'react';

import Payment from 'payment';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import moment from 'moment';

import pagamentoService from '../services/financeiro/pagamento-service'

export default class PagamentoCartao extends Component {
    constructor(props) {
        super(props)

        this.state = {
            alerta: {
                exibir: false,
                erro: false,
                mensagem: ''
            },
            nomeCartao: '',
            numeroCartao: '',
            mesExpiracao: '',
            anoExpiracao: '',
            expiracao: '',
            cvc: '',
            bandeira: '',
            focused: '',
            placeholder: {
                name: 'NOME',
            },
            locale: {
                valid: 'Validade'
            },
            cartaoValido: false
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleCallback = this.handleCallback.bind(this);
        this.finalizarPagamento = this.finalizarPagamento.bind(this);
        this.esconderBarraNotificacao = this.esconderBarraNotificacao.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,document.body.scrollHeight);
        Payment.formatCardNumber(document.querySelector('[name="numeroCartao"]'));
        Payment.formatCardExpiry(document.querySelector('[name="expiracao"]'));
        Payment.formatCardCVC(document.querySelector('[name="cvc"]'));
    }

    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name,
        });
    };

    handleInputChange = ({ target }) => {
        if (target.name === 'expiracao') {
            this.setState({
                [target.name]: target.value.replace(/ /g, ''),
            });
        }
        else {
            this.setState({
                [target.name]: target.value,
            });
        }
    };

    handleCallback(type, isValid) {
        if (isValid) {
            let bandeira;
            switch (type.issuer) {
                case 'mastercard':
                    bandeira = 'Master';
                    break;
                case 'amex':
                    bandeira = 'Amex';
                    break;
                case 'dinersclub':
                    bandeira = 'Diners';
                    break;
                case 'discover':
                    bandeira = 'Discover';
                    break;
                case 'visa':
                    bandeira = 'Visa';
                    break;
                case 'hipercard':
                    bandeira = 'Hipercard';
                    break;
                case 'jcb':
                    bandeira = 'JBC';
                    break;
                default:
                    alert('Cartão inválido.');
                    this.setState({ cartaoValido: false });
                    return;
            };

            this.setState({ cartaoValido: isValid, bandeira: bandeira });
        } else {
            this.setState({ cartaoValido: isValid });
        }
    }

    finalizarPagamento(e) {
        if (!this.state.cartaoValido) {
            alert('Cartão inválido!');
            e.preventDefault();
            return;
        }
        
        //MerchantOrderId de teste qualquer id diferente de 1 e 2
        const pagamento = {
            "IdMantida": this.props.idInstituicaoMantida,
            "MerchantOrderId": this.props.idRegistroTituloCartao,
            "Payment": {
                "Type": "CreditCard",
                "Amount": this.getMoney(this.props.opcaoTipoProva.vrInscricao),
                "Installments": 1,
                "SoftDescriptor": "Unigranrio",
                "CreditCard": {
                    "CardNumber": this.state.numeroCartao.replace(/ /g, ''),
                    "Holder": this.state.nomeCartao,
                    "ExpirationDate": this.state.expiracao,
                    "SecurityCode": this.state.cvc,
                    "Brand": this.state.bandeira
                }
            }
        }
        pagamentoService.insert(pagamento)        
            .then(res => {
                if (res.erro) {
                    this.setState({
                        alerta: {
                            exibir: true,
                            erro: true,
                            mensagem: res.mensagem || 'Ocorreu um erro durante o pagamento, corrija os dados e tente novamente.'
                        }
                    })
                } else {
                    //MANDAR PARA TELA FINAL
                    this.props.mudarStateSucessoInscricao('mostrarInputCartao', false);
                    this.props.finalizarInscricao();
                }
            })
            .catch(err => {
                this.setState({
                    alerta: {
                        exibir: true,
                        erro: true,
                        mensagem: err || 'Ocorreu um erro durante o pagamento, corrija os dados e tente novamente.'
                    }
                })
            })

        e.preventDefault();
    }

    getMoney(str) {
        return parseInt(str.replace(/[\D]+/g, ''), 10);
    }

    formatReal(int) {
        var tmp = int + '';
        tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
        if (tmp.length > 6)
            tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

        return tmp;
    }

    esconderBarraNotificacao() {
        this.setState({ alerta: { exibir: false } });
    }

    render() {
        const { nomeCartao, numeroCartao, expiracao, cvc, focused, placeholder, locale, } = this.state;
        return (
            <div className="row text-center">
                <aside className="col-sm-7 no-padding" id="cartao-credito">
                    <article className="card border-none">
                        <div className="card-body-cartao">
                            {
                                this.state.alerta.exibir
                                    ? <p className={this.state.alerta.erro ? "alert alert-danger" : "alert alert-success"} onClick={this.esconderBarraNotificacao}>{this.state.alerta.mensagem}</p>
                                    : null
                            }
                            <article className="card">
                                <div className="">
                                    <Cards
                                        number={numeroCartao}
                                        name={nomeCartao}
                                        expiry={expiracao}
                                        placeholders={placeholder}
                                        locale={locale}
                                        cvc={cvc}
                                        focused={focused}
                                        callback={this.handleCallback}
                                    />
                                    <form style={styles.marginTop15} onSubmit={this.finalizarPagamento}>
                                        <div className="form-group form-cartao">
                                            <input
                                                type="tel"
                                                name="numeroCartao"
                                                required
                                                className="form-control"
                                                placeholder="Número do cartão"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </div>
                                        <div className="form-cartao mg-bt-10">
                                            <input
                                                type="text"
                                                name="nomeCartao"
                                                required
                                                className="form-control mg-bt-15"
                                                placeholder="Nome do titular do cartão (como está gravado no cartão)"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </div>
                                        <div className="form-cartao">
                                            <input
                                                type="tel"
                                                name="expiracao"
                                                required
                                                placeholder="Validade"
                                                className="form-control mg-bt-15"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                            <input
                                                type="tel"
                                                name="cvc"
                                                required
                                                placeholder="CVV"
                                                className="form-control mg-bt-25"
                                                onKeyUp={this.handleInputChange}
                                                onFocus={this.handleInputFocus}
                                            />
                                        </div>

                                        <button className="finalizar-pagamento">Finalizar pagamento</button>
                                    </form>
                                </div>
                            </article>

                        </div>
                    </article>
                </aside>
                <aside className="col-sm-5" id="resumo-compra">
                    <article className="card">
                        <h6 className="card-header">Resumo da compra</h6>
                        <div className="card-body-cartao" id="pd-resumo">
                            <div className="row">
                                <p className="col resumo-compra-left">Loja:</p>
                                <p className="col">{parseInt(this.props.idInstituicaoMantida, 10) === 1 ? 'AFE' : 'FATENP'}</p>
                            </div>
                            <div className="row">
                                <p className="col resumo-compra-left">Número do pedido:</p>
                                <p className="col">{this.props.idRegistroTituloCartao}</p>
                            </div>
                            <div className="row">
                                <p className="col resumo-compra-left">Data da compra:</p>
                                <p className="col">{moment(new Date()).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className="row border-none">
                                <p className="col resumo-compra-left">Forma de pagamento:</p>
                                <p className="col">Crédito à Vista</p>
                            </div>
                        </div>
                        <div className="card-footer text-muted">
                            <div className="row">
                                <p className="col-6">Total:</p>
                                <h6 className="col-6">R$ {this.props.opcaoTipoProva.vrInscricao}</h6>
                            </div>

                        </div>
                    </article>


                </aside>
            </div>

        )
    }
}

const styles = {
    ccImg: {
        margin: "0 auto"
    },
    width45: {
        width: "45%"
    },
    width100Center: {
        width: "10%",
        texAlign: "center"
    },
    centralizar: {
        margin: "auto"
    },
    marginTop15: {
        marginTop: "15px"
    }
}
