
import api from '../../utils/api';

import { apiBaseProcessoSeletivo } from '../../config';

import { removerMascaras } from '../../utils/string'
import { redirecionarPageVisualizacaoComprovante } from '../../utils/url';
import { tipoErro } from '../../components/utils/Warnings';

const candidatoService = {};

candidatoService.insert = async function (dadosCandidato, openWarningModal) {
  const candidatoComMesmaOferta = await candidatoService.verificaInscricaoMesmaOferta(dadosCandidato.idLead,dadosCandidato.idOfertaProcessoSeletivo)
  if(candidatoComMesmaOferta){          
    if(openWarningModal){
      openWarningModal('Você Já possui uma inscrição para essse mesmo Curso, Unidade, Forma de Ingresso, Modalidade, Turno. Você será redirecionado para visualização do comprovante...',tipoErro.alerta)
      return setTimeout(() => {
        redirecionarPageVisualizacaoComprovante(candidatoComMesmaOferta, true)
      }, 4000);
    }
    return  redirecionarPageVisualizacaoComprovante(candidatoComMesmaOferta)
  }
  return api.postBaseURL('/candidato/insert', dadosCandidato, apiBaseProcessoSeletivo)
    .then((res) => {
      const paramsProcedure = {
        idTitulo: res.find(el => el.parameterName === 'prm_id_titulo').value,
        idCandidato: res.find(el => el.parameterName === 'prm_id_candidato').value,
        txRetorno: res.find(el => el.parameterName === 'prm_tx_retorno').value
      }
      return paramsProcedure;
    });
}

candidatoService.findOneByIdAndDateNascimentoAndCpf = function (dadosCandidato) {
  return api.getBaseURL(
    `/candidato/buscar-inscricao-candidato/${dadosCandidato.id}/${dadosCandidato.dataNascimento}/${removerMascaras(dadosCandidato.cpf)}`, null, apiBaseProcessoSeletivo)
    .then((candidato) => {
      return candidato
    })
    .catch(err => {
      if (err.message === "Sem token" || err.status === 401) {
        api.authenticar()
          .then(() => {
            candidatoService.findOneByIdAndDateNascimentoAndCpf(dadosCandidato);
          });
      }
    });
}
candidatoService.finalizarInscricao = function (dadosCandidato) {
  return api.postBaseURL('/candidato/finalizar-inscricao-transferencia-reingresso'
    , dadosCandidato
    , apiBaseProcessoSeletivo)
      .then(() => {
        this.props.buscarInscricao(null, true).then(() => {
        this.props.carregarDisciplinasCadastradas(dadosCandidato, true)
      })
    }).catch(err => console.log(err));
}

candidatoService.apenasFinalizarInscricao = function (dadosCandidato) {
  return api.postBaseURL('/candidato/finalizar-inscricao-transferencia-reingresso'
    , dadosCandidato
    , apiBaseProcessoSeletivo)
}


candidatoService.verificarSeCandidatoJaEstaPreMatriculado = function (idCandidato) {
  return api.getBaseURL(`/candidato/verificar-se-candidato-ja-esta-pre-matriculado?idCandidato=${idCandidato}`
    , null
    , apiBaseProcessoSeletivo)
}

candidatoService.proximoAndamentoCandidato = function (candidato) {
  return api.putBaseURL('/candidato/proximo-andamento-candidato'
    , candidato
    , apiBaseProcessoSeletivo)
}

candidatoService.getAndamentoAtual = function (idCandidato) {
  return api.getBaseURL(
    `/candidato/andamento-atual/${idCandidato}`, null, apiBaseProcessoSeletivo)
    .then((solicitacaoServico) => {
      return solicitacaoServico
    })    
}

// Em caso de retorno do candidato, ele se encontra nas situações abaixo:
// Matriculado Financeiro, Matriculado Acadêmico ou Matriculado
candidatoService.verificarSeCandidatoJaEstaMatriculado = function (idCandidato) {
  return api.getBaseURL(`/candidato/verificar-se-candidato-ja-esta-matriculado?idCandidato=${idCandidato}`
    , null
    , apiBaseProcessoSeletivo)
}

candidatoService.verificaInscricaoMesmaOferta = function (idLead, idOferta) {
  return api.getBaseURL(`/candidato/verificar-inscricao-mesma-oferta?idLead=${idLead}&idOferta=${idOferta}`
    , null
    , apiBaseProcessoSeletivo)
}

candidatoService.verificaCandidatoPossuiProcessoIsencaoMesmoCurso = function (idLead, idOferta, codigoCurso) {
  if(!idLead) {
    //alert('teste')
    return false
  }

  return api.getBaseURL(`/candidato/verificar-candidato-possui-processo-isencao-mesmo-curso?idLead=${idLead}&idOferta=${idOferta}&codigoCurso=${codigoCurso}`
    , null
    , apiBaseProcessoSeletivo)
}

candidatoService.enviarEmailConfirmacao = function (candidato) {
  return api.postBaseURL(`/candidato/enviar-email-confirmacao?emailCandidato=${candidato.emailPessoal}`
    , candidato
    , apiBaseProcessoSeletivo)
}

export default candidatoService;