
import api from '../../utils/api';

// import {apiBaseEstruturaGlobal} from '../../config';


const ufService = {};

// ufService.listAll =  function(){
//     return api.getBaseURL('/ufs', null, apiBaseEstruturaGlobal)
//     .then(ufs => {
//         return ufs;
//     });    
// }

// ufService.findByEntity =  function(dadosUf){
//     return api.postBaseURL('/ufs/find', dadosUf,apiBaseEstruturaGlobal)
//             .then((ufs) => {                
//             return ufs;               
//     })        
// }


export default ufService;