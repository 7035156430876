import React, { Component } from 'react';
import logoFatenp from '../../images/hotsite-img/logo-fatenp-branca.png';
import wpp from '../../images/icons/whatsapp.png';

export default class Header extends Component {
    render() {
        return (

    <header className="site-header background-fatenp magictime boingInUp">
        <div className="container-fluid-courses">
            <div className="row">
                <div className="col-md-4" style={{textAlign: 'left'}}>  
                    <a   href="http://vemserfatenp.com.br">
                        <img className="site-header-logo img-fluid mb-3 mb-md-0" src={logoFatenp} alt="Universidade Fatenp"  />
                    </a>              
                </div>
                        <div className="col-md-8 text-md-right">
                             {/* <span className="site-header-nav1">
                                <a href="https://fatenp.com.br/portal" target="_blank" rel="noopener noreferrer">Ir para o portal</a>
                                <span className="mx-2">|</span>
                                <a href="http://www2.unigranrio.br/possoajudar/" target="_blank" rel="noopener noreferrer">FAQ</a>
                            </span>  */}
                            {/* <span className="site-header-social">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Fatenp" title="Facebook">
                                    <i className="fab fa-facebook-f"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/fatenp" title="Twitter">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://plus.google.com/116722107956995296543" title="Google+">
                                    <i className="fab fa-google-plus-g"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/fatenpunigranrio/" title="Instagram">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/fatenp/" title="LinkedIn">
                                    <i className="fab fa-linkedin-in"></i></a>
                                <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCjiSsI5RLgqCWLIwMJ6pDig/videos" title="YouTube">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </span> */}

                            <div className="site-header-nav2">
                                <a href="https://www.fatenp.com.br/" target="_blank" >Home</a>
                                {/* <a href="/hotsite" onClick={(e) => { this.props.history.push('/'); e.preventDefault() }}>Home</a> */}
                                <span className="mx-2">|</span>
                                <a href="https://vestibular.unigranrio.edu.br/fatenp/curso/6" target="_blank">Cursos</a>
                                <span className="mx-2">|</span>
                                {/* <a href="https://portal.unigranrio.edu.br/fatenp" target="_blank">Informações</a> */}
                                {/* <a href="/hotsite/informacoes/" onClick={(e) => { this.props.history.push('/informacoes'); e.preventDefault() }}>Informações</a> */}
                                {/* <span className="mx-2">|</span> */}
                                <a href="https://www.fatenp.com.br/contato" target="_blank" rel="noopener noreferrer">Contato</a>
                            </div>

                            <div className="site-header-nav2">
                            <img src={wpp} alt="" className="icon-wpp"/>
                                <a href="https://hubs.ly/H0hnby40" target="_blank" rel='noopener noreferrer' className="wpp"> 48 99154-4041</a> 
                                <span className="mx-2">|</span>
                                <a href="" target="_blank" rel='noopener noreferrer' className="wpp">0800 003 4040</a> 
                            </div>
                        </div>                
            </div>
        </div>
    </header>

        )
    }
}




