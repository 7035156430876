import React, { Component } from 'react';
import Container from '../../components/Container';
import '../../styles/internalServerError.css'
import { fraseGenericaErro } from '../../config'
import api from '../../utils/api'
import sadPage from '../../images/hotsite-img/sad-page.png';

export default class InternalError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heartCode: '❤️'
    }
  }
  componentDidMount() {
    document.body.style.background = '#fff4e3';
    const error = document.querySelector(".error-500");
    let i = 0, data = "", text = error.getAttribute("data-text");

    const typing = setInterval(() => {
      if (i == text.length) {
        clearInterval(typing);
      } else {
        data += text[i];
        document.querySelector(".error-500").setAttribute("data-text", data);
        i++;
      }
    }, 35);
    this.checkApiControleAcessoIsAvailable()
  }
  checkApiControleAcessoIsAvailable() {
    setTimeout(() => {
      api.authenticar()
        .then(() => {
          this.setState({ messageServiceReestablished: 'Serviço restabelecido, redirecionando. . .' }, () => {
            setTimeout(() => {
              window.location.reload();              
            }, 3500);
          })
        }).catch(() => {
          this.checkApiControleAcessoIsAvailable()
        })
    }, 4500);
  }
  render() {
    return (
      <Container>
        <div>                      
          <img className="img-fluid img-chapeu-universitario" src={sadPage} />

          <div className="error-500" data-text={this.state.messageServiceReestablished || (fraseGenericaErro + ` ${this.state.heartCode}`)}>
          </div>
        </div>
      </Container>
    )
  }
}




