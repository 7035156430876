
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';

const disciplinaExternaService = {};

disciplinaExternaService.insert =  function(dadosDisciplinaExterna){
    return api.postBaseURL('/disciplinas-externa', dadosDisciplinaExterna,apiBaseProcessoSeletivo)
            .then((res) => { 
                const paramsProcedure = {                    
                    idDisciplinaExterna  : res.find(el => el.parameterName === 'prm_id_disciplina_externa').value,
                    txRetorno:res.find(el => el.parameterName === 'prm_tx_retorno').value
            }            
            return paramsProcedure
    });    
}

disciplinaExternaService.insertArray =  function(dadosDisciplinaExternaArray){
    return api.postBaseURL('/disciplinas-externa/array', dadosDisciplinaExternaArray,apiBaseProcessoSeletivo)
            .then((disciplinasAdicionadas) => {                 
            return disciplinasAdicionadas
    });    
}

disciplinaExternaService.searchAll =  function(dadosDisciplinaExterna){
    return api.postBaseURL('/disciplinas-externa/search-all', dadosDisciplinaExterna,apiBaseProcessoSeletivo)
            .then((disciplinasExterna) => {                                    
            return disciplinasExterna;               
    });    
}


export default disciplinaExternaService;