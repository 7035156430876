
// import api from '../../utils/api';

// import {apiBaseFinanceiro} from '../../config';


const pagamentoService = {};

// pagamentoService.insert =  function(dadosPagamento){
//     return api.postBaseURL('/pagamento/cartao-credito', dadosPagamento, apiBaseFinanceiro)
//       .then(statusPagamento => {       
//         return statusPagamento       
//     });
// }


export default pagamentoService;