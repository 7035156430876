import React, { Component } from 'react'
import Carregando from '../../components/utils/Carregando';
import Container from '../../components/Container';
import { fraseGenericaErro } from '../../config';
import HubspotForm from 'react-hubspot-form'
import ModalContinuarInscricao from '../../components/utils/ModalContinuarInscricao'
import { tipoErro } from '../../components/utils/Warnings'
import Warnings from '../../components/utils/Warnings';
import acaoComercialCaptadorService from '../../services/comercial/acao-comercial-captador-service'
import contatoService from '../../services/comercial/contato-service';
export default class Recrutador extends Component {
    constructor(props) {
        super(props);

        this.state = {  
            warning: {
                show: false,
                message: '',
                tipoErro: ''
              },          
            lead:'',
            show:false,
            idAcaoComercialCaptador: this.props.match.params.idAcaoComercialCaptador,
            idCampanhaComercial: 0,
            idAcaoComercial: 0,
            idCaptador: 0,
            nomeAcaoComercial: "",
            nomeCaptador: "",            
            nomeCampanha: "",            
            carregando: true,
            erro: false
        }                
        
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.continuarInscricao = this.continuarInscricao.bind(this);
        this.continuarCadastrando = this.continuarCadastrando.bind(this);
        this.closeWarningModal = this.closeWarningModal.bind(this);
        this.openWarningModal = this.openWarningModal.bind(this);
        this.handleReady = this.handleReady.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.setState({ carregando: true });        
        acaoComercialCaptadorService.findOneById(this.state.idAcaoComercialCaptador)
        .then(acaoComercialCaptador =>{

            if(this.ativo === false){
                this.handleAcaoComercialCaptadorIsNotActive()
                return
            }
            this.handleAcaoComercialCaptadorIsActive(acaoComercialCaptador)
        });
    }

    handleSubmit(form){                
        const acaoComercialCaptador = {
            idCampanhaComercial:this.state.idCampanhaComercial,
            idAcaoComercial:this.state.idAcaoComercial,
            idCaptador:this.state.idCaptador
        }
        contatoService.insert(contatoService.montarLead(form,acaoComercialCaptador))
        .then(lead => {
            this.openModal()
            this.setState({lead:lead});   
        })
        .catch(()=> {
          setTimeout(() => this.handleSubmit(form), 1000)
        })
    }

    handleAcaoComercialCaptadorIsNotActive(){        
        this.openWarningModal('Seu Link Foi inativado, Removendo formulário . . .',tipoErro.alerta)
        setTimeout(() => {
            document.getElementById("hs-form-iframe-0").remove()                                            
        }, 2000);                    
    }

    handleAcaoComercialCaptadorIsActive(acaoComercialCaptador){        
        this.setState({ 
            idCampanhaComercial: acaoComercialCaptador.idCampanha,
            idAcaoComercial: acaoComercialCaptador.idAcaoComercial,
            idCaptador: acaoComercialCaptador.idCaptador,
            nomeAcaoComercial: acaoComercialCaptador.nomeAcaoComercial,
            nomeCaptador: acaoComercialCaptador.nomeCaptador,
            nomeCampanha: acaoComercialCaptador.nomeCampanha 
        });
        this.setState({ carregando: false });
    }
    
    continuarInscricao() {
        const lead = this.state.lead
        this.setState({
            carregando: false
        });
        // Esses ifs de comparação com 999 e 9999 servem para cursos que não temos nos sistemas atuais, mas
        // são feitos nos sistemas antigos
        if (lead.idCurso === 999) {
            window.location.href = 'http://inscricao.unigranrio.edu.br/processoSeletivo/vestibular-inscricao?redir=false'
            return
        }else if(lead.idCurso === 9999){
          window.location.href = 'https://portal.unigranrio.edu.br/transferencia-externa-medicina'
          return
          
        }
        if (this.state.lead.nomeCurso.includes('Fatenp')) {
            this.props.history.push(`/fatenp/1/${this.state.lead.idCurso}/0`, {
                lead
            });
        } else {
            this.props.history.push(`/curso/1/${this.state.lead.idCurso}/0`, {
                lead
            });
        }
    }
    
    closeModal(){        
        this.setState({show:false})        
      }
      openModal(){
        this.setState({show:true})
      }

      closeWarningModal() {
        const warning = this.state.warning
        warning.show = false
        this.setState({ warning: warning })
      }
    
      openWarningModal(message,tipoErro,defaultError){
        const warning = {
          show: true,
          message: message,
          tipoErro: tipoErro
        }
          if(defaultError){
            warning.message = fraseGenericaErro
            warning.tipoErro = 2
          }
          this.setState({ warning: warning })        
    } 
      continuarCadastrando(){
        window.location.reload();
      }

      handleReady(){          
        let formHubspot =  document.getElementById("hs-form-iframe-0").contentDocument;                
        setTimeout(() => {
            formHubspot.getElementsByName("nm_acao")[0].value = this.state.nomeAcaoComercial
            formHubspot.getElementsByName("nm_campanha")[0].value = this.state.nomeCampanha 
            formHubspot.getElementsByName("nm_captador")[0].value = this.state.nomeCaptador

            formHubspot.getElementsByName("curso_recrutador")[0].addEventListener("change", function(){
                let formHubspot =  document.getElementById("hs-form-iframe-0").contentDocument
                formHubspot.getElementsByName("mn_mantida")[0].value = formHubspot.getElementsByName("curso_recrutador")[0].selectedOptions[0].text.includes('Fatenp') ? 'Fatenp' : 'Unigranrio'
            });
        }, 500);
             
      }
      
    
    render() {        
        return (            
            <Container onClick={() => this.closeModal()} {... this.props}>
            <Warnings  {... this.state.warning} closeWarningModal={this.closeWarningModal} />
            <ModalContinuarInscricao  {... this.state} 
            continuarCadastrando={this.continuarCadastrando}
            continuarInscricao={this.continuarInscricao}
            closeModal={this.closeModal} />
                <div className="container" style={{textAlign: "center"}}>
                    {   

                        <HubspotForm  style={{marginLeft: "20px", padding: "20px"}}
                            portalId='3960387'
                            formId='2af9ed25-d72a-4158-a0d1-c57a4172334b'
                            onFormSubmit={(form) => setTimeout(() => this.handleSubmit(form), 2000)}                
                            onFormReady	={() => this.handleReady()}
                            loading={<div><Carregando/></div>}
                            />
                                
            
                    }
                    Campanha: {this.state.nomeCampanha}
                    <br />
                    Ação: {this.state.nomeAcaoComercial}
                    <br />
                    Recrutador: {this.state.nomeCaptador}
                </div>

                <style jsx>
                {`
                    @media(min-height: 895px){
                        footer{
                            position: absolute !important;
                            bottom: 0 !important;
                        }
                    }
                    /* HUBSPOT BUTTON */
                    .hs-button:hover, .hs-button:focus {
                      background-color: #ff8f73;
                      border-color: #ff8f73;
                  }
                  
                  .hs-button {
                    -webkit-border-radius: 15px;
                    -moz-border-radius: 15px;
                    border-radius: 15px;
                    background-clip: padding-box;
                    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
                    margin: 0;
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 700;
                    line-height: 12px;
                    position: relative;
                    text-align: center;
                    background-color: #ff7a59;
                    border-color: #ff7a59;
                    color: #fff;  
                    border-style: solid;
                    border-width: 1px;
                    font-size: 14px;
                    padding: 12px 24px;
                    bottom: 56px;
                  }
                  @media (max-width: 767px){
                    iframe{
                      width: 100% !important;
                      margin: 40px auto 50px auto !important;
                      padding-left: 30px;
                    }
                    .hbspt-form{
                      text-align: center !important; 
                    }
                  }
                  .total-width{
                    max-width: 100% !important;
                    width: 100% !important;
                  }
                  @media (min-width: 768px){
                    iframe{
                      width: 500px !important;
                      margin: 0 auto !important;
                      margin: 40px auto 50px auto !important;
                    } 
                  }
                `}
                </style>
            </Container>
        )
    }
}
