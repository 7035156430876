import React, { Component } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

import candidatoService from '../../services/processo-seletivo/candidato-service'
import { removerMascaras } from '../../utils/string';
import { tipoErro } from '../../components/utils/Warnings';

export default class Agendamento extends Component {
    constructor(props) {
        super(props)

        moment.locale('pt-br');
        this.diaPermitido = this.diaPermitido.bind(this);
        //this.enviarLead = this.enviarLead.bind(this);
        this.enviarCanditado = this.enviarCanditado.bind(this);
        this.abrirPreviewDadosCandidatoModal = this.abrirPreviewDadosCandidatoModal.bind(this);
        this.enviandoDados = this.enviandoDados.bind(this)

    }

    diaPermitido(date) {
        return this.props.diasPermitidos.find(dia => dia === date.weekday() + 1)
            ? true
            : false;
    }

    abrirPreviewDadosCandidatoModal(e) {
        e.preventDefault();
        this.props.definirEstadoComponentePai('callbackFunctionInsertCandidato', this.enviarCanditado)
        this.props.definirEstadoComponentePai('showPreviewDadosCandidatoModal', true)
    }

    enviarCanditado(e) {
        this.enviandoDados(true)
        if (e) e.preventDefault();
        const candidato = {
            idLead: this.props.lead.idLead,
            idProcessoSeletivo: this.props.tipoProva,
            idOfertaProcessoSeletivo: this.props.oferta.id,
            idInstituicaoMantida: this.props.idInstituicaoMantida,
            idLocalProva: this.props.localProva.id,
            dataProva: Object.keys(this.props.dataProva).length === 0 ? null : this.props.dataProva,
            horaProva: this.props.horarioProva.agendamento,
            inPermiteEnvioSms: true,
            nrInscricaoEnem: this.props.lead.nrInscricaoEnem,
            nrAnoEnem: this.props.idAlavanca === 2 ? this.props.lead.nrAnoEnem : null,

            //Endereço
            cep: removerMascaras(this.props.endereco.cep),
            logradouro: this.props.endereco.logradouro,
            numero: this.props.endereco.numero,
            complemento: this.props.endereco.complemento,
            bairro: this.props.endereco.bairro,
            municipio: this.props.endereco.idMunicipio,
            uf: this.props.endereco.idUf,

            cpf: this.props.lead.cpf,
            nome: this.props.lead.primeiroNome,
            telefone: this.props.lead.telefone,
            sobrenome: this.props.lead.sobrenome,
            email: this.props.lead.email,
            dataNascimento: this.props.lead.dataNascimento,
        }
        candidatoService.insert(candidato,this.props.openWarningModal)
            .then((paramsProcedure) => {
                this.props.definirEstadoComponentePai('idTitulo', paramsProcedure.idTitulo)
                this.props.definirEstadoComponentePai('idCandidato', paramsProcedure.idCandidato)
                this.props.definirEstadoComponentePai('txRetorno', paramsProcedure.txRetorno)
                this.props.definirEstadoComponentePai("inscricaoConcluida", true);
                this.enviandoDados(false);
                this.props.editarProgresso(null, 4, this.props.lead);
            })
            .catch((erro) => {
                this.props.openWarningModal(erro, tipoErro.erro, false)
                this.enviandoDados(false);
            })
    }

    enviandoDados(boolean) {
        this.props.definirEstadoComponentePai('isEnviandoDadosCandidato', boolean)
    }

    render() {

        if (this.props.escolheuModalidade) {
            return (
                <div>
                    <form autocomplete="off" id="agendamento" className="box_fade" onSubmit={(e) => this.enviarCanditado(e)} >
                        <h2 className="h3 text-blue mb-4">Agendamento</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="control-label" htmlFor="tipoProva">Tipo da prova</label>
                                    <select required className="form-control" id="tipoProva"
                                        name="tipoProva" onChange={this.props.onChange}
                                        disabled={this.props.opcoesTiposProva.length < 1}>
                                        <option value="0" >(Escolha um tipo da prova)</option>
                                        {this.props.opcoesTiposProva.map((tipoProva, i) => <option key={i} value={tipoProva.id}>{tipoProva.nomeTipoProcessoSeletivo}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="control-label" htmlFor="idLocalProva">Local da prova</label>
                                    <select required disabled={this.props.locaisProva.length < 1} className="form-control"
                                        id="idLocalProva" value={this.props.localProva.id || ''} name="idLocalProva" onChange={this.props.onChange}>
                                        <option value="0" >(Escolha um local)</option>
                                        {this.props.locaisProva.map((localProva, i) => <option key={i} value={localProva.id}>{localProva.nome}</option>)}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="control-label" htmlFor="idDataProva">
                                        Data da Prova
                                    {this.props.diasPermitidos.length > 0 && !this.props.dataMax._isValid ? <span style={{ marginLeft: '10px', color: '#ff8f00', fonWeight: 600 }}>Sem datas disponíveis para esse local de prova, favor, selecione outro local de prova</span> : ''}
                                    </label>
                                    <DatePicker className="form-control date-picker"
                                        dateFormat="DD/MM/YYYY"
                                        id="idDataProva"
                                        required
                                        name="idDataProva"
                                        disabled={!this.props.dataMax._isValid || this.props.diasPermitidos.length < 1 || this.props.desabilitarDatePicker}
                                        minDate={moment()}
                                        minDate={this.props.dataMin}
                                        maxDate={this.props.dataMax}
                                        filterDate={this.diaPermitido}
                                        excludeDates={this.props.datasBloqueadas}
                                        value={this.props.dataProva}
                                        selected={this.props.dataProvaDatePicker}
                                        onChange={this.props.onChange}
                                        placeholderText="(Escolha uma data)"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="control-label" htmlFor="idHorarioProva">Horário da Prova</label>
                                    <select required value={this.props.horarioProva.id} disabled={this.props.horariosProva.length < 1} className="form-control"
                                        id="idHorarioProva" name="idHorarioProva" onChange={this.props.onChange}>
                                        <option value="0" >(Escolha um horário de prova)</option>
                                        {this.props.horariosProva.map((horarioProva, i) => <option key={i} value={horarioProva.id}>{horarioProva.agendamento}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button id="btConfirmaInscricao" type="submit" className="btn btn-primary bt-confirmar" disabled={!this.props.horarioProva.id}>Confirmar</button>
                    </form>

                </div>
            )
        } else {
            return null;
        }
    }

}
