import React, { Component } from 'react'
import CurrencyFormat from 'react-currency-format';

import ofertaService from '../../services/comercial/oferta-service';
export default class CardModalidade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valores: [],
      oferta: {},
      idTurno: 0,
      idTurnoProcessoSeletivo: 0,
      idModalidadeEnsino: 0,
      turno: {},
      modalidade: {},
      reajuste: false,
      turnos: [],
      idCurso: 0,
    };

    this.onChange = this.onChange.bind(this);
    this.isMedicina = this.isMedicina.bind(this);
  }
  componentWillMount() {
    this.setState(
      {
        modalidade: this.props.modalidadeAtual,
        reajuste: this.props.reajuste,
        oferta: this.props.modalidadeAtual ? this.props.modalidadeAtual : {},
        // turno: this.props.modalidadeAtual ? this.props.modalidadeAtual.modalidadeTurnos[0] : {},
        // idTurno: this.props.modalidadeAtual ? this.props.modalidadeAtual.modalidadeTurnos[0].turnoId : 0,
        idModalidadeEnsino: this.props.modalidadeAtual
          ? this.props.modalidadeAtual.modalidadeEnsinoId
          : 0,
        idCurso: this.props.idCurso,
        idAlavanca: this.props.idAlavanca,
        idUnidadeEnsino: this.props.idUnidadeEnsinoProcessoSeletivo
          ? this.props.idUnidadeEnsinoProcessoSeletivo
          : 0,
      },
      async () => {
        this.obterTurnos(
          this.state.idAlavanca,
          this.state.idCurso,
          this.state.idUnidadeEnsino,
          this.state.idModalidadeEnsino
        );
      }
    );

    // this.obterTurnos(modalidade)
  }
  obterTurnos(idAlavanca, idCurso, idUnidadeEnsino, idModalidadeEnsino) {
    const criterioPesquisaTurnos = {
      idAlavanca: idAlavanca,
      idCurso: idCurso,
      idUnidadeEnsino: idUnidadeEnsino,
      idModalidadeEnsino: idModalidadeEnsino,
    };
    console.log(`console aqui: `, criterioPesquisaTurnos)
    ofertaService.findTurnosPorCurso(criterioPesquisaTurnos).then((turnos) => {
      if (turnos[0]) {
        this.setState({
          turnos: turnos,
          turno: turnos[0],
          idTurno: turnos[0].turnoId,
          idTurnoProcessoSeletivo: turnos[0].idTurnoProcessoSeletivo,
        });
        // this.renderizarTurnos(turnos)
      } else {
        //   this.openWarningModal(
        //     'Forma de ingresso não disponível para essa inscrição',
        //     tipoErro.alerta
        //   );
      }
    });
  }

  renderizarTurnos(turnos) {
    // console.log(turnos.length > 0 && turnos[0].turnoId);

    
    // this.setState({
    //   idTurno: turnos.length > 0 && turnos[0].turnoId
    // })
    return turnos.map((turno, i) => (
      <option key={i} value={turno.turnoId}>
        {turno.nome}
      </option>
    ));
    
  }

  renderizarValores(el, elAtual, idTurno) {
    idTurno = parseInt(idTurno, 10);

    return (
      <div className="mb-4" key={el.id}>
        <div className="opcao-preco textocentralizado">
          {this.state.turno.valorLiquido !== 0 &&
          this.state.turno.valorPromocional !== 0 ? (
            <h6 className="text-blue mb-2 textocentralizado">VALOR</h6>
          ) : (
            <></>
          )}

          {/* <span className="value value--old-usar-enquanto-nao-novo-campo">de <del>{this.state.turno.valorBruto}</del> por: </span>
                <span className="value value--new">R$ {this.state.turno.valorPromocional}</span> */}
          {/* Quando o novo campo estiver adicionado, trocar trecho de cima por trecho de baixo e 
                remover a classe value--old-usar-enquanto-nao-novo-campo do arquivo hotsite.css */}
          {/* <span className="value value--old">R$ {this.state.turno.valorBruto}</span> */}
          {this.state.turno.valorLiquido === 0 &&
          this.state.turno.valorPromocional === 0 ? (
            <></>
          ) : this.state.turno.valorLiquido !=
            this.state.turno.valorPromocional ? (
            <div>
              <span className="value value--new">
                <CurrencyFormat
                  value={
                    this.state.turno.valorLiquido
                      ? this.state.turno.valorLiquido
                      : 0
                  }
                  thousandSeparator=""
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  displayType={'text'}
                  prefix={'R$ '}
                />{' '}
                <i className="ofertaAsteristico">
                  {this.state.oferta.reajuste === false ? '*' : ''}
                </i>
              </span>
              <p className="dia-util">
                {' '}
                pagamentos realizados até o dia 10 de cada mês *
              </p>
              {this.state.turno.valorPromocional ? (
                <>
                  <span className="value value--desc">
                  <CurrencyFormat
                    value={
                      this.state.turno.valorPromocional
                        ? this.state.turno.valorPromocional
                        : 0
                    }
                    thousandSeparator=""
                    decimalScale={2}
                    fixedDecimalScale={true}
                    decimalSeparator=","
                    displayType={'text'}
                    prefix={'R$ '}
                  />{' '}
                  **
                </span>
              <p className="promocional"> Promocional</p>
              {this.state.turno.dataValidadePromocional ? (
                <p className="data-valor-promocional">
                  {/* Preço válido até {this.state.turno.dataValidadePromocional} */}
                  *Consulte o regulamento
                </p>
              ) : (
                ''
              )}
                </>
              ) : (<></>)}
              
            </div>
          ) : (
            <div>
              <span className="value value--desc">
                <CurrencyFormat
                  value={
                    this.state.turno.valorPromocional
                      ? this.state.turno.valorPromocional
                      : 0
                  }
                  thousandSeparator=""
                  decimalScale={2}
                  fixedDecimalScale={true}
                  decimalSeparator=","
                  displayType={'text'}
                  prefix={'R$ '}
                />
              </span>
              <p className="promocional">
                {' '}
                pagamentos realizados até o dia 10 de cada mês *
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }

  onChange(e, elAtual) {
    console.log(this.state.turnos);
    if (e.target.name.includes('idTurno')) {
      this.setState({
        [e.target.name]: e.target.value,
        turno: this.state.turnos.find(
          (turno) => turno.turnoId === parseInt(e.target.value, 10)
        ),       
        idTurnoProcessoSeletivo: this.state.turnos.find((t)=> t.turnoId === parseInt(e.target.value,10 )).idTurnoProcessoSeletivo || 0,
        oferta: elAtual,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  isMedicina() {
    return this.props.idCurso === 106;
  }

  render() {
    return (
      <div
        key={this.props.modalidadeAtual.id}
        className="col-md-4"
        id={'mod_' + this.props.modalidadeAtual.id}
      >
        <div>
          <div className="cards-modalidades">
            <div
              className={
                this.props.modalidadeAtual.modalidadeEnsinoId === 1 ||
                this.props.modalidadeAtual.modalidadeEnsinoId === 5
                  ? 'header-card header-card-presencial'
                  : 'header-card'
                  ? this.props.modalidadeAtual.modalidadeEnsinoId === 2 ||
                    this.props.modalidadeAtual.modalidadeEnsinoId === 6
                    ? 'header-card header-card-ead'
                    : 'header-card'
                    ? this.props.modalidadeAtual.modalidadeEnsinoId === 3 ||
                      this.props.modalidadeAtual.modalidadeEnsinoId === 6
                      ? 'header-card header-card-semi-presencial'
                      : 'header-card'
                    : 'header-card'
                  : 'header-card'
              }
            >
              <h2 id="{{modalidade.id}}">
                {this.props.modalidadeAtual.modalidadeEnsino}
              </h2>
            </div>
            <div className="card-body">
              <div className="mb-3">
                <h6 className="text-blue mb-2 textocentralizado turno">
                  TURNO
                </h6>
                <select
                  className="custom-select"
                  id="idTurno"
                  name="idTurno"
                  onChange={(e) => this.onChange(e, this.props.modalidadeAtual)}
                >
                  {this.renderizarTurnos(this.state.turnos)}
                </select>
              </div>
              {this.renderizarValores(
                this.props.modalidadeAtual,
                this.props.elAtual,
                this.props.idTurno
              )}
              {
                <a
                  className="btn btn-primary btn-block bt-inscrevase"
                  href={`${process.env.REACT_APP_URL_OUTR_CURS}?idps=-${this.state.turno.idProcessoSeletivo}-&codcurso=20-${this.props.idCurso}&turno=${this.state.idTurno == 9200 ? 92 : this.state.idTurno}&codcampus=20-${this.props.idUnidadeEnsinoProcessoSeletivo}&pidarea=${this.state.idTurnoProcessoSeletivo}`}
                  target="_blank"
                >
                  <strong>Inscreva-se já!</strong>
                </a>
              }
              {/* {this.isMedicina() &&
              this.props.modalidadeAtual.formaIngressoId != 3 ? (
                <a
                  className="btn btn-primary btn-block bt-inscrevase"
                  href="http://inscricao.unigranrio.edu.br/processoSeletivo/vestibular-inscricao?redir=false"
                >
                  <strong>Inscreva-se já!</strong>
                </a>
              ) : (
                <button
                  className="btn btn-primary btn-block bt-inscrevase"
                  id="selecionaVestibular"
                  onClick={(e) =>
                    this.props.selecionarVestibular(
                      this.props.modalidadeAtual.alavancaId,
                      this.props.modalidadeAtual,
                      this.state,
                      this.props.modalidadeAtual.formaIngressoId
                    )
                  }
                  type="button"
                >
                  <strong>Inscreva-se ss já!</strong>
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
