import { getBaseUrl, removerMascaras } from './string'
// import { apiBaseComercial } from '../config';

export function checkIsNotApiProd(){
    // if(!apiBaseComercial.includes('https')){
    //     return true
    // }

      return false
  }

  export function redirecionarPageVisualizacaoComprovante(candidato, openInSelfPage) {
    const {idInstituicaoMantida, id, dtNascimentoFormatoAmericano, documentoIdentificacao } = candidato    
    const stringFatenpCasoMantidaSejaFatenp = idInstituicaoMantida === 2 ? '-fatenp': ''
    window.open(`${getBaseUrl()}/candidato${stringFatenpCasoMantidaSejaFatenp}/${id}/${dtNascimentoFormatoAmericano}/${removerMascaras(documentoIdentificacao)}`, openInSelfPage && '_self');
    
}
  
  