import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Footer from './../Footer';
import Header from './../Header';
import BodyInformacoes from './BodyInformacoes';


class Container extends Component {
  render() {
    return (
      <section>
        <Header {...this.props} />
        <div className="ls-main"> 
        <BodyInformacoes /> 
        </div>
          <Footer {...this.props} />
      </section>
    )
  }
}

Container.propTypes = {
  children: PropTypes.element
};

export default Container;