import React, { Component } from 'react';
import PropTypes from 'prop-types';



import Footer from './Footer';
import Header from './Header';

class Container extends Component {
  render() {
    return (
      <section>
        <Header {...this.props} />
        <div className="ls-main">
          {this.props.children} 
        </div>
        <Footer {...this.props} />
      </section>
    )
  }
}

Container.propTypes = {
  children: PropTypes.element
};

export default Container;