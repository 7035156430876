
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';

const candidatoDisciplinaExternaPeriodoLetivoService = {};

 candidatoDisciplinaExternaPeriodoLetivoService.insertArray =  function(dadosCandidatoDisciplinaExternaPeriodoLetivoArray){
    return api.postBaseURL('/candidato-disciplinas-externa-periodo-letivo/array', dadosCandidatoDisciplinaExternaPeriodoLetivoArray,apiBaseProcessoSeletivo)
    .then((idsCandidatoDisciplinaExternaPeriodoLetivo) => {                 
    return idsCandidatoDisciplinaExternaPeriodoLetivo
    });    
}

candidatoDisciplinaExternaPeriodoLetivoService.searchAll =  function(dadosCandidatoDisciplinaExternaPeriodoLetivoArray){
    return api.postBaseURL('/candidato-disciplinas-externa-periodo-letivo/searchAll', dadosCandidatoDisciplinaExternaPeriodoLetivoArray,apiBaseProcessoSeletivo)
    .then((candidatoDisciplinasExternaPeriodoLetivo) => {                 
    return candidatoDisciplinasExternaPeriodoLetivo
    });    
}

candidatoDisciplinaExternaPeriodoLetivoService.carregarDisciplinasCadastradas = function(idCandidato){
    const previewDadosSalvos = []
    return candidatoDisciplinaExternaPeriodoLetivoService.searchAll({idCandidato:idCandidato})
    .then(candidatoDisciplinasExternaPeriodoLetivo => {  
    const agrupadores = this.agruparDisciplinasByIdCursoExternoAndIdInstituicao(candidatoDisciplinasExternaPeriodoLetivo)  
    agrupadores.map(agrupador =>{    
      previewDadosSalvos.push({
        periodoLetivo:agrupador.nomePeriodoLetivo,
        siglaUf:agrupador.siglaUf,
        instituicao:agrupador.nomeInstituicaoEnsino,
        disciplinas:agrupador.disciplinas,
        showAccordion:false
            })
        })        
       return previewDadosSalvos 
    })
  }
  candidatoDisciplinaExternaPeriodoLetivoService.agruparDisciplinasByIdCursoExternoAndIdInstituicao = function(candidatoDisciplinasExternaPeriodoLetivo){
     let agrupadores = []
     if(candidatoDisciplinasExternaPeriodoLetivo.length > 0){
       
       agrupadores = candidatoDisciplinasExternaPeriodoLetivo.filter((thing, index, self) =>
        index === self.findIndex((t) => (
        t.idInstituicaoEnsino === thing.idInstituicaoEnsino && t.idPeriodoLetivo === thing.idPeriodoLetivo
      ))
      ).map(el => {       
        return {
          id:el.id,
          idPeriodoLetivo:el.idPeriodoLetivo,
          nomePeriodoLetivo:el.nomePeriodoLetivo,
          idInstituicaoEnsino:el.idInstituicaoEnsino,
          nomeInstituicaoEnsino:el.nomeInstituicaoEnsino,
          siglaUf:el.siglaUfInstituicaoEnsino,
          disciplinas:[]}})    
      agrupadores.forEach((agrupador,index) => {
        candidatoDisciplinasExternaPeriodoLetivo.filter(elemento => {		
      if(agrupador.idPeriodoLetivo === elemento.idPeriodoLetivo && agrupador.idInstituicaoEnsino === elemento.idInstituicaoEnsino){	          
        agrupadores[index].disciplinas
          .push({nome:elemento.nomeDisciplinaExterna,codigo:elemento.codigoDisciplinaExterna,cargaHoraria:elemento.cargaHorariaDisciplinaExterna}) 
          }
        })
      })
     }
    return agrupadores
  }
   

export default  candidatoDisciplinaExternaPeriodoLetivoService;