
// import api from '../../utils/api';

// import {apiBaseEstruturaGlobal} from '../../config';


const municipioService = {};

// municipioService.listAllByEntity =  function(dadosMunicipio){
//     return api.postBaseURL('/municipios', dadosMunicipio, apiBaseEstruturaGlobal)
//     .then(municipios => {
//         return municipios;
//     });    
// }


export default municipioService;