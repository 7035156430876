import React, {Component} from 'react';
import municipioService from '../../services/estrutura-global/municipio-service';
import ufService from '../../services/estrutura-global/uf-service';
import enderecoService from '../../services/estrutura-global/endereco-service';
import {removerMascaras} from '../../utils/string';
import { tipoErro } from '../../components/utils/Warnings';
import MaskedInput from 'react-text-mask'

export default class Endereco extends Component {
    constructor(props) {
        super(props)

        this.state = {
            ufsOptionsHtml: [],
            ufs:[],
            arrayUfsOnlyObjects:[],
            municipios: [],
            cepInexistente: false
        }

        this.onBlur = this.onBlur.bind(this);
        this.onChangeUf = this.onChangeUf.bind(this);
        this.onChangeMunicipio = this.onChangeMunicipio.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeNumeroEndereco = this.onChangeNumeroEndereco.bind(this);
    }

 
    componentDidMount() {        
        this.obterUfs();                       
        if(this.props.endereco.idMunicipio && this.props.endereco.idUf){
            this.obterMunicipios(this.props.endereco.idUf)
        }
        
    }

    rendenrizarOptionsCombo(list) {
        return list.map((el, i) => (
            <option key={i} value={el.id}>
                {el.nome}
            </option>
        ));
    }

    rendenrizarOptionsComboUf(list) {
        return list.map((el, i) => (
            <option key={i} value={el.id}>
                {el.sigla}
            </option>
        ));
    }

    obterUfs() {
        ufService.listAll()
        .then(ufs =>{
            this.setState({ ufsOptionsHtml: this.rendenrizarOptionsComboUf(ufs),ufs:ufs });
        })
    }

    obterMunicipios(idUf) {
        const dadosMunicipio = {
            idUf: idUf
        }
        municipioService.listAllByEntity(dadosMunicipio)        
            .then(municipios => {
                this.setState({ municipios: this.rendenrizarOptionsCombo(municipios) });
            });
    }

    onBlur(e) {
        if (!e.target.value || removerMascaras(e.target.value).length !== 8) return;

        if (e.target.name === 'cep') {
            enderecoService.findOneByCep({ cep: removerMascaras(e.target.value) })            
                .then(res => {
                    const endereco = this.props.endereco;
                    
                    if (!res.length) {
                        endereco.bairro = '';
                        endereco.logradouro = '';
                        endereco.idEndereco = '';
                        endereco.idMunicipio = 0;
                        endereco.idUf = 0;
                        endereco.nomeMunicipio = '';
                        this.props.definirEstadoComponentePai("endereco", endereco);
                        this.setState({ municipios: [] });
                                                
                        this.props.openWarningModal('CEP inexistente na nossa base de dados, digite o endereço manualmente',tipoErro.alerta)
                        this.trocarEstadoCepInexistente(true)
                    } else {
                        this.obterMunicipios(res[0].idUf)
                        
                        endereco.bairro = res[0].bairro;
                        endereco.logradouro = res[0].nome;
                        endereco.idEndereco = res[0].id;
                        endereco.idUf = res[0].idUf;

                        endereco.idMunicipio = res[0].idMunicipio;
                        endereco.nomeMunicipio = res[0].nomeMunicipio;
                        endereco.sgUf = res[0].siglaUf;
                        endereco.nomeEstado = this.getNomeUfSelecionada(res[0].idUf);
                        endereco.nomeMunicipio = res[0].nomeMunicipio;
                        this.setState({ endereco: endereco});
                        this.props.definirEstadoComponentePai("endereco", endereco);
                        
                        // setTimeout(function () {
                        //     endereco.idMunicipio = res[0].idMunicipio;
                        //     endereco.nomeMunicipio = res[0].nomeMunicipio;
                        //     endereco.sgUf = res[0].siglaUf;
                        //     this.setState({ endereco: endereco});
                        //     this.props.definirEstadoComponentePai("endereco", endereco);
                        // }.bind(this), 500);
                    }
                });               
        }
    }

    onChange(e) {
        const endereco = this.props.endereco;
        endereco[e.target.name] = e.target.value
        this.props.definirEstadoComponentePai("endereco", endereco);
    }

    onChangeUf(e) {
        const endereco = this.props.endereco;
        endereco.idUf = e.target.value;
        const sgUf = e.target.options[e.target.selectedIndex].text;
        endereco.nomeEstado = this.getNomeUfSelecionada(endereco.idUf)
        this.obterMunicipios(e.target.value);
        this.props.definirEstadoComponentePai("endereco", endereco);
        this.props.definirEstadoComponentePai("sgUf", sgUf);
    }

    onChangeMunicipio(e) {
        const endereco = this.props.endereco;
        endereco.idMunicipio = e.target.value;
        const nomeMunicipio = e.target.options[e.target.selectedIndex].text;
        endereco.nomeMunicipio = nomeMunicipio;

        this.props.definirEstadoComponentePai("endereco", endereco);
        this.props.definirEstadoComponentePai("nomeMunicipio", nomeMunicipio);
    }

    onChangeNumeroEndereco(e) {                
        const endereco = this.props.endereco;
        endereco[e.target.name] = e.target.value.replace(/\W/g,'')
        this.setState({ endereco: endereco });
    }

    trocarEstadoCepInexistente(cepInexistente) {
        this.setState({ cepInexistente: cepInexistente })
    }

    getNomeUfSelecionada(idUfSelecionada){
        return this.state.ufs.find(uf => uf.id == idUfSelecionada).nome
    }

    render() {
        return (
            <div>
            <div className="row">
               <div className="col-md-2">
                  <div className="form-group">
                     <label className="control-label" htmlFor="cep">CEP</label>
                    <button style={{marginLeft:'10px'}} onClick={()=> window.open("http://www.buscacep.correios.com.br/sistemas/buscacep/")} className="cep-consultation__button" data-toggle="modal">
				     Não sei meu cep
			        </button>
                     <MaskedInput
                     pattern="[0-9]{5}-[0-9]{3}"
                     mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                     className="form-control required" required id="cep" name="cep" type="text" value={this.props.endereco.cep} onChange={this.onChange} onBlur={this.onBlur}
                     />
                  </div>
               </div>
               <div className="col-md-5">
                  <div className="form-group">
                     <label className="control-label" htmlFor="logradouro">Logradouro</label>
                     <input className="form-control required" disabled={!this.state.cepInexistente} required id="logradouro" name="logradouro" type="text" value={this.props.endereco.logradouro} onChange={this.onChange} />
                  </div>
               </div>
               <div className="col-md-5">
                  <div className="form-group">
                     <label className="control-label" htmlFor="bairro">Bairro</label>
                     <input className="form-control required" disabled={!this.state.cepInexistente} required id="bairro" name="bairro" type="text" value={this.props.endereco.bairro} onChange={this.onChange} />
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-md-2">
                  <div className="form-group">
                     <label className="control-label" htmlFor="uf">UF</label>
                     <select required className="form-control required" disabled={!this.state.cepInexistente} id="uf" name="uf"
                        value={this.props.endereco.idUf} onChange={this.onChangeUf}>
                        <option value="0">Selecione...</option>
                        {this.state.ufsOptionsHtml}
                     </select>
                  </div>
               </div>
               <div className="col-md-3">
                  <div className="form-group">
                     <label className="control-label" htmlFor="municipio">Município</label>
                     <select required className="form-control required" disabled={!this.state.cepInexistente} id="municipio" name="municipio"
                        value={this.props.endereco.idMunicipio} onChange={this.onChangeMunicipio}>
                        <option value="">Selecione...</option>
                        {this.state.municipios}
                     </select>
                  </div>
               </div>
               <div className="col-md-2">
                  <div className="form-group">
                     <label className="control-label" htmlFor="numero">Número da residência</label>
                     <input className="form-control required" required maxLength="7" id="numero" name="numero" type="text" value={this.props.endereco.numero} onChange={this.onChangeNumeroEndereco} />
                  </div>
               </div>
               <div className="col-md-5">
                  <div className="form-group">
                     <label className="control-label" htmlFor="complemento">Complemento</label>
                     <input className="form-control" id="complemento" name="complemento" type="text" value={this.props.endereco.complemento} onChange={this.onChange} />
                  </div>
               </div>
            </div>
         </div>
            
        );
    }
}