import React, { Component } from 'react'
import loader from '../../images/ajax-loader.gif'
export default class Carregando extends Component {
    constructor(props){
        super(props)
    }
    render() {
        return (
            <div style={{ textAlign: "center" }}>
                <img src={loader} alt="" /> <br /> <i>{this.props.message || 'Carregando...'}</i>
                <br />
            </div>
        )
    }
}