
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';


const horarioProvaService = {};

horarioProvaService.findByEntity =  function(dadosHorarioProva){
    return api.postBaseURL('/horario-prova/search', dadosHorarioProva,apiBaseProcessoSeletivo)
            .then((horariosProva) => {                
            return horariosProva;               
    });    
}

export default horarioProvaService;