
// import api from '../../utils/api';

import {apiBaseEstruturaGlobal} from '../../config';


const instituicaoEnsinoService = {};

// instituicaoEnsinoService.findByEntityInEsinoSuperior =  function(dadosInstituicaoEnsino){
//     return api.postBaseURL('/instituicoesEnsino/search/ensino-superior', dadosInstituicaoEnsino, apiBaseEstruturaGlobal)
//     .then(instituicoesEnsino => {
//         return instituicoesEnsino;
//     });    
// }

// instituicaoEnsinoService.findByEntityInEnsinoMedio =  function(dadosInstituicaoEnsino){
//     return api.postBaseURL('/instituicoesEnsino/search/ensino-medio', dadosInstituicaoEnsino, apiBaseEstruturaGlobal)
//     .then(instituicoesEnsino => {
//         return instituicoesEnsino;
//     });    
// }


export default instituicaoEnsinoService;