import React, {Component} from 'react';

export default class BodyInformacoes extends Component{
    render(){
        return(
            <div className="main">
                <div className="hero mb-5">
                    <div className="container">
                        <h1>Editais e Regulamentos</h1>
                    </div>
                </div>
                <div className="container">
                    <p>
                        Ao se inscrever no <strong>PROCESSO DE SELEÇÃO</strong>, o candidato
                        se habilita a participar, de acordo com o seu interesse na modalidade
                        ofertada, considerando as limitações impostas pelo calendário, das
                        atividades assim programadas.
                    </p>
                    <br />
                    <h5 className="mb-3">Requisitos para a inscrição:</h5>
                    <ol>
                        <li>Já ter concluído o ensino médio (2º Grau) ou equivalente;</li>
                        <li>Documento oficial de identidade;</li>
                        <li>Cópia colorida da cédula de identidade totalmente legível e
                            colorida;</li>
                        <li>Dispor de recursos para pagar a taxa fixada no Edital CESU;</li>
                        <li>Boletim com o resultado do ENEM, caso seja a modalidade
                            escolhida;</li>
                        <li>Portador de necessidades educacionais específicas, ter sido
                            previamente entrevistado pela Coordenação do curso de seu interesse e
                            estar devidamente informado das exigências e possíveis e
                            circunstanciais limitações decorrentes do perfil profissiográfico
                            traçado pelo Projeto Pedagógico;</li>
                        <li>Caso tenha cursado o equivalente ao médio no Exterior,
                            apresentar documento que ateste a equivalência expedida por órgão
                            competente de Governo Estadual (Secretaria de Educação).</li>
                    </ol>
                    <p>
                        <a className="btn btn-primary"
                            href="http://unigranrio.com.br/_docs/edital/edital-nead-201913%20V3.pdf"
                            target="_blank" rel="noopener noreferrer">Baixar o regulamento completo</a>
                    </p>
                    <br />
                    <h5 className="mb-3">Período de Inscrição:</h5>
                    <p>
                        As provas de seleção serão realizadas nas datas informadas nos atos de
                        inscrição e se repetirão, quando necessário, em edições posteriores,
                        nas datas divulgadas no Portal da UNIGRANRIO (<a
                            href="http://www.unigranrio.com.br" target="_blank" rel="noopener noreferrer">www.unigranrio.br</a>)
                        até o preenchimento total das vagas ou, se o for o caso, aos limites
                        impostos pelo calendário acadêmico.
                    </p>
                    <br />
                    <h5 className="mb-3">Matrícula:</h5>
                    <p>Os classificados obterão o material a ser utilizado na matrícula
                        pela Internet, incluindo o boleto de pagamento da primeira parcela da
                        semestralidade e serão orientados de como obtê-lo e dos prazos para 
                        tanto nos locais em que se submeteram à prova de seleção.</p>
                    <br />
                    <h5 className="mb-3">Baixar o Edital do Vestibular da UNIGRANRIO:</h5>
                    <ul>
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/Edital%202020.2%20-%20MODALIDADE%20A%20-%20ENEM%20voltada%20aos%20Cursos%20presenciais,%20exceto%20Medicina.%202020.2.pdf" alt="Edital 2020.2 - MODALIDADE A - ENEM voltada aos Cursos presenciais, exceto Medicina. 2020.2"  title=" Edital 2020.2 - MODALIDADE A - ENEM voltada aos Cursos presenciais, exceto Medicina. 2020.2" target="_blank"> Edital 2020.2 - MODALIDADE A - ENEM voltada aos Cursos presenciais, exceto Medicina. 2020.2</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/Edital%202020.2%20%20-%20Demais%20Cursos%20presenciais.pdf" alt="Edital 2020.2  - Demais Cursos presenciais"  title="Edital 2020.2 - Demais Cursos presenciais" target="_blank"> Edital 2020.2 - Demais Cursos presenciais</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/EDITAL%2020202%20SEMI.pdf" alt="EDITAL 20202 SEMI"  title="EDITAL 20202 SEMI" target="_blank"> EDITAL 20202 SEMI</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/EDITAL%2020202%20EAD.pdf" alt="EDITAL 20202 EAD"  title="EDITAL 20202 EAD" target="_blank">  EDITAL 20202 EAD</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/medicina2020/EDITAL%202020.2%20-%20Medicina%20(Atualizado).pdf" alt="EDITAL 2020.2 - Medicina (Versão atualizada)"  title="EDITAL 2020.2 - Medicina (Versão atualizada)" target="_blank"> EDITAL 2020.2 - Medicina (Versão atualizada)</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/medicina2020/Edital%20do%20Vestibular%20de%20Medicina%202020.2.pdf" alt="EDITAL 2020.2 - Medicina"  title="EDITAL 2020.2 - Medicina" target="_blank"> EDITAL 2020.2 - Medicina</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/Portaria%20GRU%20n%C2%BA30-20%2025%20de%20maio%20de%202020.pdf" alt="Portaria GRU nº30 20, de 25 de maio de 2020"  title="Portaria GRU nº30 20, de 25 de maio de 2020" target="_blank"> Portaria GRU nº30 20, de 25 de maio de 2020</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/Portaria%20GRU%20n26-20.pdf" alt="Portaria GRU nº26 20, de 6 de maio de 2020"  title="Portaria GRU nº26 20, de 6 de maio de 2020" target="_blank"> Portaria GRU nº26/20, de 6 de maio de 2020</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/Portaria%20GRU%20n%C2%BA25%2020,%20de%2025%20de%20mar%C3%A7o%20de%202020.pdf" alt="Portaria GRU nº25 20, de 25 de março de 2020"  title="Portaria GRU nº25 20, de 25 de março de 2020" target="_blank"> Portaria GRU nº25/20, de 25 de março de 2020</a></li> 
                        <li><a href="http://unigranrio.com.br/_docs/edital/2020/REGULAMENTO%20DO%20PROCESSO%20SELETIVO.pdf" alt="EDITAL - CONVOCAÇÃO PARA INGRESSO VIA VESTIBULAR NOS CURSOS DE MEDICINA"  title="EDITAL - CONVOCAÇÃO PARA INGRESSO VIA VESTIBULAR NOS CURSOS DE MEDICINA" target="_blank"> REGULAMENTO DO PROCESSO SELETIVO</a></li> 
                    </ul>                   
                </div>
            </div>
        )
    }
}