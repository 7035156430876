import React, { Component } from 'react';
import HeaderFatenp from './headers/HeaderFatenp';
import HeaderUnigranrio from './headers/HeaderUnigranrio';

export default class Header extends Component {
  render() {
    return (
      <div>
        {this.props.location && (this.props.location.pathname.includes('fatenp') || (this.props.unidade && (this.props.unidade.codigo === '30' ||  this.props.unidade.codigo === '31'))) ? <HeaderFatenp {... this.props} /> : <HeaderUnigranrio {... this.props} />}
      </div>
    )
  }
}




