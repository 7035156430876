
// import api from '../../utils/api';

// import {apiBaseFinanceiro} from '../../config';


const registroTituloService = {};

// registroTituloService.insert =  function(dadosRegistroTitulo){
//     return api.postBaseURL('/registroTitulo/insert', dadosRegistroTitulo, apiBaseFinanceiro)
//       .then(res => {
//         const returnParamsProcedure ={
//         idRegistroTitulo : res.find(el => el.parameterName === 'prm_id_registro_titulo').value,
//         idRegistroTituloCartao : res.find(el => el.parameterName === 'prm_id_registro_titulo_cartao').value
//         }
//         return returnParamsProcedure       
//     });    
// }


export default registroTituloService;