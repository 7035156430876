import React, { Component } from "react";
import Show from "../operator/Show";
import Dropdown from 'react-bootstrap/Dropdown';

export default class CardVestibular extends Component {
  constructor(props) {
    super(props);
}

  render() {
    return (
      <section className="signup-btn clearfix magictime spaceInRight" style={{zIndex: 3}}>
        <div className="signup-btn-cta align-items-center">
          <div className="row">
            <div className="col-sm-6">
              <p>
                <h6 className="mb-1"></h6>
                <span className="title-card">Vestibular</span>
              </p>
            </div>
            <Show show={this.props.idMantida === 1}>
              <div
                className="col-sm-3 mb-1"
                style={{ paddingRight: '7px', paddingLeft: '7px' }}
              >
                {/* Comentado o código e duplicada a linha apontando para página de medicina a pedido do MKT: Chamado 63132 */}
                {/* <a onClick={(e) => this.props.selecionaAlavanca(e,999)} href="" data-toggle="modal" data-target="#signup-modal"> */}
                {/* <a href="http://inscricao.unigranrio.com.br/processoSeletivo/vestibular-inscricao" target="_blank"  data-toggle="modal" data-target="#signup-modal">
                  <button className="btn btn-enem-vestibular btn-block" type="button">
                      <strong>Medicina s</strong>
                  </button>
              </a> */}

                {/* <a
                  href={`${process.env.REACT_APP_URL_PROC_SEL_MED}`}
                  target="_blank"
                  data-toggle="modal"
                  data-target="#signup-modal"
                >
                  <button
                    className="btn btn-enem-vestibular btn-block"
                    type="button"
                  >
                    <strong>Medicina</strong>
                  </button>
                </a> */}

<Dropdown>
                        <Dropdown.Toggle id="dropdown-basic" className='btn btn-block btn-enem-vestibular'>
                          Medicina
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropdown-menu p-0 show text-center w-100'>
                          <Dropdown.Item href="http://processoseletivo.unigranrio.edu.br/?idPs=2593&pidarea=2743" className='btn btn-block btn-enem-vestibular m-0' target={'_blank'}>Barra da Tijuca</Dropdown.Item>
                          <Dropdown.Item href="http://processoseletivo.unigranrio.edu.br/?idPs=2594&pidarea=2717" className='btn btn-block btn-enem-vestibular m-0' target={'_blank'}>Duque de Caxias</Dropdown.Item>
                          
                        </Dropdown.Menu>
                      </Dropdown>
              </div>
            </Show>
            <div
              className={`${
                this.props.idMantida === 1 ? '' : 'offset-3'
              } col-sm-3 mb-1`}
              style={{ paddingRight: '7px', paddingLeft: '7px' }}
            >
              <a
                onClick={(e) => this.props.selecionaAlavanca(e, 6)}
                href=""
                data-toggle="modal"
                data-target="#signup-modal"
              >
                <button
                  className="btn btn-enem-vestibular btn-block"
                  type="button"
                >
                  <strong>
                    {this.props.idMantida === 1
                      ? 'Outros Cursos'
                      : 'Inscreva-se'}
                  </strong>
                </button>
              </a>
            </div>
          </div>
        </div>
        <small className="pre-inscricao bg-blue">
          {' '}
          * Cursos com condições especiais.
        </small>
        {/* <ModalFormLead history={this.props.history} handleCloseModal={this.handleCloseModal} idAlavanca={this.state.idAlavanca} show={this.state.show} /> */}
      </section>
    );
  }
}
