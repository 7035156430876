
import api from '../../utils/api';

import {apiBaseProcessoSeletivo} from '../../config';

const disciplinaExternaPeriodoLetivoService = {};

const BASE_URL = '/disciplinas-externa-periodo-letivo'

disciplinaExternaPeriodoLetivoService.filterByDisciplinaExterna =  function(dadosDisciplinaExternaPeriodoLetivo){
    return api.postBaseURL(`${BASE_URL}/filter-by-disciplina-externa`, dadosDisciplinaExternaPeriodoLetivo,apiBaseProcessoSeletivo)
            .then((disciplinasExternaPeriodoLetivo) => {                                    
            return disciplinasExternaPeriodoLetivo;               
    });    
}

disciplinaExternaPeriodoLetivoService.insert =  function(dadosDisciplinaExterna){
    return api.postBaseURL(`${BASE_URL}`, dadosDisciplinaExterna,apiBaseProcessoSeletivo)
            .then((res) => { 
                const paramsProcedure = {                    
                    idDisciplinaExternaPeriodoLetivo  : res.find(el => el.parameterName === 'id_disciplina_externa_periodo_letivo').value,
                    txRetorno:res.find(el => el.parameterName === 'prm_tx_retorno').value
            }            
            return paramsProcedure
    });    
}

disciplinaExternaPeriodoLetivoService.insertArray =  function(dadosDisciplinaExternaPeriodoLetivoArray){
    return api.postBaseURL('/disciplinas-externa-periodo-letivo/array', dadosDisciplinaExternaPeriodoLetivoArray,apiBaseProcessoSeletivo)
            .then((idsDisciplinaExternaPeriodoLetivo) => {                 
            return idsDisciplinaExternaPeriodoLetivo
    });    
}   

export default disciplinaExternaPeriodoLetivoService;