import React, { Component } from 'react';

import ContainerInformacoes from '../../components/informacoes/ContainerInformacoes';

export default class Informacoes extends Component {

    render() {
        return (
            <ContainerInformacoes {... this.props} />
        )
    }
}