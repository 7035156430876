
// import api from '../../utils/api';
// import {apiBaseComercial} from '../../config';
import utilsFormHubSpot from '../../utils/utils-form-hubspot';
import {removerMascaras} from '../../utils/string'

const contatoService = {};

// contatoService.insert =  function(lead){
//     return api.postBaseURL('/comercial/contato/insert', lead, apiBaseComercial)
//         .then((res)=>{      
//             lead.idLead = res[2].value;        
//             return lead;            
//         })
// }

// contatoService.update =  function(lead,idLead){
//     return api.putBaseURL(`/comercial/contato/update/${idLead}`, lead, apiBaseComercial)
//             .then(() => {
                
//             }).catch((err) => {
//                 if (err.message === "Sem token" || err.status === 401) {
//                     api.authenticar()
//                         .then(() => {
//                             return contatoService.update(lead,idLead);
//                         })
//                 }

//                 this.setState({ carregando: false })                
//                 this.props.openWarningModal(null,null,true)              

//             })
// }


contatoService.montarLead = function(form,acaoComercialCaptador){
    const formSerializado  = form.serializeArray() 
        if(acaoComercialCaptador){
            const lead = {
              primeiroNome:utilsFormHubSpot.encontrarAtributo(formSerializado,'firstname'),
              sobrenome:utilsFormHubSpot.encontrarAtributo(formSerializado,'lastname'),
              email:utilsFormHubSpot.encontrarAtributo(formSerializado,'email'),
              telefone:utilsFormHubSpot.encontrarAtributo(formSerializado,'phone'),          
              nomeCurso:utilsFormHubSpot.encontrarTextCurso(form),
              idCurso:parseInt(utilsFormHubSpot.encontrarAtributo(formSerializado,'curso_recrutador'), 10),
              idCampanhaComercial:acaoComercialCaptador.idCampanhaComercial,
              idAcaoComercial:acaoComercialCaptador.idAcaoComercial,
              idCaptador:acaoComercialCaptador.idCaptador,
              cpf:removerMascaras(utilsFormHubSpot.encontrarAtributo(formSerializado,'cpf'))       
            }                        
            return lead
        }
        const lead = {
            primeiroNome:utilsFormHubSpot.encontrarAtributo(formSerializado,'firstname'),
            sobrenome:utilsFormHubSpot.encontrarAtributo(formSerializado,'lastname'),
            email:utilsFormHubSpot.encontrarAtributo(formSerializado,'email'),
            telefone:utilsFormHubSpot.encontrarAtributo(formSerializado,'phone')    
            }
            return  lead;        
}

export default contatoService;