import React, { Component } from 'react'
import instituicaoEnsinoService from '../../services/estrutura-global/instituicao-ensino-service';
import disciplinaExternaService from '../../services/processo-seletivo/disciplina-externa-service'
import AsyncSelect from 'react-select/lib/Async'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { confirmAlert } from 'react-confirm-alert';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import CardDadosDisciplinasCursadas from './CardDadosDisciplinasCursadas';
import Carregando from '../../components/utils/Carregando';
import ModalPreviewDisciplinasCursadas from '../candidato/previews/ModalPreviewDisciplinasCursadas';
import periodoLetivoService from '../../services/processo-seletivo/periodo-letivo-service';
import disciplinaExternaPeriodoLetivoService from '../../services/processo-seletivo/disciplina-externa-periodo-letivo-service';
import candidatoDisciplinaExternaPeriodoLetivoService from '../../services/processo-seletivo/candidato-disciplina-externa-periodo-letivo-service';
import ufService from '../../services/estrutura-global/uf-service'
import { noOptionsMessage } from '../../utils/select';
import { FaHandPointRight } from 'react-icons/fa';
export default class FormDisciplinasCursadas extends Component {
  constructor(props) {
    super(props)
    this.state = {
      incrementIdDisciplina: 0,      
      showModalPreviewDisciplinasCursadas: false,
      isProcessandoDisciplinas: false,
      message: 'Processando as disciplinas',
      instanceTimeoutAnimation:'',
      isAnmationActivated:false,
      showAccordionDisciplinas: true,
      instituicoesOculta: false,
      disciplinas: [],
      disciplinaAprovada: {
        idInstituicaoEnsino: '',
        idPeriodoLetivo: '',
        idUf: '',
        idsDisciplina: [],
      },
      inputsDisciplina: {
        nomeDisciplina: '',
        codigo: '',
        cargaHoraria: ''
      },
      previewDadosSalvos: [],
      optionInstituicaoEscolhida: '',
      optionUfEscolhido: '',
      optionPeriodoLetivoEscolhido: '',      
      isCarregandoDisciplinas: true,
      ultimoCadastro: false,
      cadastroFinalizado: false,
    }
    this.definirEstadoComponentePai = this.definirEstadoComponentePai.bind(this)
    this.adicionarNovaDisciplina = this.adicionarNovaDisciplina.bind(this);
    this.zerarStatesDoFormulario = this.zerarStatesDoFormulario.bind(this)
    this.closeModalPreviewDisciplinasCursadas = this.closeModalPreviewDisciplinasCursadas.bind(this)
    this.cadastrarDisciplinas = this.cadastrarDisciplinas.bind(this)
    this.openToastConfirmationNoDisciplina = this.openToastConfirmationNoDisciplina.bind(this)
    this.deleteDisciplina = this.deleteDisciplina.bind(this)
    this.preencherInputsDisciplinasComDisciplinaEscolhida = this.preencherInputsDisciplinasComDisciplinaEscolhida.bind(this);
    this.onFocusSelect = this.onFocusSelect.bind(this)
    this.onCloseToastWithDisciplinas = this.onCloseToastWithDisciplinas.bind(this)
  }
  componentDidMount() {
    candidatoDisciplinaExternaPeriodoLetivoService
      .carregarDisciplinasCadastradas(this.props.candidato.id)
      .then(previewDadosSalvos => this.setState({ previewDadosSalvos: previewDadosSalvos }))


  }

  onChange(e, rotuloAtributo, optionSelect) {    
    const tempDisciplinaAprovada = this.state.disciplinaAprovada
    let tempOptionSelect = this.state[optionSelect]
    if (!e) {
      this.handleClearCombo(tempDisciplinaAprovada, rotuloAtributo, tempOptionSelect, optionSelect)
      return
    } else {
      tempDisciplinaAprovada[rotuloAtributo] = e.value
      tempOptionSelect = e
      // Caso de change uf com instituição selecionada, limpar instituicao      
      if(optionSelect === 'optionUfEscolhido' && this.state.optionInstituicaoEscolhida){        
        tempDisciplinaAprovada.idInstituicaoEnsino = ''
        this.setState({optionInstituicaoEscolhida:''})
      }

    }
    this.setState({ disciplinaAprovada: tempDisciplinaAprovada, [optionSelect]: tempOptionSelect }, () => {
      if (this.state.disciplinaAprovada.idPeriodoLetivo &&
        this.state.disciplinaAprovada.idInstituicaoEnsino &&
        this.state.disciplinaAprovada.idUf) {
        this.obterDisciplinasExterna()
      }
    })
    return;
  }
  definirEstadoComponentePai(campo, valor) {
    this.setState({ [campo]: valor });
  }
  carregarDisciplinasCadastradas() {
    const tempPreviewDadosSalvos = this.state.previewDadosSalvos
    candidatoDisciplinaExternaPeriodoLetivoService.searchAll({ idCandidato: this.props.candidato.id })
      .then(candidatoDisciplinasExternaPeriodoLetivo => {
        const agrupadores = this.agruparDisciplinasByIdCursoExternoAndIdInstituicao(candidatoDisciplinasExternaPeriodoLetivo)
        agrupadores.map(agrupador => {
          tempPreviewDadosSalvos.push({
            periodoLetivo: agrupador.nomePeriodoLetivo,
            siglaUf: agrupador.siglaUf,
            instituicao: agrupador.nomeInstituicaoEnsino,
            disciplinas: agrupador.disciplinas,
            showAccordion: false
          })
        })
        this.setState({ previewDadosSalvos: tempPreviewDadosSalvos })
      })
  }
  agruparDisciplinasByIdCursoExternoAndIdInstituicao(candidatoDisciplinasExternaPeriodoLetivo) {
    let agrupadores = []
    if (candidatoDisciplinasExternaPeriodoLetivo.length > 0) {

      agrupadores = candidatoDisciplinasExternaPeriodoLetivo.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.idInstituicaoEnsino === thing.idInstituicaoEnsino && t.idPeriodoLetivo === thing.idPeriodoLetivo
        ))
      ).map(el => {
        return {
          id: el.id,
          idPeriodoLetivo: el.idPeriodoLetivo,
          nomePeriodoLetivo: el.nomePeriodoLetivo,
          idInstituicaoEnsino: el.idInstituicaoEnsino,
          nomeInstituicaoEnsino: el.nomeInstituicaoEnsino,
          siglaUf: el.siglaUfInstituicaoEnsino,
          disciplinas: []
        }
      })
      agrupadores.forEach((agrupador, index) => {
        candidatoDisciplinasExternaPeriodoLetivo.filter(elemento => {
          if (agrupador.idPeriodoLetivo === elemento.idPeriodoLetivo && agrupador.idInstituicaoEnsino === elemento.idInstituicaoEnsino) {
            agrupadores[index].disciplinas.push({ nome: elemento.nomeDisciplinaExterna, codigo: elemento.codigoDisciplinaExterna })
          }
        })
      })
    }
    return agrupadores
  }
  openToast(time, message, onClose) {
    if (toast.isActive(this.state.toastId)) {
      return;
    }
    this.setState({
      toastId: toast.warn(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: time,
        onClose: () => {if(onClose){ return onClose()}}   
      })
    });
  }

  handleClearCombo(tempDisciplinaAprovada, rotuloAtributo, tempOptionSelect, optionSelect) {
    if (this.allComboIsSelected() && this.state.disciplinas.length > 0) {
      return confirmAlert({
        title: 'Atenção!!!',
        message: 'Ao fazer isso, as disciplinas que ainda não foram processadas irão ser perdidas',
        buttons: [
          {
            label: 'Sim',
            onClick: () => {
              this.limparCombo(tempDisciplinaAprovada, rotuloAtributo, tempOptionSelect, optionSelect)
            }
          },
          {
            label: 'Não',
          }
        ]
      })
    }
    this.limparCombo(tempDisciplinaAprovada, rotuloAtributo, tempOptionSelect, optionSelect)
  }
  onFocusSelect(e){
    e.preventDefault();
    if(this.state.disciplinas.length > 0 && !this.state.isAnmationActivated){
      this.openToast(false,
      'Salve suas disciplinas antes de trocar a Instituição/Semestre',
      this.onCloseToastWithDisciplinas)
    }
  }
  onCloseToastWithDisciplinas(){        
    if(this.state.instanceTimeoutAnimation !== null){        
        this.setState({isAnmationActivated:true},()=>{
          window.$([document.documentElement, document.body]).animate({
            scrollTop: window.$('#animation-hand').offset().top
           }, 2000);        
        })        
        this.setState({instanceTimeoutAnimation:setTimeout(() => {         
          this.setState({instanceTimeoutAnimation:null})
        }, 8000)})
    }
}  
  limparCombo(tempDisciplinaAprovada, rotuloAtributo, tempOptionSelect, optionSelect) {
    tempDisciplinaAprovada[rotuloAtributo] = ''
    tempOptionSelect = ''
    this.setState({
      disciplinaAprovada: tempDisciplinaAprovada,
      novasDisciplinas: [],
      disciplinas: [],
      [optionSelect]: tempOptionSelect,
    })
    this.zerarInputsDisciplina()
  }
  onchangeInputsDisciplina(e, rotuloAtributo) {
    const tempInputsDisciplina = this.state.inputsDisciplina
    tempInputsDisciplina[rotuloAtributo] = e.target.value
    this.setState({ inputsDisciplina: tempInputsDisciplina })
  }

  obterInsituicoesEnsino(nomeBuscaInsituicao) {
    const dadosInstituicaoEnsino = {
      nome: nomeBuscaInsituicao,
      idUf: this.state.disciplinaAprovada.idUf

    }
    return instituicaoEnsinoService.findByEntityInEsinoSuperior(dadosInstituicaoEnsino)
      .then(res => {
        let opcoes = [];
        opcoes = res.map(o => {
          return {
            value: o['id'],
            label: o['nome']
          };
        });
        const limiteRegistroPorPagina = 60
        if (opcoes.length === limiteRegistroPorPagina) {
          this.setState({ instituicoesOculta: true })
        } else {
          this.setState({ instituicoesOculta: false })
        }
        return opcoes
      });
  }
  obterUfs(nomeBuscaUf) {
    const dadosUf = {
      nome: nomeBuscaUf
    }
    return ufService.findByEntity(dadosUf)
      .then(res => {
        let opcoes = [];
        opcoes = res.map(o => {
          return {
            value: o['id'],
            label: o['nome'],
            sigla: o['sigla']
          };
        });
        return opcoes
      });
  }

  obterPeriodosLetivo(nomePeriodoLetivo) {
    const dadosPeriodoLetivo = {
      id: 0,
      nome: nomePeriodoLetivo,
      idInstituicaoMantida: this.props.candidato.idInstituicaoMantida
    }
    return periodoLetivoService.findByEntity(dadosPeriodoLetivo)
      .then(res => {
        let opcoes = [];
        opcoes = res.map(o => {
          return {
            value: o['id'],
            label: o['nome']
          };
        });

        return opcoes
      });
  }

  obterDisciplinasExterna(e) {
    const dadosPesquisa = {
      id: 0,
      nome: e,
      idPeriodoLetivo: this.state.disciplinaAprovada.idPeriodoLetivo,
      idInstituicaoEnsino: this.state.disciplinaAprovada.idInstituicaoEnsino,
      ativo: true,
      validado: true,
    }
    return disciplinaExternaPeriodoLetivoService.filterByDisciplinaExterna(dadosPesquisa)
      .then(res => {
        let opcoes = [];
        opcoes = res.map(o => {
          return {
            value: o['idDisciplinaExterna'],
            label: o['nome'],
            codigo: o['codigo'],
            cargaHoraria:o['cargaHoraria']

          };
        }).filter(el => {
          if (!this.state.disciplinas.find(disciplina => disciplina.idDisciplina === el.value)) {
            return el
          }
        });

        return opcoes
      });
  }
  adicionarNovaDisciplina() {
    const idDisciplina = 'ID' + this.state.incrementIdDisciplina
    this.addDisciplina({
      idDisciplina: idDisciplina,
      novaDisciplina: true,
      idPeriodoLetivo: this.state.disciplinaAprovada.idPeriodoLetivo,
      idInstituicaoEnsino: this.state.disciplinaAprovada.idInstituicaoEnsino,
      nome: this.state.inputsDisciplina.nomeDisciplina,
      codigo: this.state.inputsDisciplina.codigo,
      cargaHoraria: this.state.inputsDisciplina.cargaHoraria
    }, () => {
      this.zerarInputsDisciplina()
      this.incrementarIdDisciplina()
    })
  }
  incrementarIdDisciplina() {
    let tempIncrementIdDisciplina = this.state.incrementIdDisciplina
    tempIncrementIdDisciplina++
    this.setState({ incrementIdDisciplina: tempIncrementIdDisciplina })
  }

  zerarInputsDisciplina() {
    this.setState({ inputsDisciplina: { nomeDisciplina: '', codigo: '', cargaHoraria: '' } })
  }

  allComboIsSelected() {
    if (this.state.disciplinaAprovada.idPeriodoLetivo &&
      this.state.disciplinaAprovada.idInstituicaoEnsino &&
      this.state.disciplinaAprovada.idUf) {
      return true
    }
    return false
  }

  somethingComboSelected() {
    if (this.state.disciplinaAprovada.idPeriodoLetivo ||
      this.state.disciplinaAprovada.idInstituicaoEnsino ||
      this.state.disciplinaAprovada.idUf) {
      return true
    }
    return false
  }

  openToastConfirmationNoDisciplina() {
    return confirmAlert({
      title: 'Atenção!!!',
      message: 'Nenhuma disciplina foi incluída, ao apertar (Sim), essa etapa será pulada e sua inscrição finalizada',
      buttons: [
        {
          label: 'Sim',
          onClick: () => {
            this.setState({ message: 'Finalizando sua inscrição' }, () => {
              this.changeStateProcessandoDisciplinas(true)
              this.props.finalizarInscricao()
            })
          }
        },
        {
          label: 'Não',
        }
      ]
    })
  }
  cadastrarDisciplinas() {
    this.closeModalPreviewDisciplinasCursadas()
    this.changeStateProcessandoDisciplinas(true)
    let disciplinasValidadas = this.getArrayIdsDisciplinasSelecionadasValidadas()
    this.salvarDisciplinasExterna()
    .then(disciplinasAdicionadas => {
      disciplinasAdicionadas = disciplinasValidadas.concat(disciplinasAdicionadas)
      this.salvarAssociacaoDeDisciplinaComPeriodoLetivo(disciplinasAdicionadas)
        .then((idsDisciplinaExternaPeriodoLetivo) => {
        this.salvarAssociacaoDeCandidatoComDisciplinaExternaPeriodoLetivo(idsDisciplinaExternaPeriodoLetivo)
          .then(() => {
            if (this.state.ultimoCadastro) {
              this.setState({ ultimoCadastro: true, cadastroFinalizado: true }, () => {
                this.props.finalizarInscricao()
              })
            } else {
              this.exibirDadosSalvo()
              this.changeStateProcessandoDisciplinas(false)
            }
          })
      })

    })
  }
  salvarAssociacaoDeCandidatoComDisciplinaExternaPeriodoLetivo(idsDisciplinaExternaPeriodoLetivo) {    
    const dadosCandidatoDisciplinaExternaPeriodoLetivoArray = []
    idsDisciplinaExternaPeriodoLetivo.forEach(idDisciplinaExternaPeriodoLetivo => {
      dadosCandidatoDisciplinaExternaPeriodoLetivoArray.push({
        id: 0,
        idDisciplinaExternaPeriodoLetivo: idDisciplinaExternaPeriodoLetivo,
        idCandidato: this.props.candidato.id,
      })
    });
    return candidatoDisciplinaExternaPeriodoLetivoService.insertArray(dadosCandidatoDisciplinaExternaPeriodoLetivoArray).then(idsCandidatoDisciplinaExternaPeriodoLetivo => {
      return idsCandidatoDisciplinaExternaPeriodoLetivo
    })
  }

  salvarAssociacaoDeDisciplinaComPeriodoLetivo(disciplinasAdicionadasOuValidadasSelecionadas) {
    const dadosDisciplinaExternaPeriodoLetivoArray = []      
    disciplinasAdicionadasOuValidadasSelecionadas.forEach(disciplinaAdicionadaOuValidadaSelecionada => {            
      
      dadosDisciplinaExternaPeriodoLetivoArray.push({
        id: 0,
        idDisciplinaExterna: disciplinaAdicionadaOuValidadaSelecionada.id || disciplinaAdicionadaOuValidadaSelecionada.idDisciplina ,
        idPeriodoLetivo: this.state.disciplinaAprovada.idPeriodoLetivo,
        cargaHoraria:parseInt(this.state.disciplinas.find(disciplinaEmMemoria => disciplinaEmMemoria.codigo === disciplinaAdicionadaOuValidadaSelecionada.codigo).cargaHoraria, 10)
      })
    });    
    
    return disciplinaExternaPeriodoLetivoService.insertArray(dadosDisciplinaExternaPeriodoLetivoArray)
    .then(idsDisciplinaExternaPeriodoLetivo => {
      return idsDisciplinaExternaPeriodoLetivo
    })
  }

  getArrayIdsDisciplinasSelecionadasValidadas() {
    return this.state.disciplinas.filter(disciplina => {
      if (!isNaN(disciplina.idDisciplina)) {
        return disciplina
      }
    })
  }

  salvarDisciplinasExterna() {
    return disciplinaExternaService.insertArray(this.getOnlyNovasDisciplinas())
      .then(disciplinasAdicionadas => {
        return disciplinasAdicionadas
      })
  }

  getOnlyNovasDisciplinas() {
    return this.state.disciplinas.filter(novaDisciplina => {
      if (isNaN(novaDisciplina.idDisciplina)) {
        return novaDisciplina
      }
    })
  }

  validarFormulario() {
    if (this.state.disciplinas.length > 0 || (this.state.ultimoCadastro && this.state.previewDadosSalvos.length > 0)) {
      return true
    }
    return false
  }

  exibirDadosSalvo() {
    const tempPreviewDadosSalvos = this.state.previewDadosSalvos
    const indexPreviewDadosExistente = tempPreviewDadosSalvos
      .findIndex(el => el.periodoLetivo === this.state.optionPeriodoLetivoEscolhido.label &&
        el.siglaUf === this.state.optionUfEscolhido.sigla &&
        el.instituicao === this.state.optionInstituicaoEscolhida.label)
    if (indexPreviewDadosExistente !== -1) {
      tempPreviewDadosSalvos[indexPreviewDadosExistente].disciplinas.push(...this.state.disciplinas)
    } else {
      tempPreviewDadosSalvos.push({
        periodoLetivo: this.state.optionPeriodoLetivoEscolhido.label,
        instituicao: this.state.optionInstituicaoEscolhida.label,
        siglaUf: this.state.optionUfEscolhido.sigla,
        disciplinas: this.state.disciplinas,
        showAccordion: false
      })
    }

    this.setState({ previewDadosSalvos: tempPreviewDadosSalvos }, () => {
      this.zerarStatesDoFormulario()
    })
  }
  zerarStatesDoFormulario() {    
    this.setState({
      disciplinas: [],
      optionInstituicaoEscolhida: '',
      optionPeriodoLetivoEscolhido: '',
      optionUfEscolhido: '',      
    }, () => {
      this.changeStateProcessandoDisciplinas(false)
    })
  }
  changeStateProcessandoDisciplinas(state) {
    this.setState({ isProcessandoDisciplinas: state })
  }
  openModalPreviewDisciplinasCursadas() {
    this.setState({ showModalPreviewDisciplinasCursadas: true })
  }
  closeModalPreviewDisciplinasCursadas() {
    this.setState({ showModalPreviewDisciplinasCursadas: false })
  }
  finalizarProcessoCadastroDisciplinas() {
    if (this.state.ultimoCadastro && this.state.disciplinas.length === 0) {
      return confirmAlert({
        title: 'Atenção!!!',
        message: 'Deseja finalizar o processo ?',
        buttons: [
          {
            label: 'Sim',
            onClick: () => {
              this.cadastrarDisciplinas()
            }
          },
          {
            label: 'Não',
          }
        ]
      })
    }
    this.openModalPreviewDisciplinasCursadas()

  }
  preencherInputsDisciplinasComDisciplinaEscolhida(idDisciplina) {
    let indexToEdit = this.state.disciplinas.findIndex(el => el.idDisciplina === idDisciplina)
    const tempInputsDisciplina = this.state.inputsDisciplina
    tempInputsDisciplina.disciplinaExistente = true
    tempInputsDisciplina.nomeDisciplina = this.state.disciplinas[indexToEdit].nome
    tempInputsDisciplina.codigo = this.state.disciplinas[indexToEdit].codigo
    tempInputsDisciplina.cargaHoraria = this.state.disciplinas[indexToEdit].cargaHoraria
    tempInputsDisciplina.indexToEdit = indexToEdit
    this.setState({ inputsDisciplina: tempInputsDisciplina })
  }

  addDisciplina(disciplina, callback) {
    if (!Array.isArray(disciplina) && disciplina.nome) {
      if (this.existeDisciplinaComMesmoNome(disciplina.nome)) {
        return this.openToast(4500, `Você já incluiu uma disciplina com esse nome: ${disciplina.nome.trim()}`)
      }
      const tempDisciplinas = this.state.disciplinas
      tempDisciplinas.push(disciplina)
      this.setState({ disciplinas: tempDisciplinas }, () => {
        if (callback) callback()
      })
    }
  }
  editarDisciplina() {
    const tempDisciplinas = this.state.disciplinas
    const indexToEdit = this.state.inputsDisciplina.indexToEdit
    if(tempDisciplinas[indexToEdit]){
      tempDisciplinas[indexToEdit].nome = this.state.inputsDisciplina.nomeDisciplina
      tempDisciplinas[indexToEdit].cargaHoraria = this.state.inputsDisciplina.cargaHoraria
      tempDisciplinas[indexToEdit].codigo = this.state.inputsDisciplina.codigo
    }
    this.setState({ disciplinas: tempDisciplinas }, () => {
      this.zerarInputsDisciplina()
    })
  }
  existeDisciplinaComMesmoNome(nomeDisciplina) {
    let nomesDisciplinas = this.state.disciplinas.map(disciplina => disciplina.nome.trim())
    if (nomesDisciplinas.includes(nomeDisciplina)) {
      return true
    }
    return false
  }
  deleteDisciplina(idDisciplina, callback) {
    const tempDisciplinas = this.state.disciplinas
    const indexToRemove = tempDisciplinas.findIndex(el => el.idDisciplina === idDisciplina)
    tempDisciplinas.splice(indexToRemove, 1)
    this.setState({ disciplinas: [] }, () => {
      this.setState({ disciplinas: tempDisciplinas })
      if (callback) callback()
    })
  }


  render() {
    return (
      <div>
        <div>
          <ModalPreviewDisciplinasCursadas
            ultimoCadastro={this.state.ultimoCadastro}
            confirmarDisciplinas={this.cadastrarDisciplinas}
            closeModalPreviewDisciplinasCursadas={this.closeModalPreviewDisciplinasCursadas}
            showModalPreviewDisciplinasCursadas={this.state.showModalPreviewDisciplinasCursadas}
            nomesDisciplinasSelecionadas={this.state.disciplinas.map(disciplinaSelecionada => disciplinaSelecionada.nome)}
            nomePeriodoLetivo={this.state.optionPeriodoLetivoEscolhido.label}
            nomeInstituicao={this.state.optionInstituicaoEscolhida.label}
            siglaUf={this.state.optionUfEscolhido.sigla}
          />
          <ToastContainer />
          {this.state.isProcessandoDisciplinas ? <Carregando message={this.state.message} />
            :
            <div className="section-bg-disciplinas">
              <h2 className="h3 text-blue mb-2 text-center">Informe suas Disciplinas aprovadas para serem dispensadas </h2>
              <p className="subtitle-doc mb-4 text-tittle-disciplinas text-center">Com seu histórico em mãos, informe o Estado da Instituição, o nome da Instituição, o Semestre que a disciplina foi aprovada e o nome da Disciplina.</p>
              <div className="row">
                <div className="div-lista-disciplinas">
                  <h5 className="h5 text-blue mb-2">Preencha os campos abaixo para encontrar suas disciplinas e inclui-las à lista</h5>
                  <div className="row">
                    <div className="col-sm-3 col-xs-3">
                      <div className="form-group">
                        <label className="control-label" htmlFor="idUf">Estado</label>
                        <AsyncSelect
                          placeholder="Digite o nome do estado"
                          isClearable
                          cacheOptions
                          onFocus={(e) => {this.onFocusSelect(e)}}
                          onChange={(e) => this.onChange(e, 'idUf', 'optionUfEscolhido')}
                          noOptionsMessage={(e) => noOptionsMessage(e,'Nenhum estado foi encontrado')}
                          id="idUf"
                          name="idUf"
                          value={this.state.optionUfEscolhido}
                          loadOptions={(e) => this.obterUfs(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-7 col-xs-7">
                      <div className="form-group">
                        <label className="control-label" htmlFor="idInstituicaoEnsino">
                          Insituição
                        </label>
                        {this.state.instituicoesOculta ?
                          <span style={{ marginLeft: '15px' }} className="text-intiuicoes-oculta">
                            *Existem itens ocultos, digite mais dados sobre a sua instituição
                        </span>
                          : ''}
                        <AsyncSelect
                          placeholder={this.state.disciplinaAprovada.idUf ? 'Digite o nome da instituição' : 'Selecione um estado'}
                          isClearable
                          isDisabled={!this.state.disciplinaAprovada.idUf}
                          noOptionsMessage={(e) => noOptionsMessage(e,'Instituição não cadastrada, entre em contato com sua Unidade/Polo')}
                          onFocus={(e) => {this.onFocusSelect(e)}}
                          onChange={(e) => {
                            this.setState({ instituicoesOculta: false })
                            this.onChange(e, 'idInstituicaoEnsino', 'optionInstituicaoEscolhida')
                          }
                          }
                          id="idInstituicaoEnsino"
                          name="idInstituicaoEnsino"
                          value={this.state.optionInstituicaoEscolhida}
                          loadOptions={(e) => this.obterInsituicoesEnsino(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 col-xs-2">
                      <div className="form-group">
                        <label className="control-label" htmlFor="idPeriodoLetivo">Semestre</label>
                        <AsyncSelect
                          placeholder="Ex.: 20181"
                          isClearable
                          onFocus={(e) => {this.onFocusSelect(e)}}
                          noOptionsMessage={(e) => noOptionsMessage(e,'Nenhum semestre foi encontrado')}
                          cacheOptions
                          onChange={(e) => this.onChange(e, 'idPeriodoLetivo', 'optionPeriodoLetivoEscolhido')}
                          id="idPeriodoLetivo"
                          name="idPeriodoLetivo"
                          value={this.state.optionPeriodoLetivoEscolhido}
                          loadOptions={(e) => {return this.obterPeriodosLetivo(e)}}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 div-lista-disciplinas">
                  <div className="form-group">
                    <h5 className="h5 text-blue mb-2">
                      Pesquise a disciplina que está cadastrada previamente em nossa base de dados.
                    </h5>
                    <label className="control-label" htmlFor="idDisciplina">
                      Disciplinas
                        </label>
                    <AsyncSelect
                      placeholder={this.state.disciplinaAprovada.idInstituicaoEnsino && this.state.disciplinaAprovada.idPeriodoLetivo ? 'Digite o nome da Disciplina' : 'Selecione uma instiuição e um semestre'}
                      isClearable={false}                      
                      noOptionsMessage={(e) => noOptionsMessage(e,'Nenhuma disciplina foi encontrada',null,'Digite o nome da disciplina e selecione')}
                      value=""
                      name="idDisciplina"
                      isDisabled={!(this.state.disciplinaAprovada.idInstituicaoEnsino && this.state.disciplinaAprovada.idPeriodoLetivo)}
                      onChange={(e) => {
                        this.addDisciplina({ idDisciplina: e.value, nome: e.label, codigo: e.codigo, novaDisciplina: false,cargaHoraria:e.cargaHoraria })
                      }
                      }
                      loadOptions={(e) => this.obterDisciplinasExterna(e)}
                    />
                  </div>
                </div>
                <div className="col-sm-6 div-lista-disciplinas">
                  <form style={{ marginBottom: '18px' }} onSubmit={(e) => {
                    e.preventDefault();
                    if (this.state.inputsDisciplina.disciplinaExistente) {
                      this.editarDisciplina()
                    } else {
                      this.adicionarNovaDisciplina()
                    }
                  }}>
                    <h5 className="h5 text-blue mb-2">
                      Caso não encontre a disciplina na pesquisa, cadastre abaixo.
                      <span className="text-informativo">repita os dados do histórico escolar</span>
                    </h5>
                    <div className="row">
                      <div className="form-group col-sm-12">
                        <label htmlFor="nomeDisciplina">Nome da Disciplina</label>
                        <input type="text" className="form-control"
                          disabled={!this.allComboIsSelected()}
                          maxLength="120"
                          minLength="3"
                          required
                          id="nomeDisciplina"
                          name="nomeDisciplina"
                          value={this.state.inputsDisciplina.nomeDisciplina}
                          onChange={(e) => this.onchangeInputsDisciplina(e, 'nomeDisciplina')}
                          placeholder="Digite o nome da Disciplina" />
                      </div>
                      <div className="form-group col-sm-6">
                        <label htmlFor="codigo" >Código</label>
                        <input type="text" className="form-control"
                          disabled={!this.allComboIsSelected()}
                          maxLength="20"
                          minLength="1"
                          id="codigo"
                          name="codigo"
                          value={this.state.inputsDisciplina.codigo}
                          onChange={(e) => this.onchangeInputsDisciplina(e, 'codigo')}
                          placeholder="Digite o código"
                        />
                      </div>
                      <div className="form-group col-sm-6">
                        <label htmlFor="cargaHoraria">Carga Horária</label>
                        <input type="number" className="form-control"
                          disabled={!this.allComboIsSelected()}
                          max="32767"
                          min="1"
                          id="cargaHoraria"
                          name="cargaHoraria"
                          value={this.state.inputsDisciplina.cargaHoraria}
                          onChange={(e) => this.onchangeInputsDisciplina(e, 'cargaHoraria')}
                          placeholder="Digite a carga horária"
                        />
                      </div>
                    </div>
                    <div className="row">
                    </div>
                    <div className="section-btn-add-disciplina">
                      <button disabled={!this.allComboIsSelected()} type="submit" className="btn-add-disciplina">
                        {this.state.inputsDisciplina.disciplinaExistente ? 'Editar Disciplina' : 'Incluir à Lista'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">

                <div className="col-sm-12 col-xs-12 div-lista-disciplinas">
                  <div id="accordion">
                    {this.allComboIsSelected() && this.state.disciplinas.length > 0 ?
                      <div>
                        <h5 className="h5 text-blue mb-2 text-center">Lista de Disciplinas à serem salvas</h5>
                        <CardDadosDisciplinasCursadas
                          preencherInputsDisciplinasComDisciplinaEscolhida={this.preencherInputsDisciplinasComDisciplinaEscolhida}
                          siglaUf={this.state.optionUfEscolhido.sigla}
                          nomePeriodoLetivo={this.state.optionPeriodoLetivoEscolhido.label}
                          nomeInstituicao={this.state.optionInstituicaoEscolhida.label}
                          showAccordionDisciplinas={true}
                          disciplinas={this.state.disciplinas}
                          deleteDisciplina={this.deleteDisciplina}
                        />
                        <div className="text-center">
                        {this.state.isAnmationActivated && this.state.instanceTimeoutAnimation !== null ? 
                        <FaHandPointRight id="animation-hand" className="icon-hand-point-right" style={{ width: '15px', height: '15px', marginRight: '15px',fontSize:'25px' }}/>
                        :''}
                          <button style={{ width: '34%' }} type="button" onClick={() => {
                            if (this.validarFormulario()) {
                              this.setState({ ultimoCadastro: false })
                              this.openModalPreviewDisciplinasCursadas()
                            } else {
                              this.openToast(5000, 'Para prosseguir, você deve ter pelo menos uma disciplina selecionada')
                            }
                          }}
                            className="btn-orange">Salvar as Disciplinas e continuar cadastrando um novo Semestre/Instituição</button>
                        </div>
                      </div>

                      ://<h5 className="h5 text-blue mb-2 text-center">Lista de disciplinas vazia, inclua as disciplina cursadas na lista</h5>
                      null}
                    {this.state.previewDadosSalvos.length > 0 ?
                      <div>
                        <h5 className="h5 text-blue mb-2 text-center">Disciplinas Salvas</h5>
                        {this.state.previewDadosSalvos.map((el, index) => {
                          return (
                            <CardDadosDisciplinasCursadas
                              key={index}
                              disciplinasProcessadas={true}
                              nomeInstituicao={el.instituicao}
                              siglaUf={el.siglaUf}
                              nomePeriodoLetivo={el.periodoLetivo}
                              showAccordionDisciplinas={false}
                              disciplinas={el.disciplinas}
                            />
                          )
                        })}
                      </div> : ''}
                  </div>
                </div>
              </div>
            </div>}
          {this.state.isProcessandoDisciplinas ? '' :
            <div id="confirmar-cancelar" className="container">
              <button id="btConfirmaInscricao" className="btn btn-primary bt-confirmar"
                type="button" onClick={() => {
                  {
                    if (this.state.previewDadosSalvos.length === 0 && this.state.disciplinas.length === 0) {
                      this.openToastConfirmationNoDisciplina()
                      return
                    }
                    this.setState({ ultimoCadastro: true }, () => {
                      if (this.validarFormulario()) {
                        this.finalizarProcessoCadastroDisciplinas()
                      } else {
                        this.openToast(5000, 'Para prosseguir, você deve ter pelo menos uma disciplina selecionada')
                      }
                    })
                  }
                }}
                disabled={this.props.carregando}>Finalizar Inscrição</button>
            </div>}

        </div>

      </div>
    )
  }
}
